import "./SoundboxCarousel.scss";
import React from "react";
import { useGetCurrentScenes } from "../../hooks/useGetCurrentScenes";
import { useCurrentBrowserZoom } from "../../hooks/useCurrentBrowserZoom";

type SoundboxCarouselProps = {
  carouselRef: React.RefObject<HTMLDivElement>;
};

export const SoundboxCarousel = React.memo((props: SoundboxCarouselProps) => {
  const currentScenes = useGetCurrentScenes();
  const { setPictureSourceImageMaxWidth } = useCurrentBrowserZoom();

  return (
    <div className="soundbox-carousel">
      <div className="soundbox-carousel__images-wrapper" ref={props.carouselRef}>
        {currentScenes.map(e => (
          <picture className="soundbox-carousel__image">
            <source srcSet={e.img[0]} media={setPictureSourceImageMaxWidth(500)} />
            <source srcSet={e.img[1]} media={setPictureSourceImageMaxWidth(1302)} />
            <img src={e.img[2]} alt={"Soundbox stage image"} className="soundbox-carousel__image" />
          </picture>
        ))}
      </div>
    </div>
  );
});
