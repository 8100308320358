import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import "./Select.scss";
import { ReactComponent as ArrowDownIcon } from "../../static/icons/arrowDown.svg";
import { useSelect } from "../../hooks/useSelect";

type SelectProps = {
  options: Array<string>;
  selectedIndex: number;
  onChange?: (index: number) => void;
};

export const Select = (props: SelectProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { selectListRef, selectButtonRef } = useSelect(open);
  const selectRef = useRef<HTMLDivElement>(null);
  useClickOutside(selectRef, () => {
    setOpen(false);
  });

  function focusFirstLink() {
    const firstLink = selectListRef.current?.querySelector("li") as HTMLLIElement;
    if (firstLink) {
      setTimeout(() => {
        firstLink.focus();
      }, 0);
    }
  }

  function focusElementWithIndex(index: number) {
    const liList = selectListRef.current?.querySelectorAll("li");
    if (liList) {
      setTimeout(() => {
        liList[index]?.focus();
      }, 0);
    }
  }

  function focusLastLink() {
    const links = selectListRef.current?.querySelectorAll("li");
    const lastLink = links?.[links.length - 1];
    lastLink && lastLink.focus();
  }

  function focusNextLink() {
    const nextLink = document.activeElement?.nextElementSibling as HTMLLIElement;

    nextLink?.tagName && nextLink.tagName === "LI" ? nextLink.focus() : focusFirstLink();
  }

  function focusPreviousLink() {
    const previousLink = document.activeElement?.previousElementSibling as HTMLLIElement;
    previousLink ? previousLink.focus() : focusLastLink();
  }

  function onTriggerClick() {
    const newState = !open;
    setOpen(newState);
    focusElementWithIndex(props.selectedIndex);
  }

  function keyboardNavigation(e: React.KeyboardEvent<HTMLDivElement>) {
    switch (e.key) {
      case "Home":
        e.preventDefault();
        focusFirstLink();
        break;
      case "End":
        e.preventDefault();
        focusLastLink();
        break;
      case "ArrowDown":
        e.preventDefault();
        focusNextLink();
        break;
      case "ArrowUp":
        e.preventDefault();
        focusPreviousLink();
        break;
      case "Escape":
        e.preventDefault();
        selectButtonRef.current?.focus();
        setOpen(false);
        break;
    }
  }

  const handleChange = (index: number) => {
    if (props.onChange) {
      props.onChange(index);
    }
    setOpen(false);
  };

  return (
    <div className="select" onKeyDown={e => keyboardNavigation(e)} ref={selectRef}>
      <button className="select__button" onClick={() => onTriggerClick()} ref={selectButtonRef}>
        {props.options[props.selectedIndex]}{" "}
        <span className="select__icon">
          <ArrowDownIcon />
        </span>
      </button>

      <ul className={classNames("select__select-list", !open && "select__select-list--hide")} ref={selectListRef}>
        {props.options.map((e, index) => (
          <li onClick={() => handleChange(index)} tabIndex={0}>
            {e}
          </li>
        ))}
      </ul>
    </div>
  );
};
