import classNames from "classnames";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useCarousel } from "../../hooks/useCarousel";
import { useDeviceScreenWidth } from "../../hooks/useDeviceWievportHeight";
import { useFindHeatPumpTranslation } from "../../hooks/useFindHeatPumpTranslation";
import { useIsSummer } from "../../hooks/useIsSummer";
import { ReactComponent as LeftArrow } from "../../static/icons/leftArrow.svg";
import { HeatPumpData } from "../../types/heatPump";
import { Translations } from "../../types/languages";
import { Select } from "../Select/Select";
import "./Carousel.scss";

enum CarouselStep {
  SMALL_STEP = 25,
  BIG_STEP = 50,
}

const CAROUSEL_BREAKPOINT = 768;

export type CarouselProps = {
  items: Array<HeatPumpData>;
  category?: string;
  translations: Translations;
  setSelectedPump: React.Dispatch<
    React.SetStateAction<{
      selectedHeatPumpId: number;
      variantIndex: number;
    }>
  >;
  selectedPump: {
    selectedHeatPumpId: number;
    variantIndex: number;
  };
};

export const Carousel = (props: CarouselProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const carouseTextlRef = useRef<HTMLDivElement>(null);
  const [step, setStep] = useState<number>(0);
  const { translate, translateCarousel, disableArrow, changeTranslate, activeIndex } = useCarousel(
    step,
    props.items.length,
    [carouselRef, carouseTextlRef],
    true,
  );
  const width = useDeviceScreenWidth();
  const { isSummer } = useIsSummer(props.translations);
  const pumpsLastSelectedOption = useRef<{ [key: number]: number }>({});

  const { heatPumpTranslation } = useFindHeatPumpTranslation(
    {
      translations: props.translations,
      heatPumps: props.items,
    },
    {
      heatPumpId: props.selectedPump.selectedHeatPumpId,
      variantIndex: props.selectedPump.variantIndex,
    },
  );

  useLayoutEffect(() => {
    const width = window.innerWidth;

    if (!width) {
      return;
    }

    if (width < CAROUSEL_BREAKPOINT && step !== CarouselStep.BIG_STEP) {
      setStep(CarouselStep.BIG_STEP);
      changeTranslate(0);
    } else if (width >= CAROUSEL_BREAKPOINT && step !== CarouselStep.SMALL_STEP) {
      setStep(CarouselStep.SMALL_STEP);

      if (props.items.length < 4) {
        changeTranslate((100 - CarouselStep.SMALL_STEP * props.items.length) / 2);
      } else {
        changeTranslate(0);
      }
    }
  }, [width, props.category]);

  const carouselIsNeeded = useMemo(() => {
    if (
      (window.innerWidth >= CAROUSEL_BREAKPOINT && props.items.length <= 4) ||
      (window.innerWidth < CAROUSEL_BREAKPOINT && props.items.length <= 2)
    ) {
      return false;
    }
    return true;
  }, [width, props.items]);

  const checkToHideElement = (index: number) => {
    if (!carouselIsNeeded) {
      return true;
    }

    const currentIndex = Math.abs(translate.current / step);

    if (currentIndex <= index && index <= currentIndex - 1 + 100 / step) {
      return true;
    }
    return false;
  };

  const selectTeaserImage = (heatPumpData: HeatPumpData, active: boolean): string[] => {
    const variantIndex =
      typeof pumpsLastSelectedOption.current[heatPumpData.id] === "number"
        ? pumpsLastSelectedOption.current[heatPumpData.id]
        : 0;

    if (active && isSummer && heatPumpData.variants[props.selectedPump.variantIndex].teaser) {
      return heatPumpData.variants[props.selectedPump.variantIndex].teaser;
    } else if (active && !isSummer && heatPumpData.variants[props.selectedPump.variantIndex].teaserWinter) {
      return heatPumpData.variants[props.selectedPump.variantIndex].teaserWinter as string[];
    }

    if (isSummer) {
      return heatPumpData.variants[variantIndex].teaser || heatPumpData.variants[variantIndex].teaserWinter || [];
    }
    return heatPumpData.variants[variantIndex].teaserWinter || heatPumpData.variants[variantIndex].teaser || [];
  };

  const selectHeatPump = (id: number) => {
    pumpsLastSelectedOption.current[props.selectedPump.selectedHeatPumpId] = props.selectedPump.variantIndex;

    if (id !== props.selectedPump.selectedHeatPumpId) {
      props.setSelectedPump({
        variantIndex: typeof pumpsLastSelectedOption.current[id] === "number" ? pumpsLastSelectedOption.current[id] : 0,
        selectedHeatPumpId: id,
      });
    }
  };

  return (
    <>
      <div className="carousel-wrapper">
        <div className="carousel-wrapper__images">
          {carouselIsNeeded && !disableArrow.left && (
            <button
              className="carousel-button carousel-button__left"
              onClick={() => translateCarousel("left")}
              disabled={disableArrow.left}
            >
              <LeftArrow />
            </button>
          )}
          {carouselIsNeeded && !disableArrow.right && (
            <button
              className="carousel-button carousel-button__right"
              onClick={() => translateCarousel("right")}
              disabled={disableArrow.right}
            >
              <LeftArrow />
            </button>
          )}

          <div className="carousel" ref={carouselRef}>
            {props.items.map((e, index) => {
              const images = selectTeaserImage(e, e.id === props.selectedPump.selectedHeatPumpId);

              return (
                <div
                  className="carousel__item"
                  onClick={() => selectHeatPump(e.id)}
                  style={{
                    opacity: checkToHideElement(index) ? "100%" : "0%",
                  }}
                >
                  <div
                    className={classNames(
                      "carousel__image-wrapper",
                      e.id === props.selectedPump.selectedHeatPumpId && "carousel__image-wrapper--active",
                    )}
                  >
                    {images.length === 3 && (
                      <picture>
                        <source srcSet={images[0]} media="(max-width: 768px)" />
                        <source srcSet={images[1]} media="(max-width: 1920px)" />
                        <img src={images[2]} alt={heatPumpTranslation?.carouselName} className="carousel__image" />
                      </picture>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="carousel" ref={carouseTextlRef}>
          {props.items.map((e, index) => {
            let translation = props.translations?.pumps[e.variants[0].shortcut];

            if (e.id === props.selectedPump.selectedHeatPumpId) {
              const heatPumpShortcut = e.variants[props.selectedPump.variantIndex]?.shortcut;
              translation = heatPumpShortcut ? props.translations?.pumps[heatPumpShortcut] : translation;
            }

            const isSelected = e.id === props.selectedPump.selectedHeatPumpId;
            return (
              <div
                className="carousel__item"
                style={{
                  opacity: checkToHideElement(index) ? "100%" : "0%",
                  transition: "0.3s",
                }}
                key={e.id}
              >
                <div onClick={() => selectHeatPump(e.id)}>
                  <div className="carousel__text">
                    <h2 className={classNames("carousel__title", !isSelected && "carousel__title--unselected")}>
                      {translation.carouselName}
                    </h2>
                    <div className={classNames("carousel__subtitle", !isSelected && "carousel__subtitle--unselected")}>
                      {translation.carouselSubtitle}
                    </div>
                  </div>
                </div>
                {e.id === props.selectedPump.selectedHeatPumpId && (
                  <Select
                    options={e.variants.map(e => `${e.power} kW`)}
                    selectedIndex={props.selectedPump.variantIndex}
                    onChange={(index: number) => props.setSelectedPump(e => ({ ...e, variantIndex: index }))}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
