import { useContext } from "react";
import { PageDataContext } from "../../context/PageDataContext";
import { useIsSummer } from "../../hooks/useIsSummer";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import "./Disclaimer.scss";

type DisclaimerProps = {
  handleClose: () => void;
};

export const Disclaimer = (props: DisclaimerProps) => {
  const context = useContext(PageDataContext);
  const { isSummer } = useIsSummer(context.translations);

  return (
    <Modal type="text">
      <div className="disclaimer">
        <header>
          <h1>Disclaimer</h1>
        </header>

        <p>{isSummer ? context.translations.disclaimer.summer : context.translations.disclaimer.winter}</p>
        <div className="disclaimer__button-wrapper">
          <Button type="tertiary" onClick={props.handleClose}>
            {context.translations.disclaimer.back}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
