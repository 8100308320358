import classNames from "classnames";
import { useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { langugaes } from "../../App";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useSelect } from "../../hooks/useSelect";
import { ReactComponent as GlobeIcon } from "../../static/icons/globe.svg";
import "./LanguagePicker.scss";

type LanguagePickerProps = {
  type: "light" | "grey";
  className?: string;
  languages: Array<{ language: string; shortcut: string }>;
};

export const LanguagePicker = (props: LanguagePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { selectListRef, selectButtonRef } = useSelect(open);
  const languagePickerRef = useRef<HTMLDivElement>(null);
  useClickOutside(languagePickerRef, () => setOpen(false));
  const location = useLocation();
  const language = langugaes.find(e => e.shortcut === location.pathname.split("/")[1]);
  const navigate = useNavigate();

  const handleClick = (shortcut: string) => {
    navigate(`/${shortcut}/` + location.pathname.split("/").splice(2).join("/") + location.search);
    setOpen(false);
  };

  return (
    <div className={classNames("language-picker", props.className)} ref={languagePickerRef}>
      <button
        className={classNames(
          "language-picker__button",
          props.type === "grey" ? "language-picker__button--grey" : "language-picker__button--light",
        )}
        ref={selectButtonRef}
        onClick={() => setOpen(true)}
      >
        <span className="language-picker__icon">
          <GlobeIcon />
        </span>
        {language ? language.language : "Not Found"}
      </button>

      <ul
        className={classNames("language-picker__select-list", !open && "language-picker__select-list--hide")}
        ref={selectListRef}
      >
        {props.languages.map((e, index) => (
          <li key={e.shortcut} onClick={() => handleClick(e.shortcut)} tabIndex={0}>
            {e.language}
          </li>
        ))}
      </ul>
    </div>
  );
};
