import "./Alert.scss";
import { ReactComponent as ClosIcon } from "../../static/icons/close.svg";

type AlertProps = {
  onClick: () => void;
  text?: string;
};

export const Alert = (props: AlertProps) => {
  return (
    <div className="alert">
      {props.text}
      <button className="alert__button" onClick={props.onClick}>
        <ClosIcon />
      </button>
    </div>
  );
};
