import { useEffect, useState } from "react";
export const useCurrentBrowserZoom = () => {
  const [zoom, setZoom] = useState<number>(getZoom());

  useEffect(() => {
    const handleZoomChange = () => {
      const zoom = getZoom();
      setZoom(zoom);
    };

    window.addEventListener("resize", handleZoomChange);

    return () => window.removeEventListener("resize", handleZoomChange);
  }, []);

  function getZoom() {
    if (typeof window.devicePixelRatio === "number") {
      return window.devicePixelRatio;
    } else if (window) {
      return (window.outerWidth - 10) / window.innerWidth;
    }

    return 1;
  }

  const setPictureSourceImageMaxWidth = (pixels: number) => `(max-width: ${pixels / zoom}px)`;

  return { setPictureSourceImageMaxWidth };
};
