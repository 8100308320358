import { memo, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import { heatPumpData, PageDataContext } from "./context/PageDataContext";
import { SelectedHeatPumpContext, SelectedHeatPumpData } from "./context/SelectedHeatPumpContext";
import { useDeviceScreenHeight } from "./hooks/useDeviceWievportHeight";
import { ProductSelection } from "./templates/ProductSelection/ProductSelection";
import { SelectedProductIntro } from "./templates/SelectedProductIntro/SelectedProductIntro";
import { Soundbox } from "./templates/Soundbox/Soundbox";
import { HeatPumpData } from "./types/heatPump";
import { Translations } from "./types/languages";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Alert } from "./components/Alert/Alert";
import { ProductSelectionWrapper } from "./templates/ProductSelectionWrapper/ProductSelectionWrapper";
import { SoundboxSettings } from "./types/soundboxSettings";
import { BackgroundSelectionContext } from "./context/BackgroundSelectionContext";
import { Helmet } from "react-helmet";

// Vaillant
import de from "./translations/vaillant/de.json";
import en from "./translations/vaillant/en.json";

export const langugaes = [
  {
    language: "Deutsch",
    shortcut: "de",
    translation: de as Translations,
  },
  {
    language: "English",
    shortcut: "en",
    translation: en as Translations,
  },
];

enum AlertMessage {
  SUCCESS,
  UPDATE,
}

function App() {
  const height = useDeviceScreenHeight();
  const [selectedHeatPumpData, setSelectedHeatPumpData] = useState<SelectedHeatPumpData>({
    heatPumpId: heatPumpData[0].id,
    variantIndex: 0,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [currentLanguage, setCurrentLanguage] = useState<string>();
  const [heatPumpsData, setHeatPumpsData] = useState<Array<HeatPumpData>>();
  const [openAlert, setOpenAlert] = useState<{ open: false } | { open: true; messageType: AlertMessage }>({
    open: false,
  });
  const [soundboxSettings, setSoundboxSettings] = useState<SoundboxSettings>({
    volume: 50,
    surroundings: null,
  });

  const getNavigatorLanguage = (): string => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return (navigator as any).userLanguage || navigator.language || (navigator as any).browserLanguage || "en";
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const pwa = queryParams.get("pwa");

    if (pwa === "download" || window.matchMedia("(display-mode: standalone)").matches) {
      serviceWorkerRegistration.register({
        onSuccess: () => setOpenAlert({ open: true, messageType: AlertMessage.SUCCESS }),
        onUpdate: () => setOpenAlert({ open: true, messageType: AlertMessage.UPDATE }),
      });
    } else if (pwa === "unregister") {
      serviceWorkerRegistration.unregister();
    }
  }, []);

  useEffect(() => {
    const language = location.pathname.split("/")[1];
    const currentLanguage = langugaes.find(e => e.shortcut === language);

    if (!currentLanguage) {
      const language = getNavigatorLanguage();

      const bestLanguageFit =
        langugaes.find(e => e.shortcut === language) || langugaes.find(e => e.shortcut === language.split("-")[0]);

      navigate(bestLanguageFit ? "/" + bestLanguageFit.shortcut : "/en", { replace: true });
    } else {
      setCurrentLanguage(currentLanguage.shortcut);
      document.documentElement.lang = currentLanguage.shortcut.split("-")[0];
    }
  }, [location.pathname]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    let products: Array<HeatPumpData> = heatPumpData;

    if (queryParams.has("products")) {
      const productsIds = queryParams.get("products")?.split(",");
      const filteredProducts = heatPumpData
        .map(e => {
          return { ...e, variants: e.variants.filter(e => productsIds?.includes(e.shortcut)) };
        })
        .filter(e => !!e.variants.length);
      products = filteredProducts.length === 0 ? heatPumpData : filteredProducts;
    }

    if (queryParams.has("startProduct")) {
      const startProductId = queryParams.get("startProduct");

      let variantIndex: number | null = null;
      let productId: number | null = null;
      for (let i = 0; i < products.length; i++) {
        variantIndex = products[i].variants.findIndex(e => e.shortcut === startProductId);

        if (variantIndex >= 0) {
          productId = products[i].id;
          break;
        }
      }

      if (variantIndex !== null && productId !== null) {
        setSelectedHeatPumpData({
          heatPumpId: productId,
          variantIndex: variantIndex,
        });
      } else {
        setSelectedHeatPumpData({
          heatPumpId: products[0].id,
          variantIndex: 0,
        });
      }
    } else {
      setSelectedHeatPumpData({
        heatPumpId: products[0].id,
        variantIndex: 0,
      });
    }

    setHeatPumpsData(products);
  }, []);

  const language = langugaes.find(e => e.shortcut === currentLanguage)?.translation as Translations;

  return currentLanguage && heatPumpsData ? (
    <>
      {openAlert.open && (
        <Alert
          text={
            openAlert.messageType === AlertMessage.SUCCESS
              ? langugaes.find(e => e.shortcut === currentLanguage)?.translation.pwa.download
              : langugaes.find(e => e.shortcut === currentLanguage)?.translation.pwa.update
          }
          onClick={() => setOpenAlert({ open: false })}
        />
      )}
      <InjectScript script={language.cookieConsent} />
      <BackgroundSelectionContext.Provider value={{ data: soundboxSettings, setData: setSoundboxSettings }}>
        <SelectedHeatPumpContext.Provider value={{ data: selectedHeatPumpData, setData: setSelectedHeatPumpData }}>
          <PageDataContext.Provider
            value={{
              heatPumps: heatPumpsData,
              translations: language,
            }}
          >
            <main style={{ minHeight: (height || 0) < 300 ? 300 : height, overflow: "hidden" }}>
              <Routes>
                <Route path="/:lang/soundbox" element={<Soundbox />} />
                <Route path="/:lang" element={<ProductSelectionWrapper height={height as number} />} />
                <Route path="*" element={<Navigate to={"/"} />} />
              </Routes>
            </main>
          </PageDataContext.Provider>
        </SelectedHeatPumpContext.Provider>
      </BackgroundSelectionContext.Provider>
    </>
  ) : null;
}

export default App;

const InjectScript: React.FC<{ script: string }> = memo(({ script }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current === null) {
      return;
    }

    const doc = document.createRange().createContextualFragment(script);

    divRef.current.innerHTML = "";
    divRef.current.appendChild(doc);
  }, []);

  return <div ref={divRef} />;
});
