import classNames from "classnames";
import { ReactComponent as RightArrowIcon } from "../../static/icons/rightArrow.svg";
import "./SoundboxCarouselButton.scss";

type SoundboxCarouselButtonProps = {
  type: "next" | "back";
  text: string;
  subText?: {
    distance: string;
    distanceDescription?: string;
  };
  disabled?: boolean;
  onClick: () => void;
};

export const SoundboxCarouselButton = (props: SoundboxCarouselButtonProps) => {
  return (
    <button
      disabled={props.disabled}
      className={classNames("soundbox-carousel-button", `soundbox-carousel-button--${props.type}`)}
      onClick={props.onClick}
    >
      <span className="soundbox-carousel-button__icon">
        <RightArrowIcon />
      </span>
      <div className="soundbox-carousel-button__text">
        <span className="soundbox-carousel-button__title">{props.text}</span>
        <span className="soundbox-carousel-button__description">
          {props?.subText?.distance}
          {"\xa0"}
          {props.subText?.distanceDescription && (
            <span className="soundbox-carousel-button__subtext">{props.subText?.distanceDescription}</span>
          )}
        </span>
      </div>
    </button>
  );
};
