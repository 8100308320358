import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { langugaes } from "../App";

export const useGetAvailableLanguages = () => {
  const location = useLocation();

  const availableLanguages = useMemo(() => {
    const language = langugaes.find(e => e.shortcut === location.pathname.split("/")[1]);

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("langs")) {
      const langs = queryParams.get("langs")?.split(",");
      return langugaes.filter(e => langs?.includes(String(e.shortcut)));
    }

    return language?.shortcut ? langugaes.filter(e => e.shortcut.startsWith(language.shortcut.split("-")[0])) : [];
  }, [location.pathname]);

  return { availableLanguages };
};
