import classNames from "classnames";
import "./SwitchButton.scss";

type SwitchButtonProps = {
  active?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export const SwitchButton = (props: SwitchButtonProps) => {
  return (
    <button
      className={classNames("switch-button", props.active && "switch-button--active")}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
