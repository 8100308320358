import "./BasicButton.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

type BasicButtonProps = {
  children: React.ReactNode | string;
  className?: string;
  disabled?: boolean;
  href?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
};

export const BasicButton = (props: BasicButtonProps) => {
  if (!props.href) {
    return (
      <button disabled={props.disabled} className={classNames("basic-button", props.className)} onClick={props.onClick}>
        {props.icon && <span className="basic-button__icon">{props.icon}</span>}
        {props.children}
      </button>
    );
  }

  return (
    <Link to={props.href} className={classNames("basic-button", props.className)} onClick={props.onClick}>
      {props.icon && <span className="basic-button__icon">{props.icon}</span>}
      {props.children}
    </Link>
  );
};
