import classNames from "classnames";
import "./Modal.scss";

type ModalProps = {
  children: React.ReactNode;
  type?: "default" | "text";
};

export const Modal = (props: ModalProps) => {
  return (
    <div className="modal">
      <div
        className={classNames(
          "modal__content",
          props.type === "text" ? "modal__content--text" : "modal__content--default",
        )}
      >
        {props.children}
      </div>
    </div>
  );
};
