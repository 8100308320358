import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import "./RangeInput.scss";

type RangeInputProps = {
  className?: string;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
};

export const RangeInput = (props: RangeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const val = ((props.value - 0) / (100 - 0)) * 100;
    inputRef.current.style.background =
      "linear-gradient(to right, rgba(0, 137, 125, 1) 0%, rgba(0, 137, 125, 1) " +
      val +
      "%, rgba(0, 0, 0, 0.2) " +
      val +
      "%, rgba(0, 0, 0, 0.2) 100%)";
  }, [props.value]);

  return (
    <input
      value={props.value}
      ref={inputRef}
      type="range"
      min="0"
      max="100"
      className={classNames("range-input", props.className)}
      onChange={e => props.setValue(+e.target.value)}
    />
  );
};
