import { BasicButton } from "../BasicButton/BasicButton";
import "./Button.scss";
import "../../scss/buttons.scss";
import classNames from "classnames";

type ButtonProps = {
  children: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  type: "tertiary" | "primary" | "secondary";
  className?: string;
  onClick?: () => void;
  href?: string;
};

export const Button = (props: ButtonProps) => {
  return (
    <BasicButton
      className={classNames(`custom-button button button__${props.type}`, props.className)}
      onClick={props.onClick}
      href={props.href}
      icon={props.icon}
      disabled={props.disabled}
    >
      {props.children}
    </BasicButton>
  );
};
