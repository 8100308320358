import React from "react";
import { HeatPumpData } from "../types/heatPump";
import { Translations } from "../types/languages";
import en from "../translations/vaillant/en.json";
import { heatPumpDataVaillant } from "./vaillantData";
export const heatPumpData = heatPumpDataVaillant;

export const contextValue = {
  translations: en,
  heatPumps: heatPumpData,
};

export const PageDataContext = React.createContext<{
  translations: Translations;
  heatPumps: Array<HeatPumpData>;
}>({ translations: en as Translations, heatPumps: [] });
