import classNames from "classnames";
import "./ControlButton.scss";

export type ControlButtonProps = {
  image: string;
  alt: string;
  active?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

export const ControlButton = (props: ControlButtonProps) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames("control-button", props.disabled && "control-button--disabled")}
      onClick={props.onClick}
    >
      <img
        src={props.image}
        alt={props.alt}
        className={classNames("control-button__image", props.active && "control-button__image--active")}
      />
    </div>
  );
};
