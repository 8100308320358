import { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { PageDataContext } from "../../context/PageDataContext";
import { ProductSelection } from "../ProductSelection/ProductSelection";
import { SelectedProductIntro } from "../SelectedProductIntro/SelectedProductIntro";

type ProductSelectionWrapperProps = {
  height: number;
};

export const ProductSelectionWrapper = (props: ProductSelectionWrapperProps) => {
  const context = useContext(PageDataContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return queryParams.has("startProduct") ? (
    <SelectedProductIntro context={context} />
  ) : (
    <ProductSelection height={props.height} context={context} />
  );
};
