import { useEffect, useRef, useState } from "react";

export const useLoadingDataDelay = (loadingStatus: boolean, delay: number = 800) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const showSoundLoadingTimeOut = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (showSoundLoadingTimeOut.current) {
      clearTimeout(showSoundLoadingTimeOut.current);
    }

    if (!loadingStatus) {
      setShowLoading(false);
    } else {
      showSoundLoadingTimeOut.current = setTimeout(() => {
        setShowLoading(true);
      }, delay);
    }
  }, [loadingStatus]);

  return {
    showLoading,
  };
};
