import { useEffect, useRef } from "react";

export const useSelect = (open?: boolean) => {
  const selectListRef = useRef<HTMLUListElement>(null);
  const selectButtonRef = useRef<HTMLButtonElement>(null);

  const setSelectPosition = () => {
    if (!selectListRef.current || !selectButtonRef.current) {
      return;
    }
    const domRect = selectButtonRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - domRect.bottom;

    const listHeight = selectListRef.current.getBoundingClientRect().height;

    if (spaceBelow - listHeight < 0) {
      selectListRef.current.style.bottom = -(spaceBelow - listHeight - 20) + "px";
      selectListRef.current.style.left = "0";
    } else {
      selectListRef.current.style.bottom = "0";
      selectListRef.current.style.left = "0";
    }
  };

  useEffect(() => {
    setSelectPosition();
  }, [selectListRef.current, selectButtonRef.current, open]);

  useEffect(() => {
    window.addEventListener("resize", setSelectPosition);

    return () => window.removeEventListener("resize", setSelectPosition);
  }, []);

  return {
    selectListRef,
    selectButtonRef,
  };
};
