import { RefObject, useEffect, useRef, useState } from "react";

export const useCarousel = (
  step: number,
  length: number,
  refs: Array<RefObject<HTMLDivElement>>,
  enableActiveIndex?: boolean,
) => {
  const translate = useRef<number>(0);
  const [disableArrow, setDisableArrow] = useState<{ left: boolean; right: boolean }>({ left: true, right: true });
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    disableCarouselArrows();
  }, [step, length]);

  const disableCarouselArrows = () => {
    if (step === 0) {
      return;
    }

    let disableLeftArrow = false;
    let disableRightArrow = false;

    if (translate.current === 0) {
      disableLeftArrow = true;
    }

    if (-translate.current / step + 100 / step >= length) {
      disableRightArrow = true;
    }

    if (disableLeftArrow !== disableArrow.left || disableRightArrow !== disableArrow.right) {
      setDisableArrow({
        left: disableLeftArrow,
        right: disableRightArrow,
      });
    }
  };

  const translateCarousel = (direction: "left" | "right") => {
    const translateValue = direction === "left" ? -step : step;

    changeTranslate(translate.current - translateValue);
  };

  const changeTranslate = (value: number) => {
    const invalidRef = refs.find(e => e.current === null);

    if (invalidRef?.current === null) {
      return;
    }

    refs.forEach(e => {
      e.current!!.style.transform = `translateX(${value}%)`;
    });
    translate.current = value;

    if (enableActiveIndex) {
      setActiveIndex(-value / step);
    }

    disableCarouselArrows();
  };

  return {
    translate,
    translateCarousel,
    disableArrow,
    changeTranslate,
    activeIndex,
  };
};
