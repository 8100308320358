import "./ProductSelection.scss";
import { Carousel } from "../../components/Carousel/Carousel";
import { Tag } from "../../components/Tag/Tag";
import { Select } from "../../components/Select/Select";
import { Button } from "../../components/Button/Button";
import { useContext, useEffect, useMemo, useState } from "react";
import { PageDataContext } from "../../context/PageDataContext";
import { SelectedHeatPumpContext, SelectedHeatPumpData } from "../../context/SelectedHeatPumpContext";
import { LanguagePicker } from "../../components/LanguagePicker/LanguagePicker";
import { ReactComponent as SoundMaxIcon } from "../../static/icons/soundMax.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Category } from "../../types/category";
import { checkIfIsMoreThanOneCategory } from "../../utils/checkIfIsMoreThanOneCategory";
import classNames from "classnames";
import { useGetAvailableLanguages } from "../../hooks/useGetAvailableLanguages";
import { TextButton } from "../../components/TextButton/TextButton";
import { Disclaimer } from "../../components/Disclaimer/Disclaimer";
import { Translations } from "../../types/languages";
import { HeatPumpData } from "../../types/heatPump";

type ProductSelectionProps = {
  height: number;
  context: {
    translations: Translations;
    heatPumps: Array<HeatPumpData>;
  };
};

export const ProductSelection = (props: ProductSelectionProps) => {
  const { context } = props;
  const selectedHeatPumpContext = useContext(SelectedHeatPumpContext);

  const [category, setCategory] = useState<Category>("all");
  const { lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { availableLanguages } = useGetAvailableLanguages();
  const showTags = useMemo(() => checkIfIsMoreThanOneCategory(context.heatPumps), [context.heatPumps]);
  const [openDisclaimer, setOpenDisclaimer] = useState<boolean>(false);

  const heatPumps = useMemo(() => {
    if (category === "water") {
      return context.heatPumps.filter(e => e.category === "water");
    } else if (category === "air") {
      return context.heatPumps.filter(e => e.category === "air");
    } else if (category === "floor") {
      return context.heatPumps.filter(e => e.category === "floor");
    }

    return context.heatPumps;
  }, [category, context.heatPumps]);

  const [carouselHeatPumpSelection, setCarouselHeatPumpSelection] = useState<{
    selectedHeatPumpId: number;
    variantIndex: number;
  }>(
    selectedHeatPumpContext.data
      ? {
          selectedHeatPumpId: selectedHeatPumpContext.data.heatPumpId,
          variantIndex: selectedHeatPumpContext.data.variantIndex,
        }
      : {
          selectedHeatPumpId: heatPumps[0].id,
          variantIndex: 0,
        },
  );

  const varaintCounts = useMemo(() => {
    return {
      all: context.heatPumps.length,
      water: context.heatPumps.filter(e => e.category === "water").length,
      air: context.heatPumps.filter(e => e.category === "air").length,
      floor: context.heatPumps.filter(e => e.category === "floor").length,
    };
  }, [context.heatPumps]);

  return (
    <>
      <div className="select-product" style={{ minHeight: props.height + "px" }}>
        <h1 className={classNames("select-product__title")}>{context.translations.intro.productSelection.title}</h1>
        <p className="select-product__subtitle">{context.translations.intro.productSelection.subtitle}</p>

        {showTags && (
          <div className="select-product__tags">
            <Tag
              label={`${context.translations.soundbox.all} (${varaintCounts.all})`}
              active={category === "all"}
              className="select-product__tag"
              onClick={() => setCategory("all")}
              disabled={varaintCounts.all === 0}
            />
            <Tag
              active={category === "air"}
              label={`${context.translations.soundbox.air} (${varaintCounts.air})`}
              className="select-product__tag"
              onClick={() => setCategory("air")}
              disabled={varaintCounts.air === 0}
            />
            <Tag
              active={category === "water"}
              label={`${context.translations.soundbox.water} (${varaintCounts.water})`}
              className="select-product__tag"
              onClick={() => setCategory("water")}
              disabled={varaintCounts.water === 0}
            />
            <Tag
              active={category === "floor"}
              label={`${context.translations.soundbox.floor} (${varaintCounts.floor})`}
              className="select-product__tag"
              onClick={() => setCategory("floor")}
              disabled={varaintCounts.floor === 0}
            />
          </div>
        )}
        <Carousel
          items={heatPumps}
          translations={context.translations}
          setSelectedPump={setCarouselHeatPumpSelection}
          selectedPump={carouselHeatPumpSelection}
          category={category}
        />
        <div className="select-product__button-wrapper">
          <Button
            type="primary"
            icon={<SoundMaxIcon />}
            onClick={() => {
              selectedHeatPumpContext.setData({
                heatPumpId: carouselHeatPumpSelection.selectedHeatPumpId,
                variantIndex: carouselHeatPumpSelection.variantIndex,
              });

              const heatPump = context.heatPumps.find(e => e.id === carouselHeatPumpSelection.selectedHeatPumpId)
                ?.variants[carouselHeatPumpSelection.variantIndex];

              if (heatPump) {
                (window as any).dataLayer.push({
                  event: "hp_selection",
                  hp_model: heatPump.shortcut,
                  power_setting: heatPump.power + " kW",
                });
              }

              navigate(`/${lang}/soundbox${location.search}`);
            }}
          >
            {context.translations.intro.productSelection.buttonText}
          </Button>
        </div>

        <div className={"select-product__bottom-items-wrapper"}>
          <div className={"select-product__bottom-item"}>
            <TextButton
              tag={{ type: "button" }}
              text={context.translations.disclaimer.disclaimerButtonText}
              onClick={() => {
                setOpenDisclaimer(true);
              }}
            />
          </div>
          <div className={"select-product__bottom-item"}>
            <TextButton
              tag={{ type: "link", href: context.translations.impressum.impressumUrl }}
              text={context.translations.impressum.impressumButtonText}
            />
          </div>
          {availableLanguages.length > 1 && (
            <div className={"select-product__bottom-item"}>
              <LanguagePicker type="grey" languages={availableLanguages} />
            </div>
          )}
        </div>
      </div>
      {openDisclaimer && (
        <Disclaimer
          handleClose={() => {
            setOpenDisclaimer(false);
          }}
        />
      )}
    </>
  );
};
