import { Scene } from "./../types/heatPump";
import { useContext, useMemo } from "react";
import { PageDataContext } from "../context/PageDataContext";
import { SelectedHeatPumpContext } from "../context/SelectedHeatPumpContext";
import { useIsSummer } from "./useIsSummer";

export const useGetCurrentScenes = () => {
  const selectedHeatPumpContext = useContext(SelectedHeatPumpContext);
  const context = useContext(PageDataContext);
  const { isSummer } = useIsSummer(context.translations);

  const currentScenes = useMemo(() => {
    const data = context.heatPumps.find(e => e.id === selectedHeatPumpContext.data.heatPumpId)!!.variants[
      selectedHeatPumpContext.data.variantIndex
    ];
    if (isSummer) {
      if (data.scenesSummer) {
        return [...data.scenesSummer.map(e => ({ ...e, summerImage: true, outside: true }))];
      }
    } else {
      if (data.scenesWinter) {
        return [...data.scenesWinter.map(e => ({ ...e, summerImage: false, outside: true }))];
      }
    }
    return [];
  }, [selectedHeatPumpContext.data]);

  return currentScenes;
};
