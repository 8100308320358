import "./CTAButton.scss";
import { ReactComponent as ArrowDown } from "../../static/icons/arrowDown.svg";

type CTAButtonProps = {
  text: string;
  href: string;
  onClick: () => void;
};

export const CTAButton = (props: CTAButtonProps) => {
  const getClickableLink = (link: string) => {
    return link.startsWith("http://") || link.startsWith("https://") ? link : `//${link}`;
  };
  return (
    <a className="cta-button" href={getClickableLink(props.href)} target={"_blank"} onClick={props.onClick}>
      {props.text}
      <span className="cta-button__icon">
        <ArrowDown />
      </span>
    </a>
  );
};
