import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { PageDataContext } from "../context/PageDataContext";
import { SelectedHeatPumpContext } from "../context/SelectedHeatPumpContext";
import { Scene, Sound } from "../types/heatPump";

export const useHeatPumpAudio = (
  activeIndex: number,
  turnOnSound: boolean,
  silentMode: boolean,
  currentScenes: Scene[],
  setTurnOnSound: React.Dispatch<React.SetStateAction<boolean>>,
  mute: boolean,
) => {
  const currentAudioData = useRef<Sound>();
  const selectedHeatPumpContext = useContext(SelectedHeatPumpContext);
  const context = useContext(PageDataContext);
  const [loading, setLoading] = useState<boolean>(false);
  const maxVolumeMap = useMemo(() => mapMaxVolumeData(), [context.heatPumps]);
  const audioCtxRef = useRef<AudioContext>();
  const trackRef = useRef<MediaElementAudioSourceNode>();
  const audioElementRef = useRef<HTMLAudioElement>();
  const gainNodeRef = useRef<GainNode>();
  const loadingData = useRef<boolean>(false);
  const loadingDataRetry = useRef<boolean>(false);
  const [retryTrigger, setRetryTrigger] = useState<{ retry: "RETRY" }>({ retry: "RETRY" });

  useEffect(() => {
    const audioElement = new Audio();
    audioElement.loop = true;

    const callback = () => {
      setLoading(false);
    };

    const pause = () => {
      setTurnOnSound(false);
    };

    const play = () => {
      setTurnOnSound(true);
    };

    audioElement.addEventListener("canplay", callback);
    audioElement.addEventListener("pause", pause);
    audioElement.addEventListener("play", play);
    audioElementRef.current = audioElement;

    return () => {
      audioElement.pause();
      audioElement.src = "";
      audioElement.removeEventListener("canplay", callback);
      audioElement.removeEventListener("pause", pause);
      audioElement.removeEventListener("play", play);

      trackRef.current?.disconnect(gainNodeRef.current as GainNode);
    };
  }, []);

  const init = () => {
    if (!audioElementRef.current) {
      return;
    }

    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    const audioCtx = new AudioContext();
    const track = audioCtx.createMediaElementSource(audioElementRef.current);
    const gainNode = audioCtx.createGain();

    gainNodeRef.current = gainNode;
    trackRef.current = track;
    audioCtxRef.current = audioCtx;

    track.connect(gainNode).connect(audioCtx.destination);
  };

  const loadData = async () => {
    if (!audioElementRef.current) {
      return;
    }

    loadingData.current = true;

    const scene = currentScenes[activeIndex];

    const sound = silentMode && scene?.silentMode ? scene.silentMode : scene?.sound;

    if (sound && sound.url !== currentAudioData.current?.url) {
      setLoading(true);
      audioElementRef.current.src = sound.url;
      audioElementRef.current.pause();
      audioElementRef.current.load();
      turnOnSound && (await audioElementRef.current.play().catch(e => {}));
      currentAudioData.current = sound;
    }

    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = selectVolume(sound);
    }

    loadingData.current = false;
    if (loadingDataRetry.current) {
      loadingDataRetry.current = false;
      setRetryTrigger({ retry: "RETRY" });
    }
  };

  useLayoutEffect(() => {
    if (!currentScenes[activeIndex] || !turnOnSound) {
      return;
    }

    if (!audioCtxRef.current) {
      init();
    }

    if (!loadingData.current) {
      loadData();
    } else {
      loadingDataRetry.current = true;
    }
  }, [activeIndex, selectedHeatPumpContext.data, silentMode, retryTrigger, turnOnSound]);

  function mapMaxVolumeData() {
    const soundMaxVolumeMap = new Map<string, number>();

    const addSoundToMap = (scene: Scene) => {
      if (
        scene.sound?.url &&
        (!soundMaxVolumeMap.has(scene.sound.url) || scene.sound.volume > (soundMaxVolumeMap.get(scene.sound.url) || 0))
      ) {
        soundMaxVolumeMap.set(scene.sound.url, scene.sound.volume);
      }

      if (
        scene.silentMode?.url &&
        (!soundMaxVolumeMap.has(scene.silentMode.url) ||
          scene.silentMode.volume > (soundMaxVolumeMap.get(scene.silentMode.url) || 0))
      ) {
        soundMaxVolumeMap.set(scene.silentMode.url, scene.silentMode.volume);
      }
    };

    context.heatPumps.forEach(pump => {
      pump.variants.forEach(variant => {
        variant.scenesWinter?.forEach(addSoundToMap);
        variant.scenesSummer?.forEach(addSoundToMap);
      });
    });

    return soundMaxVolumeMap;
  }

  const countVolumeToLinear = (volume: number) => Math.pow(10, volume / 20);

  const selectVolume = (sound: Sound) => {
    const maxVolume = maxVolumeMap.get(sound.url);

    if (typeof maxVolume === "number") {
      return countVolumeToLinear(sound.volume) / countVolumeToLinear(maxVolume);
    }
    return 1;
  };

  useLayoutEffect(() => {
    if (turnOnSound) {
      audioElementRef.current?.play().catch(e => {});
    } else {
      audioElementRef.current?.pause();
    }
  }, [turnOnSound]);

  const muteAudio = () => {
    if (!gainNodeRef.current || !currentAudioData.current) {
      return;
    }

    if (mute) {
      gainNodeRef.current.gain.value = 0;
    } else {
      gainNodeRef.current.gain.value = selectVolume(currentAudioData.current);
    }
  };

  useLayoutEffect(() => {
    muteAudio();
  }, [mute]);

  return { loading };
};
