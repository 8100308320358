import { useContext, useState } from "react";
import { PageDataContext } from "../../context/PageDataContext";
import { useGetCurrentScenes } from "../../hooks/useGetCurrentScenes";
import { ReactComponent as CloseIcon } from "../../static/icons/close.svg";
import { SoundboxSettings } from "../../types/soundboxSettings";
import { IconButton } from "../IconButton/IconButton";
import { QuickChange } from "../QuickChange/QuickChange";
import { SceneDescriptionImage } from "../SceneDescriptionImage/SceneDescriptionImage";
import "./SceneDescription.scss";

type SceneDescriptionProps = {
  setOpenSoundSelection: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      wasPausedBeforeChange: boolean;
    }>
  >;
  soundboxSettings: SoundboxSettings;
  muteSurroundings: boolean;
  setMuteSurroundings: React.Dispatch<React.SetStateAction<boolean>>;
  activeIndex: number;
  changeTranslate: (value: number) => void;
};

export const SceneDescription = (props: SceneDescriptionProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const context = useContext(PageDataContext);
  const currentScenes = useGetCurrentScenes();

  const getDistanceFromHeatPump = () => {
    const currentScene = currentScenes[props.activeIndex];

    if (!currentScene) {
      return "";
    }

    if (currentScene.outside) {
      return `${context.translations.soundbox.outside} ${currentScene.distance}m`;
    }
    return `${context.translations.soundbox.inside} ${currentScene.distance}m`;
  };

  const handleSceneChange = (index: number) => {
    props.changeTranslate(index * -100);
  };

  return (
    <>
      <div className="scene-description">
        <button className="scene-description__button" onClick={() => setOpen(open => !open)}>
          <span className="scene-description__distance">{getDistanceFromHeatPump()}</span>
          <span className="scene-description__counter">
            {context.translations.soundbox.scene} {props.activeIndex + 1}/{currentScenes.length}
          </span>
        </button>
        <div className="scene-description__text-wrapper scene-description__text-wrapper--big-screen">
          <span className="scene-description__distance">{getDistanceFromHeatPump()}</span>
          <span className="scene-description__counter">
            {context.translations.soundbox.scene} {props.activeIndex + 1}/{currentScenes.length}
          </span>
        </div>
        <div className="scene-description__images-wrapper scene-description__images-wrapper--big-screen">
          {currentScenes.map((e, index) => {
            return (
              <div className="scene-description__image-wrapper">
                <SceneDescriptionImage
                  onClick={() => handleSceneChange(index)}
                  active={index === props.activeIndex}
                  distance={e.distance + "m"}
                  img={e.gallery}
                />
              </div>
            );
          })}
        </div>

        <QuickChange
          setOpenSoundSelection={props.setOpenSoundSelection}
          soundboxSettings={props.soundboxSettings}
          muteSurroundings={props.muteSurroundings}
          setMuteSurroundings={props.setMuteSurroundings}
        />

        {open && (
          <div className="scene-description__scene-selector-mobile">
            <div className="scene-description__mobile-menu">
              <IconButton onClick={() => setOpen(open => !open)}>
                <CloseIcon />
              </IconButton>
              <div className="scene-description__text-wrapper">
                <span className="scene-description__distance">{getDistanceFromHeatPump()}</span>
                <span className="scene-description__counter">
                  {context.translations.soundbox.scene} {props.activeIndex + 1}/{currentScenes.length}
                </span>
              </div>
              <QuickChange
                setOpenSoundSelection={props.setOpenSoundSelection}
                soundboxSettings={props.soundboxSettings}
                muteSurroundings={props.muteSurroundings}
                setMuteSurroundings={props.setMuteSurroundings}
              />
            </div>
            <div className="scene-description__images-wrapper">
              {currentScenes.map((e, index) => {
                return (
                  <div className="scene-description__image-wrapper">
                    <SceneDescriptionImage
                      onClick={() => handleSceneChange(index)}
                      active={props.activeIndex === index}
                      distance={e.distance + "m"}
                      img={e.img}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
