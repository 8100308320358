import "./OverlayRestart.scss";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import { PageDataContext } from "../../context/PageDataContext";
import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

type OverlayRestartType = {
  setOverlayRestart: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OverlayRestart = (props: OverlayRestartType) => {
  const context = useContext(PageDataContext);
  const location = useLocation();
  const { lang } = useParams();

  return (
    <Modal>
      <div className="overlay-restart">
        <h1 className="overlay-restart__title">{context.translations.overlayRestart.title}</h1>
        <p className="overlay-restart__subtitle">{context.translations.overlayRestart.subtitle}</p>
        <div className="overlay-restart__buttons-wrapper">
          <div className="overlay-restart__first-button-wrapper">
            <Button type="secondary" className="overlay-restart__button" href={`/${lang}${location.search}`}>
              {context.translations.overlayRestart.backButtonText}
            </Button>
          </div>
          <Button type="tertiary" className="overlay-restart__button" onClick={() => props.setOverlayRestart(false)}>
            {context.translations.overlayRestart.cancelButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
