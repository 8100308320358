import { BasicButton } from "../BasicButton/BasicButton";
import { LanguagePicker } from "../LanguagePicker/LanguagePicker";
import { ReactComponent as SoundMaxIcon } from "../../static/icons/soundMax.svg";
import "./ProductIntroV1.scss";
import { useCurrentBrowserZoom } from "../../hooks/useCurrentBrowserZoom";

type ProductIntroV1Props = {
  images?: Array<string>;
  pageTitle: string;
  lang?: string;
  locationSearch: string;
  buttonText: string;
  availableLanguages: Array<{ language: string; shortcut: string }>;
};

export const ProductIntroV1 = (props: ProductIntroV1Props) => {
  const { images, pageTitle, lang, locationSearch, buttonText, availableLanguages } = props;
  const { setPictureSourceImageMaxWidth } = useCurrentBrowserZoom();

  return (
    <div className="selected-product-intro-v1">
      {images && (
        <picture className="selected-product-image">
          <source srcSet={images[0]} media={setPictureSourceImageMaxWidth(768)} />
          <source srcSet={images[1]} media={`${setPictureSourceImageMaxWidth(1302)} and (orientation: portrait)`} />
          <img src={images[2]} alt={"Heat pump"} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
        </picture>
      )}

      <div className="selected-product-description">
        <h1 className="selected-product-description__title">{pageTitle}</h1>
        <BasicButton
          className="selected-product-description__button"
          href={`/${lang}/soundbox/${locationSearch}`}
          icon={<SoundMaxIcon />}
        >
          {buttonText}
        </BasicButton>
        {availableLanguages.length > 1 && (
          <div className="selected-product-description__language-picker-wrapper">
            <LanguagePicker type="light" languages={availableLanguages} />
          </div>
        )}
      </div>
    </div>
  );
};
