import "./TextButton.scss";

type TextButtonProps = {
  text: string;
  onClick?: () => void;
  tag:
    | {
        type: "button";
      }
    | {
        type: "link";
        href: string;
      };
};

export const TextButton = (props: TextButtonProps) => {
  if (props.tag.type === "button") {
    return (
      <button onClick={props.onClick} className="text-button">
        {props.text}
      </button>
    );
  }

  return (
    <a onClick={props.onClick} className="text-button" href={props.tag.href} target="_blank">
      {props.text}
    </a>
  );
};
