import classNames from "classnames";
import "./IconButton.scss";

type IconButtonProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const IconButton = (props: IconButtonProps) => {
  return (
    <button onClick={props.onClick} className={classNames("icon-button", props.className)}>
      {props.children}
    </button>
  );
};
