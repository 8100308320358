import { HeatPumpData } from "../types/heatPump";

//aroTHERM perform
//18kW

//Outdoor
//Summer
import aroThermPeform18kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-small-1m-hover.jpg";
import aroThermPeform18kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-large-1m-hover.jpg";
import aroThermPeform18kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-small-3m-hover.jpg";
import aroThermPeform18kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-large-3m-hover.jpg";
import aroThermPeform18kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-small-5m-hover.jpg";
import aroThermPeform18kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-large-5m-hover.jpg";

import aroThermPeform18kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-summer-small.jpg";
import aroThermPeform18kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-summer-medium.jpg";
import aroThermPeform18kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-summer-large.jpg";

import aroThermPeform18kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-small-1m.jpg";
import aroThermPeform18kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-medium-1m.jpg";
import aroThermPeform18kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-large-1m.jpg";
import aroThermPeform18kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-small-3m.jpg";
import aroThermPeform18kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-medium-3m.jpg";
import aroThermPeform18kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-large-3m.jpg";
import aroThermPeform18kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-small-5m.jpg";
import aroThermPeform18kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-medium-5m.jpg";
import aroThermPeform18kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-large-5m.jpg";

import aroThermPeform18kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-summer-small-hover.jpg";
import aroThermPeform18kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-summer-medium-hover.jpg";
import aroThermPeform18kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-summer-large-hover.jpg";

//Winter
import aroThermPeform18kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-small-1m-hover.jpg";
import aroThermPeform18kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-large-1m-hover.jpg";
import aroThermPeform18kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-small-3m-hover.jpg";
import aroThermPeform18kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-large-3m-hover.jpg";
import aroThermPeform18kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-small-5m-hover.jpg";
import aroThermPeform18kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-large-5m-hover.jpg";

import aroThermPeform18kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-winter-small.jpg";
import aroThermPeform18kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-winter-medium.jpg";
import aroThermPeform18kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-winter-large.jpg";

import aroThermPeform18kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-small-1m.jpg";
import aroThermPeform18kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-medium-1m.jpg";
import aroThermPeform18kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-large-1m.jpg";
import aroThermPeform18kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-small-3m.jpg";
import aroThermPeform18kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-medium-3m.jpg";
import aroThermPeform18kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-large-3m.jpg";
import aroThermPeform18kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-small-5m.jpg";
import aroThermPeform18kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-medium-5m.jpg";
import aroThermPeform18kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-large-5m.jpg";

import aroThermPeform18kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-winter-small-hover.jpg";
import aroThermPeform18kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-winter-medium-hover.jpg";
import aroThermPeform18kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM perform/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-winter-large-hover.jpg";

//25kW

//Outdoor
//Summer
import aroThermPeform25kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-small-1m-hover.jpg";
import aroThermPeform25kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-large-1m-hover.jpg";
import aroThermPeform25kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-small-3m-hover.jpg";
import aroThermPeform25kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-large-3m-hover.jpg";
import aroThermPeform25kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-small-5m-hover.jpg";
import aroThermPeform25kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-summer-large-5m-hover.jpg";

import aroThermPeform25kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-summer-small.jpg";
import aroThermPeform25kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-summer-medium.jpg";
import aroThermPeform25kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-summer-large.jpg";

import aroThermPeform25kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-small-1m.jpg";
import aroThermPeform25kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-medium-1m.jpg";
import aroThermPeform25kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-large-1m.jpg";
import aroThermPeform25kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-small-3m.jpg";
import aroThermPeform25kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-medium-3m.jpg";
import aroThermPeform25kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-large-3m.jpg";
import aroThermPeform25kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-small-5m.jpg";
import aroThermPeform25kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-medium-5m.jpg";
import aroThermPeform25kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-summer-large-5m.jpg";

import aroThermPeform25kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-summer-small-hover.jpg";
import aroThermPeform25kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-summer-medium-hover.jpg";
import aroThermPeform25kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-summer-large-hover.jpg";

//Winter
import aroThermPeform25kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-small-1m-hover.jpg";
import aroThermPeform25kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-large-1m-hover.jpg";
import aroThermPeform25kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-small-3m-hover.jpg";
import aroThermPeform25kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-large-3m-hover.jpg";
import aroThermPeform25kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-small-5m-hover.jpg";
import aroThermPeform25kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-ODU-winter-large-5m-hover.jpg";

import aroThermPeform25kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-winter-small.jpg";
import aroThermPeform25kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-winter-medium.jpg";
import aroThermPeform25kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-ODU-winter-large.jpg";

import aroThermPeform25kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-small-1m.jpg";
import aroThermPeform25kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-medium-1m.jpg";
import aroThermPeform25kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-large-1m.jpg";
import aroThermPeform25kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-small-3m.jpg";
import aroThermPeform25kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-medium-3m.jpg";
import aroThermPeform25kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-large-3m.jpg";
import aroThermPeform25kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-small-5m.jpg";
import aroThermPeform25kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-medium-5m.jpg";
import aroThermPeform25kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-ODU-winter-large-5m.jpg";

import aroThermPeform25kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-winter-small-hover.jpg";
import aroThermPeform25kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-winter-medium-hover.jpg";
import aroThermPeform25kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM perform/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-ODU-winter-large-hover.jpg";

//aroTHERM perform (SSP)
//18kW

//Outdoor
//Summer
import aroThermPeformSSP18kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-small-1m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-large-1m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-small-3m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-large-3m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-small-5m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-large-5m-hover.jpg";

import aroThermPeformSSP18kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-summer-small.jpg";
import aroThermPeformSSP18kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-summer-medium.jpg";
import aroThermPeformSSP18kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-summer-large.jpg";

import aroThermPeformSSP18kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-small-1m.jpg";
import aroThermPeformSSP18kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-medium-1m.jpg";
import aroThermPeformSSP18kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-large-1m.jpg";
import aroThermPeformSSP18kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-small-3m.jpg";
import aroThermPeformSSP18kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-medium-3m.jpg";
import aroThermPeformSSP18kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-large-3m.jpg";
import aroThermPeformSSP18kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-small-5m.jpg";
import aroThermPeformSSP18kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-medium-5m.jpg";
import aroThermPeformSSP18kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-large-5m.jpg";

import aroThermPeformSSP18kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-summer-small-hover.jpg";
import aroThermPeformSSP18kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-summer-medium-hover.jpg";
import aroThermPeformSSP18kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-summer-large-hover.jpg";

//Winter
import aroThermPeformSSP18kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-small-1m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-large-1m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-small-3m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-large-3m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-small-5m-hover.jpg";
import aroThermPeformSSP18kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-large-5m-hover.jpg";

import aroThermPeformSSP18kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-winter-small.jpg";
import aroThermPeformSSP18kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-winter-medium.jpg";
import aroThermPeformSSP18kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-winter-large.jpg";

import aroThermPeformSSP18kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-small-1m.jpg";
import aroThermPeformSSP18kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-medium-1m.jpg";
import aroThermPeformSSP18kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-large-1m.jpg";
import aroThermPeformSSP18kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-small-3m.jpg";
import aroThermPeformSSP18kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-medium-3m.jpg";
import aroThermPeformSSP18kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-large-3m.jpg";
import aroThermPeformSSP18kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-small-5m.jpg";
import aroThermPeformSSP18kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-medium-5m.jpg";
import aroThermPeformSSP18kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-large-5m.jpg";

import aroThermPeformSSP18kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-winter-small-hover.jpg";
import aroThermPeformSSP18kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-winter-medium-hover.jpg";
import aroThermPeformSSP18kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/18 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-winter-large-hover.jpg";

//25kW

//Outdoor
//Summer
import aroThermPeformSSP25kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-small-1m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-large-1m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-small-3m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-large-3m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-small-5m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-summer-large-5m-hover.jpg";

import aroThermPeformSSP25kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-summer-small.jpg";
import aroThermPeformSSP25kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-summer-medium.jpg";
import aroThermPeformSSP25kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-summer-large.jpg";

import aroThermPeformSSP25kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-small-1m.jpg";
import aroThermPeformSSP25kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-medium-1m.jpg";
import aroThermPeformSSP25kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-large-1m.jpg";
import aroThermPeformSSP25kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-small-3m.jpg";
import aroThermPeformSSP25kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-medium-3m.jpg";
import aroThermPeformSSP25kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-large-3m.jpg";
import aroThermPeformSSP25kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-small-5m.jpg";
import aroThermPeformSSP25kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-medium-5m.jpg";
import aroThermPeformSSP25kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-summer-large-5m.jpg";

import aroThermPeformSSP25kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-summer-small-hover.jpg";
import aroThermPeformSSP25kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-summer-medium-hover.jpg";
import aroThermPeformSSP25kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-summer-large-hover.jpg";

//Winter
import aroThermPeformSSP25kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-small-1m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-large-1m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-small-3m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-large-3m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-small-5m-hover.jpg";
import aroThermPeformSSP25kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMperform-SoundSafePlus-ODU-winter-large-5m-hover.jpg";

import aroThermPeformSSP25kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-winter-small.jpg";
import aroThermPeformSSP25kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-winter-medium.jpg";
import aroThermPeformSSP25kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Intro/intro-aroTHERMperform-SoundSafePlus-ODU-winter-large.jpg";

import aroThermPeformSSP25kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-small-1m.jpg";
import aroThermPeformSSP25kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-medium-1m.jpg";
import aroThermPeformSSP25kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-large-1m.jpg";
import aroThermPeformSSP25kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-small-3m.jpg";
import aroThermPeformSSP25kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-medium-3m.jpg";
import aroThermPeformSSP25kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-large-3m.jpg";
import aroThermPeformSSP25kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-small-5m.jpg";
import aroThermPeformSSP25kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-medium-5m.jpg";
import aroThermPeformSSP25kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Stage/stage-aroTHERMperform-SoundSafePlus-ODU-winter-large-5m.jpg";

import aroThermPeformSSP25kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-winter-small-hover.jpg";
import aroThermPeformSSP25kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-winter-medium-hover.jpg";
import aroThermPeformSSP25kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM perform (SSP)/25 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMperform-SoundSafePlus-ODU-winter-large-hover.jpg";

//versoTHERM plus
//3kW

//Outdoor
//Summer
import versoThermPlus3kWOutdoorGallery1mSmallSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-1m-hover.jpg";
import versoThermPlus3kWOutdoorGallery1mLargeSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-1m-hover.jpg";
import versoThermPlus3kWOutdoorGallery3mSmallSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-3m-hover.jpg";
import versoThermPlus3kWOutdoorGallery3mLargeSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-3m-hover.jpg";
import versoThermPlus3kWOutdoorGallery5mSmallSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-5m-hover.jpg";
import versoThermPlus3kWOutdoorGallery5mLargeSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-5m-hover.jpg";

import versoThermPlus3kWOutdoorIntroSmallSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-small.jpg";
import versoThermPlus3kWOutdoorIntroMediumSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-medium.jpg";
import versoThermPlus3kWOutdoorIntroLargeSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-large.jpg";

import versoThermPlus3kWOutdoorStage1mSmallSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-1m.jpg";
import versoThermPlus3kWOutdoorStage1mMediumSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-1m.jpg";
import versoThermPlus3kWOutdoorStage1mLargeSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-1m.jpg";
import versoThermPlus3kWOutdoorStage3mSmallSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-3m.jpg";
import versoThermPlus3kWOutdoorStage3mMediumSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-3m.jpg";
import versoThermPlus3kWOutdoorStage3mLargeSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-3m.jpg";
import versoThermPlus3kWOutdoorStage5mSmallSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-5m.jpg";
import versoThermPlus3kWOutdoorStage5mMediumSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-5m.jpg";
import versoThermPlus3kWOutdoorStage5mLargeSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-5m.jpg";

import versoThermPlus3kWOutdoorTeaserSmallSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-small-hover.jpg";
import versoThermPlus3kWOutdoorTeaserMediumSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-medium-hover.jpg";
import versoThermPlus3kWOutdoorTeaserLargeSummer from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-large-hover.jpg";
//Winter
import versoThermPlus3kWOutdoorGallery1mSmallWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-1m-hover.jpg";
import versoThermPlus3kWOutdoorGallery1mLargeWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-1m-hover.jpg";
import versoThermPlus3kWOutdoorGallery3mSmallWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-3m-hover.jpg";
import versoThermPlus3kWOutdoorGallery3mLargeWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-3m-hover.jpg";
import versoThermPlus3kWOutdoorGallery5mSmallWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-5m-hover.jpg";
import versoThermPlus3kWOutdoorGallery5mLargeWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-5m-hover.jpg";

import versoThermPlus3kWOutdoorIntroSmallWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-small.jpg";
import versoThermPlus3kWOutdoorIntroMediumWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-medium.jpg";
import versoThermPlus3kWOutdoorIntroLargeWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-large.jpg";

import versoThermPlus3kWOutdoorStage1mSmallWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-1m.jpg";
import versoThermPlus3kWOutdoorStage1mMediumWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-1m.jpg";
import versoThermPlus3kWOutdoorStage1mLargeWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-1m.jpg";
import versoThermPlus3kWOutdoorStage3mSmallWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-3m.jpg";
import versoThermPlus3kWOutdoorStage3mMediumWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-3m.jpg";
import versoThermPlus3kWOutdoorStage3mLargeWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-3m.jpg";
import versoThermPlus3kWOutdoorStage5mSmallWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-5m.jpg";
import versoThermPlus3kWOutdoorStage5mMediumWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-5m.jpg";
import versoThermPlus3kWOutdoorStage5mLargeWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-5m.jpg";

import versoThermPlus3kWOutdoorTeaserSmallWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-small-hover.jpg";
import versoThermPlus3kWOutdoorTeaserMediumWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-medium-hover.jpg";
import versoThermPlus3kWOutdoorTeaserLargeWinter from "../static/vaillant/versoTHERM plus/3 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-large-hover.jpg";
//5kW

//Outdoor
//Summer
import versoThermPlus5kWOutdoorGallery1mSmallSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-1m-hover.jpg";
import versoThermPlus5kWOutdoorGallery1mLargeSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-1m-hover.jpg";
import versoThermPlus5kWOutdoorGallery3mSmallSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-3m-hover.jpg";
import versoThermPlus5kWOutdoorGallery3mLargeSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-3m-hover.jpg";
import versoThermPlus5kWOutdoorGallery5mSmallSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-5m-hover.jpg";
import versoThermPlus5kWOutdoorGallery5mLargeSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-5m-hover.jpg";

import versoThermPlus5kWOutdoorIntroSmallSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-small.jpg";
import versoThermPlus5kWOutdoorIntroMediumSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-medium.jpg";
import versoThermPlus5kWOutdoorIntroLargeSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-large.jpg";

import versoThermPlus5kWOutdoorStage1mSmallSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-1m.jpg";
import versoThermPlus5kWOutdoorStage1mMediumSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-1m.jpg";
import versoThermPlus5kWOutdoorStage1mLargeSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-1m.jpg";
import versoThermPlus5kWOutdoorStage3mSmallSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-3m.jpg";
import versoThermPlus5kWOutdoorStage3mMediumSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-3m.jpg";
import versoThermPlus5kWOutdoorStage3mLargeSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-3m.jpg";
import versoThermPlus5kWOutdoorStage5mSmallSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-5m.jpg";
import versoThermPlus5kWOutdoorStage5mMediumSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-5m.jpg";
import versoThermPlus5kWOutdoorStage5mLargeSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-5m.jpg";

import versoThermPlus5kWOutdoorTeaserSmallSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-small-hover.jpg";
import versoThermPlus5kWOutdoorTeaserMediumSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-medium-hover.jpg";
import versoThermPlus5kWOutdoorTeaserLargeSummer from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-large-hover.jpg";
//Winter
import versoThermPlus5kWOutdoorGallery1mSmallWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-1m-hover.jpg";
import versoThermPlus5kWOutdoorGallery1mLargeWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-1m-hover.jpg";
import versoThermPlus5kWOutdoorGallery3mSmallWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-3m-hover.jpg";
import versoThermPlus5kWOutdoorGallery3mLargeWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-3m-hover.jpg";
import versoThermPlus5kWOutdoorGallery5mSmallWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-5m-hover.jpg";
import versoThermPlus5kWOutdoorGallery5mLargeWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-5m-hover.jpg";

import versoThermPlus5kWOutdoorIntroSmallWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-small.jpg";
import versoThermPlus5kWOutdoorIntroMediumWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-medium.jpg";
import versoThermPlus5kWOutdoorIntroLargeWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-large.jpg";

import versoThermPlus5kWOutdoorStage1mSmallWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-1m.jpg";
import versoThermPlus5kWOutdoorStage1mMediumWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-1m.jpg";
import versoThermPlus5kWOutdoorStage1mLargeWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-1m.jpg";
import versoThermPlus5kWOutdoorStage3mSmallWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-3m.jpg";
import versoThermPlus5kWOutdoorStage3mMediumWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-3m.jpg";
import versoThermPlus5kWOutdoorStage3mLargeWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-3m.jpg";
import versoThermPlus5kWOutdoorStage5mSmallWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-5m.jpg";
import versoThermPlus5kWOutdoorStage5mMediumWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-5m.jpg";
import versoThermPlus5kWOutdoorStage5mLargeWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-5m.jpg";

import versoThermPlus5kWOutdoorTeaserSmallWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-small-hover.jpg";
import versoThermPlus5kWOutdoorTeaserMediumWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-medium-hover.jpg";
import versoThermPlus5kWOutdoorTeaserLargeWinter from "../static/vaillant/versoTHERM plus/5 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-large-hover.jpg";

//7kW
//Outdoor
//Summer
import versoThermPlus7kWOutdoorGallery1mSmallSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-1m-hover.jpg";
import versoThermPlus7kWOutdoorGallery1mLargeSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-1m-hover.jpg";
import versoThermPlus7kWOutdoorGallery3mSmallSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-3m-hover.jpg";
import versoThermPlus7kWOutdoorGallery3mLargeSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-3m-hover.jpg";
import versoThermPlus7kWOutdoorGallery5mSmallSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-small-5m-hover.jpg";
import versoThermPlus7kWOutdoorGallery5mLargeSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-summer-large-5m-hover.jpg";

import versoThermPlus7kWOutdoorIntroSmallSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-small.jpg";
import versoThermPlus7kWOutdoorIntroMediumSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-medium.jpg";
import versoThermPlus7kWOutdoorIntroLargeSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-summer-large.jpg";

import versoThermPlus7kWOutdoorStage1mSmallSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-1m.jpg";
import versoThermPlus7kWOutdoorStage1mMediumSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-1m.jpg";
import versoThermPlus7kWOutdoorStage1mLargeSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-1m.jpg";
import versoThermPlus7kWOutdoorStage3mSmallSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-3m.jpg";
import versoThermPlus7kWOutdoorStage3mMediumSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-3m.jpg";
import versoThermPlus7kWOutdoorStage3mLargeSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-3m.jpg";
import versoThermPlus7kWOutdoorStage5mSmallSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-small-5m.jpg";
import versoThermPlus7kWOutdoorStage5mMediumSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-medium-5m.jpg";
import versoThermPlus7kWOutdoorStage5mLargeSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-summer-large-5m.jpg";

import versoThermPlus7kWOutdoorTeaserSmallSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-small-hover.jpg";
import versoThermPlus7kWOutdoorTeaserMediumSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-medium-hover.jpg";
import versoThermPlus7kWOutdoorTeaserLargeSummer from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-summer-large-hover.jpg";
//Winter
import versoThermPlus7kWOutdoorGallery1mSmallWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-1m-hover.jpg";
import versoThermPlus7kWOutdoorGallery1mLargeWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-1m-hover.jpg";
import versoThermPlus7kWOutdoorGallery3mSmallWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-3m-hover.jpg";
import versoThermPlus7kWOutdoorGallery3mLargeWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-3m-hover.jpg";
import versoThermPlus7kWOutdoorGallery5mSmallWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-small-5m-hover.jpg";
import versoThermPlus7kWOutdoorGallery5mLargeWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-versoTHERMplus-ODU-winter-large-5m-hover.jpg";

import versoThermPlus7kWOutdoorIntroSmallWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-small.jpg";
import versoThermPlus7kWOutdoorIntroMediumWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-medium.jpg";
import versoThermPlus7kWOutdoorIntroLargeWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-versoTHERMplus-ODU-winter-large.jpg";

import versoThermPlus7kWOutdoorStage1mSmallWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-1m.jpg";
import versoThermPlus7kWOutdoorStage1mMediumWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-1m.jpg";
import versoThermPlus7kWOutdoorStage1mLargeWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-1m.jpg";
import versoThermPlus7kWOutdoorStage3mSmallWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-3m.jpg";
import versoThermPlus7kWOutdoorStage3mMediumWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-3m.jpg";
import versoThermPlus7kWOutdoorStage3mLargeWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-3m.jpg";
import versoThermPlus7kWOutdoorStage5mSmallWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-small-5m.jpg";
import versoThermPlus7kWOutdoorStage5mMediumWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-medium-5m.jpg";
import versoThermPlus7kWOutdoorStage5mLargeWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-versoTHERMplus-ODU-winter-large-5m.jpg";

import versoThermPlus7kWOutdoorTeaserSmallWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-small-hover.jpg";
import versoThermPlus7kWOutdoorTeaserMediumWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-medium-hover.jpg";
import versoThermPlus7kWOutdoorTeaserLargeWinter from "../static/vaillant/versoTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-versoTHERMplus-ODU-winter-large-hover.jpg";

//recoCOMPACT exclusive
//3kW
//Outdoor
//Summer
import recoCompactExclusive3kWOutdoorGallery1mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-1m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery1mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-1m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery3mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-3m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery3mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-3m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery5mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-5m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery5mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-5m-hover.jpg";

import recoCompactExclusive3kWOutdoorIntroSmallSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-small.jpg";
import recoCompactExclusive3kWOutdoorIntroMediumSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-medium.jpg";
import recoCompactExclusive3kWOutdoorIntroLargeSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-large.jpg";

import recoCompactExclusive3kWOutdoorStage1mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-1m.jpg";
import recoCompactExclusive3kWOutdoorStage1mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-1m.jpg";
import recoCompactExclusive3kWOutdoorStage1mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-1m.jpg";
import recoCompactExclusive3kWOutdoorStage3mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-3m.jpg";
import recoCompactExclusive3kWOutdoorStage3mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-3m.jpg";
import recoCompactExclusive3kWOutdoorStage3mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-3m.jpg";
import recoCompactExclusive3kWOutdoorStage5mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-5m.jpg";
import recoCompactExclusive3kWOutdoorStage5mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-5m.jpg";
import recoCompactExclusive3kWOutdoorStage5mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-5m.jpg";

import recoCompactExclusive3kWOutdoorTeaserSmallSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-small-hover.jpg";
import recoCompactExclusive3kWOutdoorTeaserMediumSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-medium-hover.jpg";
import recoCompactExclusive3kWOutdoorTeaserLargeSummer from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-large-hover.jpg";
//Winter
import recoCompactExclusive3kWOutdoorGallery1mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-1m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery1mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-1m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery3mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-3m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery3mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-3m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery5mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-5m-hover.jpg";
import recoCompactExclusive3kWOutdoorGallery5mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-5m-hover.jpg";

import recoCompactExclusive3kWOutdoorIntroSmallWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-small.jpg";
import recoCompactExclusive3kWOutdoorIntroMediumWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-medium.jpg";
import recoCompactExclusive3kWOutdoorIntroLargeWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-large.jpg";

import recoCompactExclusive3kWOutdoorStage1mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-1m.jpg";
import recoCompactExclusive3kWOutdoorStage1mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-1m.jpg";
import recoCompactExclusive3kWOutdoorStage1mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-1m.jpg";
import recoCompactExclusive3kWOutdoorStage3mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-3m.jpg";
import recoCompactExclusive3kWOutdoorStage3mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-3m.jpg";
import recoCompactExclusive3kWOutdoorStage3mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-3m.jpg";
import recoCompactExclusive3kWOutdoorStage5mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-5m.jpg";
import recoCompactExclusive3kWOutdoorStage5mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-5m.jpg";
import recoCompactExclusive3kWOutdoorStage5mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-5m.jpg";

import recoCompactExclusive3kWOutdoorTeaserSmallWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-small-hover.jpg";
import recoCompactExclusive3kWOutdoorTeaserMediumWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-medium-hover.jpg";
import recoCompactExclusive3kWOutdoorTeaserLargeWinter from "../static/vaillant/recoCOMPACT exclusive/3 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-large-hover.jpg";

//Outdoor
//Summer
import recoCompactExclusive5kWOutdoorGallery1mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-1m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery1mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-1m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery3mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-3m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery3mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-3m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery5mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-5m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery5mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-5m-hover.jpg";

import recoCompactExclusive5kWOutdoorIntroSmallSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-small.jpg";
import recoCompactExclusive5kWOutdoorIntroMediumSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-medium.jpg";
import recoCompactExclusive5kWOutdoorIntroLargeSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-large.jpg";

import recoCompactExclusive5kWOutdoorStage1mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-1m.jpg";
import recoCompactExclusive5kWOutdoorStage1mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-1m.jpg";
import recoCompactExclusive5kWOutdoorStage1mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-1m.jpg";
import recoCompactExclusive5kWOutdoorStage3mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-3m.jpg";
import recoCompactExclusive5kWOutdoorStage3mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-3m.jpg";
import recoCompactExclusive5kWOutdoorStage3mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-3m.jpg";
import recoCompactExclusive5kWOutdoorStage5mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-5m.jpg";
import recoCompactExclusive5kWOutdoorStage5mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-5m.jpg";
import recoCompactExclusive5kWOutdoorStage5mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-5m.jpg";

import recoCompactExclusive5kWOutdoorTeaserSmallSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-small-hover.jpg";
import recoCompactExclusive5kWOutdoorTeaserMediumSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-medium-hover.jpg";
import recoCompactExclusive5kWOutdoorTeaserLargeSummer from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-large-hover.jpg";
//Winter
import recoCompactExclusive5kWOutdoorGallery1mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-1m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery1mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-1m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery3mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-3m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery3mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-3m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery5mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-5m-hover.jpg";
import recoCompactExclusive5kWOutdoorGallery5mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-5m-hover.jpg";

import recoCompactExclusive5kWOutdoorIntroSmallWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-small.jpg";
import recoCompactExclusive5kWOutdoorIntroMediumWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-medium.jpg";
import recoCompactExclusive5kWOutdoorIntroLargeWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-large.jpg";

import recoCompactExclusive5kWOutdoorStage1mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-1m.jpg";
import recoCompactExclusive5kWOutdoorStage1mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-1m.jpg";
import recoCompactExclusive5kWOutdoorStage1mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-1m.jpg";
import recoCompactExclusive5kWOutdoorStage3mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-3m.jpg";
import recoCompactExclusive5kWOutdoorStage3mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-3m.jpg";
import recoCompactExclusive5kWOutdoorStage3mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-3m.jpg";
import recoCompactExclusive5kWOutdoorStage5mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-5m.jpg";
import recoCompactExclusive5kWOutdoorStage5mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-5m.jpg";
import recoCompactExclusive5kWOutdoorStage5mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-5m.jpg";

import recoCompactExclusive5kWOutdoorTeaserSmallWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-small-hover.jpg";
import recoCompactExclusive5kWOutdoorTeaserMediumWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-medium-hover.jpg";
import recoCompactExclusive5kWOutdoorTeaserLargeWinter from "../static/vaillant/recoCOMPACT exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-large-hover.jpg";

//7kW
//Outdoor
//Summer
import recoCompactExclusive7kWOutdoorGallery1mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-1m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery1mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-1m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery3mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-3m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery3mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-3m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery5mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-small-5m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery5mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-summer-large-5m-hover.jpg";

import recoCompactExclusive7kWOutdoorIntroSmallSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-small.jpg";
import recoCompactExclusive7kWOutdoorIntroMediumSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-medium.jpg";
import recoCompactExclusive7kWOutdoorIntroLargeSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-summer-large.jpg";

import recoCompactExclusive7kWOutdoorStage1mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-1m.jpg";
import recoCompactExclusive7kWOutdoorStage1mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-1m.jpg";
import recoCompactExclusive7kWOutdoorStage1mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-1m.jpg";
import recoCompactExclusive7kWOutdoorStage3mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-3m.jpg";
import recoCompactExclusive7kWOutdoorStage3mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-3m.jpg";
import recoCompactExclusive7kWOutdoorStage3mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-3m.jpg";
import recoCompactExclusive7kWOutdoorStage5mSmallSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-small-5m.jpg";
import recoCompactExclusive7kWOutdoorStage5mMediumSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-medium-5m.jpg";
import recoCompactExclusive7kWOutdoorStage5mLargeSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-summer-large-5m.jpg";

import recoCompactExclusive7kWOutdoorTeaserSmallSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-small-hover.jpg";
import recoCompactExclusive7kWOutdoorTeaserMediumSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-medium-hover.jpg";
import recoCompactExclusive7kWOutdoorTeaserLargeSummer from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-summer-large-hover.jpg";
//Winter
import recoCompactExclusive7kWOutdoorGallery1mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-1m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery1mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-1m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery3mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-3m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery3mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-3m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery5mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-small-5m-hover.jpg";
import recoCompactExclusive7kWOutdoorGallery5mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Gallery/gallery-recoCOMPACTexclusive-ODU-winter-large-5m-hover.jpg";

import recoCompactExclusive7kWOutdoorIntroSmallWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-small.jpg";
import recoCompactExclusive7kWOutdoorIntroMediumWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-medium.jpg";
import recoCompactExclusive7kWOutdoorIntroLargeWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Intro/intro-recoCOMPACTexclusive-ODU-winter-large.jpg";

import recoCompactExclusive7kWOutdoorStage1mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-1m.jpg";
import recoCompactExclusive7kWOutdoorStage1mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-1m.jpg";
import recoCompactExclusive7kWOutdoorStage1mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-1m.jpg";
import recoCompactExclusive7kWOutdoorStage3mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-3m.jpg";
import recoCompactExclusive7kWOutdoorStage3mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-3m.jpg";
import recoCompactExclusive7kWOutdoorStage3mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-3m.jpg";
import recoCompactExclusive7kWOutdoorStage5mSmallWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-small-5m.jpg";
import recoCompactExclusive7kWOutdoorStage5mMediumWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-medium-5m.jpg";
import recoCompactExclusive7kWOutdoorStage5mLargeWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Stage/stage-recoCOMPACTexclusive-ODU-winter-large-5m.jpg";

import recoCompactExclusive7kWOutdoorTeaserSmallWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-small-hover.jpg";
import recoCompactExclusive7kWOutdoorTeaserMediumWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-medium-hover.jpg";
import recoCompactExclusive7kWOutdoorTeaserLargeWinter from "../static/vaillant/recoCOMPACT exclusive/7 kW/Outdoor Unit/Image/Teaser/teaser-recoCOMPACTexclusive-ODU-winter-large-hover.jpg";

//flexoTHERM exclusive
//5kW

//Outdoor
//Summer
import flexoThermExclusive5kWOutdoorGallery1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-1m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-1m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-3m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-3m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-5m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-5m-hover.jpg";

import flexoThermExclusive5kWOutdoorIntroSmallSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-small.jpg";
import flexoThermExclusive5kWOutdoorIntroMediumSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-medium.jpg";
import flexoThermExclusive5kWOutdoorIntroLargeSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-large.jpg";

import flexoThermExclusive5kWOutdoorStage1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-1m.jpg";
import flexoThermExclusive5kWOutdoorStage1mMediumSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-1m.jpg";
import flexoThermExclusive5kWOutdoorStage1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-1m.jpg";
import flexoThermExclusive5kWOutdoorStage3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-3m.jpg";
import flexoThermExclusive5kWOutdoorStage3mMediumSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-3m.jpg";
import flexoThermExclusive5kWOutdoorStage3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-3m.jpg";
import flexoThermExclusive5kWOutdoorStage5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-5m.jpg";
import flexoThermExclusive5kWOutdoorStage5mMediumSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-5m.jpg";
import flexoThermExclusive5kWOutdoorStage5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-5m.jpg";

import flexoThermExclusive5kWOutdoorTeaserSmallSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-small-hover.jpg";
import flexoThermExclusive5kWOutdoorTeaserMediumSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-medium-hover.jpg";
import flexoThermExclusive5kWOutdoorTeaserLargeSummer from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-large-hover.jpg";
//Winter
import flexoThermExclusive5kWOutdoorGallery1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-1m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-1m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-3m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-3m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-5m-hover.jpg";
import flexoThermExclusive5kWOutdoorGallery5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-5m-hover.jpg";

import flexoThermExclusive5kWOutdoorIntroSmallWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-small.jpg";
import flexoThermExclusive5kWOutdoorIntroMediumWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-medium.jpg";
import flexoThermExclusive5kWOutdoorIntroLargeWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-large.jpg";

import flexoThermExclusive5kWOutdoorStage1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-1m.jpg";
import flexoThermExclusive5kWOutdoorStage1mMediumWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-1m.jpg";
import flexoThermExclusive5kWOutdoorStage1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-1m.jpg";
import flexoThermExclusive5kWOutdoorStage3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-3m.jpg";
import flexoThermExclusive5kWOutdoorStage3mMediumWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-3m.jpg";
import flexoThermExclusive5kWOutdoorStage3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-3m.jpg";
import flexoThermExclusive5kWOutdoorStage5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-5m.jpg";
import flexoThermExclusive5kWOutdoorStage5mMediumWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-5m.jpg";
import flexoThermExclusive5kWOutdoorStage5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-5m.jpg";

import flexoThermExclusive5kWOutdoorTeaserSmallWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-small-hover.jpg";
import flexoThermExclusive5kWOutdoorTeaserMediumWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-medium-hover.jpg";
import flexoThermExclusive5kWOutdoorTeaserLargeWinter from "../static/vaillant/flexoTHERM exclusive/5 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-large-hover.jpg";

//8kW
//Outdoor
//Summer
import flexoThermExclusive8kWOutdoorGallery1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-1m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-1m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-3m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-3m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-5m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-5m-hover.jpg";

import flexoThermExclusive8kWOutdoorIntroSmallSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-small.jpg";
import flexoThermExclusive8kWOutdoorIntroMediumSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-medium.jpg";
import flexoThermExclusive8kWOutdoorIntroLargeSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-large.jpg";

import flexoThermExclusive8kWOutdoorStage1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-1m.jpg";
import flexoThermExclusive8kWOutdoorStage1mMediumSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-1m.jpg";
import flexoThermExclusive8kWOutdoorStage1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-1m.jpg";
import flexoThermExclusive8kWOutdoorStage3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-3m.jpg";
import flexoThermExclusive8kWOutdoorStage3mMediumSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-3m.jpg";
import flexoThermExclusive8kWOutdoorStage3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-3m.jpg";
import flexoThermExclusive8kWOutdoorStage5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-5m.jpg";
import flexoThermExclusive8kWOutdoorStage5mMediumSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-5m.jpg";
import flexoThermExclusive8kWOutdoorStage5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-5m.jpg";

import flexoThermExclusive8kWOutdoorTeaserSmallSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-small-hover.jpg";
import flexoThermExclusive8kWOutdoorTeaserMediumSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-medium-hover.jpg";
import flexoThermExclusive8kWOutdoorTeaserLargeSummer from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-large-hover.jpg";
//Winter
import flexoThermExclusive8kWOutdoorGallery1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-1m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-1m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-3m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-3m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-5m-hover.jpg";
import flexoThermExclusive8kWOutdoorGallery5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-5m-hover.jpg";

import flexoThermExclusive8kWOutdoorIntroSmallWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-small.jpg";
import flexoThermExclusive8kWOutdoorIntroMediumWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-medium.jpg";
import flexoThermExclusive8kWOutdoorIntroLargeWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-large.jpg";

import flexoThermExclusive8kWOutdoorStage1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-1m.jpg";
import flexoThermExclusive8kWOutdoorStage1mMediumWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-1m.jpg";
import flexoThermExclusive8kWOutdoorStage1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-1m.jpg";
import flexoThermExclusive8kWOutdoorStage3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-3m.jpg";
import flexoThermExclusive8kWOutdoorStage3mMediumWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-3m.jpg";
import flexoThermExclusive8kWOutdoorStage3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-3m.jpg";
import flexoThermExclusive8kWOutdoorStage5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-5m.jpg";
import flexoThermExclusive8kWOutdoorStage5mMediumWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-5m.jpg";
import flexoThermExclusive8kWOutdoorStage5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-5m.jpg";

import flexoThermExclusive8kWOutdoorTeaserSmallWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-small-hover.jpg";
import flexoThermExclusive8kWOutdoorTeaserMediumWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-medium-hover.jpg";
import flexoThermExclusive8kWOutdoorTeaserLargeWinter from "../static/vaillant/flexoTHERM exclusive/8 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-large-hover.jpg";

//11kW
//Outdoor
//Summer
import flexoThermExclusive11kWOutdoorGallery1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-1m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-1m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-3m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-3m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-5m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-5m-hover.jpg";

import flexoThermExclusive11kWOutdoorIntroSmallSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-small.jpg";
import flexoThermExclusive11kWOutdoorIntroMediumSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-medium.jpg";
import flexoThermExclusive11kWOutdoorIntroLargeSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-large.jpg";

import flexoThermExclusive11kWOutdoorStage1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-1m.jpg";
import flexoThermExclusive11kWOutdoorStage1mMediumSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-1m.jpg";
import flexoThermExclusive11kWOutdoorStage1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-1m.jpg";
import flexoThermExclusive11kWOutdoorStage3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-3m.jpg";
import flexoThermExclusive11kWOutdoorStage3mMediumSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-3m.jpg";
import flexoThermExclusive11kWOutdoorStage3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-3m.jpg";
import flexoThermExclusive11kWOutdoorStage5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-5m.jpg";
import flexoThermExclusive11kWOutdoorStage5mMediumSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-5m.jpg";
import flexoThermExclusive11kWOutdoorStage5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-5m.jpg";

import flexoThermExclusive11kWOutdoorTeaserSmallSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-small-hover.jpg";
import flexoThermExclusive11kWOutdoorTeaserMediumSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-medium-hover.jpg";
import flexoThermExclusive11kWOutdoorTeaserLargeSummer from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-large-hover.jpg";
//Winter
import flexoThermExclusive11kWOutdoorGallery1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-1m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-1m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-3m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-3m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-5m-hover.jpg";
import flexoThermExclusive11kWOutdoorGallery5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-5m-hover.jpg";

import flexoThermExclusive11kWOutdoorIntroSmallWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-small.jpg";
import flexoThermExclusive11kWOutdoorIntroMediumWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-medium.jpg";
import flexoThermExclusive11kWOutdoorIntroLargeWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-large.jpg";

import flexoThermExclusive11kWOutdoorStage1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-1m.jpg";
import flexoThermExclusive11kWOutdoorStage1mMediumWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-1m.jpg";
import flexoThermExclusive11kWOutdoorStage1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-1m.jpg";
import flexoThermExclusive11kWOutdoorStage3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-3m.jpg";
import flexoThermExclusive11kWOutdoorStage3mMediumWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-3m.jpg";
import flexoThermExclusive11kWOutdoorStage3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-3m.jpg";
import flexoThermExclusive11kWOutdoorStage5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-5m.jpg";
import flexoThermExclusive11kWOutdoorStage5mMediumWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-5m.jpg";
import flexoThermExclusive11kWOutdoorStage5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-5m.jpg";

import flexoThermExclusive11kWOutdoorTeaserSmallWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-small-hover.jpg";
import flexoThermExclusive11kWOutdoorTeaserMediumWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-medium-hover.jpg";
import flexoThermExclusive11kWOutdoorTeaserLargeWinter from "../static/vaillant/flexoTHERM exclusive/11 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-large-hover.jpg";

//15kW
//Outdoor
//Summer
import flexoThermExclusive15kWOutdoorGallery1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-1m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-1m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-3m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-3m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-5m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-5m-hover.jpg";

import flexoThermExclusive15kWOutdoorIntroSmallSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-small.jpg";
import flexoThermExclusive15kWOutdoorIntroMediumSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-medium.jpg";
import flexoThermExclusive15kWOutdoorIntroLargeSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-large.jpg";

import flexoThermExclusive15kWOutdoorStage1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-1m.jpg";
import flexoThermExclusive15kWOutdoorStage1mMediumSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-1m.jpg";
import flexoThermExclusive15kWOutdoorStage1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-1m.jpg";
import flexoThermExclusive15kWOutdoorStage3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-3m.jpg";
import flexoThermExclusive15kWOutdoorStage3mMediumSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-3m.jpg";
import flexoThermExclusive15kWOutdoorStage3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-3m.jpg";
import flexoThermExclusive15kWOutdoorStage5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-5m.jpg";
import flexoThermExclusive15kWOutdoorStage5mMediumSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-5m.jpg";
import flexoThermExclusive15kWOutdoorStage5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-5m.jpg";

import flexoThermExclusive15kWOutdoorTeaserSmallSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-small-hover.jpg";
import flexoThermExclusive15kWOutdoorTeaserMediumSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-medium-hover.jpg";
import flexoThermExclusive15kWOutdoorTeaserLargeSummer from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-large-hover.jpg";
//Winter
import flexoThermExclusive15kWOutdoorGallery1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-1m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-1m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-3m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-3m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-5m-hover.jpg";
import flexoThermExclusive15kWOutdoorGallery5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-5m-hover.jpg";

import flexoThermExclusive15kWOutdoorIntroSmallWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-small.jpg";
import flexoThermExclusive15kWOutdoorIntroMediumWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-medium.jpg";
import flexoThermExclusive15kWOutdoorIntroLargeWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-large.jpg";

import flexoThermExclusive15kWOutdoorStage1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-1m.jpg";
import flexoThermExclusive15kWOutdoorStage1mMediumWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-1m.jpg";
import flexoThermExclusive15kWOutdoorStage1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-1m.jpg";
import flexoThermExclusive15kWOutdoorStage3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-3m.jpg";
import flexoThermExclusive15kWOutdoorStage3mMediumWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-3m.jpg";
import flexoThermExclusive15kWOutdoorStage3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-3m.jpg";
import flexoThermExclusive15kWOutdoorStage5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-5m.jpg";
import flexoThermExclusive15kWOutdoorStage5mMediumWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-5m.jpg";
import flexoThermExclusive15kWOutdoorStage5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-5m.jpg";

import flexoThermExclusive15kWOutdoorTeaserSmallWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-small-hover.jpg";
import flexoThermExclusive15kWOutdoorTeaserMediumWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-medium-hover.jpg";
import flexoThermExclusive15kWOutdoorTeaserLargeWinter from "../static/vaillant/flexoTHERM exclusive/15 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-large-hover.jpg";

//19kW
//Outdoor
//Summer
import flexoThermExclusive19kWOutdoorGallery1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-1m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-1m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-3m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-3m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-small-5m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-summer-large-5m-hover.jpg";

import flexoThermExclusive19kWOutdoorIntroSmallSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-small.jpg";
import flexoThermExclusive19kWOutdoorIntroMediumSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-medium.jpg";
import flexoThermExclusive19kWOutdoorIntroLargeSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-summer-large.jpg";

import flexoThermExclusive19kWOutdoorStage1mSmallSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-1m.jpg";
import flexoThermExclusive19kWOutdoorStage1mMediumSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-1m.jpg";
import flexoThermExclusive19kWOutdoorStage1mLargeSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-1m.jpg";
import flexoThermExclusive19kWOutdoorStage3mSmallSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-3m.jpg";
import flexoThermExclusive19kWOutdoorStage3mMediumSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-3m.jpg";
import flexoThermExclusive19kWOutdoorStage3mLargeSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-3m.jpg";
import flexoThermExclusive19kWOutdoorStage5mSmallSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-small-5m.jpg";
import flexoThermExclusive19kWOutdoorStage5mMediumSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-medium-5m.jpg";
import flexoThermExclusive19kWOutdoorStage5mLargeSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-summer-large-5m.jpg";

import flexoThermExclusive19kWOutdoorTeaserSmallSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-small-hover.jpg";
import flexoThermExclusive19kWOutdoorTeaserMediumSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-medium-hover.jpg";
import flexoThermExclusive19kWOutdoorTeaserLargeSummer from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-summer-large-hover.jpg";
//Winter
import flexoThermExclusive19kWOutdoorGallery1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-1m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-1m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-3m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-3m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-small-5m-hover.jpg";
import flexoThermExclusive19kWOutdoorGallery5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Gallery/gallery-flexoTHERMexclusive-ODU-winter-large-5m-hover.jpg";

import flexoThermExclusive19kWOutdoorIntroSmallWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-small.jpg";
import flexoThermExclusive19kWOutdoorIntroMediumWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-medium.jpg";
import flexoThermExclusive19kWOutdoorIntroLargeWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Intro/intro-flexoTHERMexclusive-ODU-winter-large.jpg";

import flexoThermExclusive19kWOutdoorStage1mSmallWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-1m.jpg";
import flexoThermExclusive19kWOutdoorStage1mMediumWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-1m.jpg";
import flexoThermExclusive19kWOutdoorStage1mLargeWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-1m.jpg";
import flexoThermExclusive19kWOutdoorStage3mSmallWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-3m.jpg";
import flexoThermExclusive19kWOutdoorStage3mMediumWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-3m.jpg";
import flexoThermExclusive19kWOutdoorStage3mLargeWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-3m.jpg";
import flexoThermExclusive19kWOutdoorStage5mSmallWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-small-5m.jpg";
import flexoThermExclusive19kWOutdoorStage5mMediumWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-medium-5m.jpg";
import flexoThermExclusive19kWOutdoorStage5mLargeWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Stage/stage-flexoTHERMexclusive-ODU-winter-large-5m.jpg";

import flexoThermExclusive19kWOutdoorTeaserSmallWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-small-hover.jpg";
import flexoThermExclusive19kWOutdoorTeaserMediumWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-medium-hover.jpg";
import flexoThermExclusive19kWOutdoorTeaserLargeWinter from "../static/vaillant/flexoTHERM exclusive/19 kW/Outdoor Unit/Image/Teaser/teaser-flexoTHERMexclusive-ODU-winter-large-hover.jpg";

//aroTHERM Split
//3_5kW
//Outdoor
//Summer
import aroThermSplit3_5kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-summer-small-1m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-summer-large-1m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-summer-small-3m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-summer-large-3m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-summer-small-5m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-summer-large-5m-hover.jpg";

import aroThermSplit3_5kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-3_5kW-summer-small.jpg";
import aroThermSplit3_5kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-3_5kW-summer-medium.jpg";
import aroThermSplit3_5kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-3_5kW-summer-large.jpg";

import aroThermSplit3_5kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-small-1m.jpg";
import aroThermSplit3_5kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-medium-1m.jpg";
import aroThermSplit3_5kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-large-1m.jpg";
import aroThermSplit3_5kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-small-3m.jpg";
import aroThermSplit3_5kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-medium-3m.jpg";
import aroThermSplit3_5kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-large-3m.jpg";
import aroThermSplit3_5kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-small-5m.jpg";
import aroThermSplit3_5kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-medium-5m.jpg";
import aroThermSplit3_5kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-summer-large-5m.jpg";

import aroThermSplit3_5kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-3_5kW-summer-small-hover.jpg";
import aroThermSplit3_5kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-3_5kW-summer-medium-hover.jpg";
import aroThermSplit3_5kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-3_5kW-summer-large-hover.jpg";

//Winter
import aroThermSplit3_5kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-winter-small-1m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-winter-large-1m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-winter-small-3m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-winter-large-3m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-winter-small-5m-hover.jpg";
import aroThermSplit3_5kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-3_5kW-winter-large-5m-hover.jpg";

import aroThermSplit3_5kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-3_5kW-winter-small.jpg";
import aroThermSplit3_5kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-3_5kW-winter-medium.jpg";
import aroThermSplit3_5kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-3_5kW-winter-large.jpg";

import aroThermSplit3_5kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-small-1m.jpg";
import aroThermSplit3_5kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-medium-1m.jpg";
import aroThermSplit3_5kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-large-1m.jpg";
import aroThermSplit3_5kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-small-3m.jpg";
import aroThermSplit3_5kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-medium-3m.jpg";
import aroThermSplit3_5kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-large-3m.jpg";
import aroThermSplit3_5kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-small-5m.jpg";
import aroThermSplit3_5kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-medium-5m.jpg";
import aroThermSplit3_5kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-3_5kW-winter-large-5m.jpg";

import aroThermSplit3_5kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-3_5kW-winter-small-hover.jpg";
import aroThermSplit3_5kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-3_5kW-winter-medium-hover.jpg";
import aroThermSplit3_5kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM Split/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-3_5kW-winter-large-hover.jpg";

//7kW
//Outdoor
//Summer
import aroThermSplit7kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-summer-small-1m-hover.jpg";
import aroThermSplit7kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-summer-large-1m-hover.jpg";
import aroThermSplit7kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-summer-small-3m-hover.jpg";
import aroThermSplit7kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-summer-large-3m-hover.jpg";
import aroThermSplit7kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-summer-small-5m-hover.jpg";
import aroThermSplit7kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-summer-large-5m-hover.jpg";

import aroThermSplit7kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-7kW-summer-small.jpg";
import aroThermSplit7kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-7kW-summer-medium.jpg";
import aroThermSplit7kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-7kW-summer-large.jpg";

import aroThermSplit7kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-small-1m.jpg";
import aroThermSplit7kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-medium-1m.jpg";
import aroThermSplit7kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-large-1m.jpg";
import aroThermSplit7kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-small-3m.jpg";
import aroThermSplit7kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-medium-3m.jpg";
import aroThermSplit7kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-large-3m.jpg";
import aroThermSplit7kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-small-5m.jpg";
import aroThermSplit7kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-medium-5m.jpg";
import aroThermSplit7kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-summer-large-5m.jpg";

import aroThermSplit7kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-7kW-summer-small-hover.jpg";
import aroThermSplit7kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-7kW-summer-medium-hover.jpg";
import aroThermSplit7kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-7kW-summer-large-hover.jpg";

//Winter
import aroThermSplit7kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-winter-small-1m-hover.jpg";
import aroThermSplit7kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-winter-large-1m-hover.jpg";
import aroThermSplit7kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-winter-small-3m-hover.jpg";
import aroThermSplit7kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-winter-large-3m-hover.jpg";
import aroThermSplit7kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-winter-small-5m-hover.jpg";
import aroThermSplit7kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-7kW-winter-large-5m-hover.jpg";

import aroThermSplit7kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-7kW-winter-small.jpg";
import aroThermSplit7kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-7kW-winter-medium.jpg";
import aroThermSplit7kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-7kW-winter-large.jpg";

import aroThermSplit7kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-small-1m.jpg";
import aroThermSplit7kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-medium-1m.jpg";
import aroThermSplit7kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-large-1m.jpg";
import aroThermSplit7kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-small-3m.jpg";
import aroThermSplit7kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-medium-3m.jpg";
import aroThermSplit7kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-large-3m.jpg";
import aroThermSplit7kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-small-5m.jpg";
import aroThermSplit7kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-medium-5m.jpg";
import aroThermSplit7kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-7kW-winter-large-5m.jpg";

import aroThermSplit7kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-7kW-winter-small-hover.jpg";
import aroThermSplit7kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-7kW-winter-medium-hover.jpg";
import aroThermSplit7kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM Split/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-7kW-winter-large-hover.jpg";

//10_12kW
//Outdoor
//Summer
import aroThermSplit10_12kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-summer-small-1m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-summer-large-1m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-summer-small-3m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-summer-large-3m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-summer-small-5m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-summer-large-5m-hover.jpg";

import aroThermSplit10_12kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-10_12kW-summer-small.jpg";
import aroThermSplit10_12kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-10_12kW-summer-medium.jpg";
import aroThermSplit10_12kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-10_12kW-summer-large.jpg";

import aroThermSplit10_12kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-small-1m.jpg";
import aroThermSplit10_12kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-medium-1m.jpg";
import aroThermSplit10_12kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-large-1m.jpg";
import aroThermSplit10_12kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-small-3m.jpg";
import aroThermSplit10_12kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-medium-3m.jpg";
import aroThermSplit10_12kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-large-3m.jpg";
import aroThermSplit10_12kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-small-5m.jpg";
import aroThermSplit10_12kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-medium-5m.jpg";
import aroThermSplit10_12kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-summer-large-5m.jpg";

import aroThermSplit10_12kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-10_12kW-summer-small-hover.jpg";
import aroThermSplit10_12kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-10_12kW-summer-medium-hover.jpg";
import aroThermSplit10_12kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-10_12kW-summer-large-hover.jpg";

//Winter
import aroThermSplit10_12kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-winter-small-1m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-winter-large-1m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-winter-small-3m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-winter-large-3m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-winter-small-5m-hover.jpg";
import aroThermSplit10_12kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMSplit-ODU-10_12kW-winter-large-5m-hover.jpg";

import aroThermSplit10_12kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-10_12kW-winter-small.jpg";
import aroThermSplit10_12kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-10_12kW-winter-medium.jpg";
import aroThermSplit10_12kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMSplit-ODU-10_12kW-winter-large.jpg";

import aroThermSplit10_12kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-small-1m.jpg";
import aroThermSplit10_12kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-medium-1m.jpg";
import aroThermSplit10_12kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-large-1m.jpg";
import aroThermSplit10_12kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-small-3m.jpg";
import aroThermSplit10_12kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-medium-3m.jpg";
import aroThermSplit10_12kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-large-3m.jpg";
import aroThermSplit10_12kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-small-5m.jpg";
import aroThermSplit10_12kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-medium-5m.jpg";
import aroThermSplit10_12kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMSplit-ODU-10_12kW-winter-large-5m.jpg";

import aroThermSplit10_12kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-10_12kW-winter-small-hover.jpg";
import aroThermSplit10_12kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-10_12kW-winter-medium-hover.jpg";
import aroThermSplit10_12kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM Split/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMSplit-ODU-10_12kW-winter-large-hover.jpg";

//aroTHERM plus
//3_5 kW
//Outdoor
//Summer
import aroThermPlus3_5kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-summer-small-1m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-summer-large-1m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-summer-small-3m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-summer-large-3m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-summer-small-5m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-summer-large-5m-hover.jpg";

import aroThermPlus3_5kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-3_5kW-summer-small.jpg";
import aroThermPlus3_5kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-3_5kW-summer-medium.jpg";
import aroThermPlus3_5kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-3_5kW-summer-large.jpg";

import aroThermPlus3_5kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-small-1m.jpg";
import aroThermPlus3_5kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-medium-1m.jpg";
import aroThermPlus3_5kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-large-1m.jpg";
import aroThermPlus3_5kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-small-3m.jpg";
import aroThermPlus3_5kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-medium-3m.jpg";
import aroThermPlus3_5kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-large-3m.jpg";
import aroThermPlus3_5kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-small-5m.jpg";
import aroThermPlus3_5kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-medium-5m.jpg";
import aroThermPlus3_5kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-summer-large-5m.jpg";

import aroThermPlus3_5kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-3_5kW-summer-small-hover.jpg";
import aroThermPlus3_5kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-3_5kW-summer-medium-hover.jpg";
import aroThermPlus3_5kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-3_5kW-summer-large-hover.jpg";
//Winter
import aroThermPlus3_5kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-winter-small-1m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-winter-large-1m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-winter-small-3m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-winter-large-3m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-winter-small-5m-hover.jpg";
import aroThermPlus3_5kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-3_5kW-winter-large-5m-hover.jpg";

import aroThermPlus3_5kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-3_5kW-winter-small.jpg";
import aroThermPlus3_5kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-3_5kW-winter-medium.jpg";
import aroThermPlus3_5kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-3_5kW-winter-large.jpg";

import aroThermPlus3_5kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-small-1m.jpg";
import aroThermPlus3_5kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-medium-1m.jpg";
import aroThermPlus3_5kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-large-1m.jpg";
import aroThermPlus3_5kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-small-3m.jpg";
import aroThermPlus3_5kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-medium-3m.jpg";
import aroThermPlus3_5kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-large-3m.jpg";
import aroThermPlus3_5kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-small-5m.jpg";
import aroThermPlus3_5kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-medium-5m.jpg";
import aroThermPlus3_5kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-3_5kW-winter-large-5m.jpg";

import aroThermPlus3_5kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-3_5kW-winter-small-hover.jpg";
import aroThermPlus3_5kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-3_5kW-winter-medium-hover.jpg";
import aroThermPlus3_5kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM plus/3_5 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-3_5kW-winter-large-hover.jpg";

//7 kW
//Outdoor
//Summer
import aroThermPlus7kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-summer-small-1m-hover.jpg";
import aroThermPlus7kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-summer-large-1m-hover.jpg";
import aroThermPlus7kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-summer-small-3m-hover.jpg";
import aroThermPlus7kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-summer-large-3m-hover.jpg";
import aroThermPlus7kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-summer-small-5m-hover.jpg";
import aroThermPlus7kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-summer-large-5m-hover.jpg";

import aroThermPlus7kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-7kW-summer-small.jpg";
import aroThermPlus7kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-7kW-summer-medium.jpg";
import aroThermPlus7kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-7kW-summer-large.jpg";

import aroThermPlus7kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-small-1m.jpg";
import aroThermPlus7kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-medium-1m.jpg";
import aroThermPlus7kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-large-1m.jpg";
import aroThermPlus7kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-small-3m.jpg";
import aroThermPlus7kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-medium-3m.jpg";
import aroThermPlus7kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-large-3m.jpg";
import aroThermPlus7kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-small-5m.jpg";
import aroThermPlus7kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-medium-5m.jpg";
import aroThermPlus7kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-summer-large-5m.jpg";

import aroThermPlus7kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-7kW-summer-small-hover.jpg";
import aroThermPlus7kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-7kW-summer-medium-hover.jpg";
import aroThermPlus7kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-7kW-summer-large-hover.jpg";
//Winter
import aroThermPlus7kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-winter-small-1m-hover.jpg";
import aroThermPlus7kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-winter-large-1m-hover.jpg";
import aroThermPlus7kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-winter-small-3m-hover.jpg";
import aroThermPlus7kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-winter-large-3m-hover.jpg";
import aroThermPlus7kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-winter-small-5m-hover.jpg";
import aroThermPlus7kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-7kW-winter-large-5m-hover.jpg";

import aroThermPlus7kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-7kW-winter-small.jpg";
import aroThermPlus7kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-7kW-winter-medium.jpg";
import aroThermPlus7kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-7kW-winter-large.jpg";

import aroThermPlus7kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-small-1m.jpg";
import aroThermPlus7kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-medium-1m.jpg";
import aroThermPlus7kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-large-1m.jpg";
import aroThermPlus7kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-small-3m.jpg";
import aroThermPlus7kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-medium-3m.jpg";
import aroThermPlus7kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-large-3m.jpg";
import aroThermPlus7kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-small-5m.jpg";
import aroThermPlus7kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-medium-5m.jpg";
import aroThermPlus7kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-7kW-winter-large-5m.jpg";

import aroThermPlus7kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-7kW-winter-small-hover.jpg";
import aroThermPlus7kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-7kW-winter-medium-hover.jpg";
import aroThermPlus7kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM plus/7 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-7kW-winter-large-hover.jpg";

//10_12 kW
//Outdoor
//Summer
import aroThermPlus10_12kWOutdoorGallery1mSmallSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-summer-small-1m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery1mLargeSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-summer-large-1m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery3mSmallSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-summer-small-3m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery3mLargeSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-summer-large-3m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery5mSmallSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-summer-small-5m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery5mLargeSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-summer-large-5m-hover.jpg";

import aroThermPlus10_12kWOutdoorIntroSmallSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-10_12kW-summer-small.jpg";
import aroThermPlus10_12kWOutdoorIntroMediumSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-10_12kW-summer-medium.jpg";
import aroThermPlus10_12kWOutdoorIntroLargeSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-10_12kW-summer-large.jpg";

import aroThermPlus10_12kWOutdoorStage1mSmallSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-small-1m.jpg";
import aroThermPlus10_12kWOutdoorStage1mMediumSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-medium-1m.jpg";
import aroThermPlus10_12kWOutdoorStage1mLargeSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-large-1m.jpg";
import aroThermPlus10_12kWOutdoorStage3mSmallSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-small-3m.jpg";
import aroThermPlus10_12kWOutdoorStage3mMediumSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-medium-3m.jpg";
import aroThermPlus10_12kWOutdoorStage3mLargeSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-large-3m.jpg";
import aroThermPlus10_12kWOutdoorStage5mSmallSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-small-5m.jpg";
import aroThermPlus10_12kWOutdoorStage5mMediumSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-medium-5m.jpg";
import aroThermPlus10_12kWOutdoorStage5mLargeSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-summer-large-5m.jpg";

import aroThermPlus10_12kWOutdoorTeaserSmallSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-10_12kW-small-hover.jpg";
import aroThermPlus10_12kWOutdoorTeaserMediumSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-10_12kW-medium-hover.jpg";
import aroThermPlus10_12kWOutdoorTeaserLargeSummer from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-10_12kW-large-hover.jpg";
//Winter
import aroThermPlus10_12kWOutdoorGallery1mSmallWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-winter-small-1m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery1mLargeWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-winter-large-1m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery3mSmallWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-winter-small-3m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery3mLargeWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-winter-large-3m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery5mSmallWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-winter-small-5m-hover.jpg";
import aroThermPlus10_12kWOutdoorGallery5mLargeWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Gallery/gallery-aroTHERMplus-ODU-10_12kW-winter-large-5m-hover.jpg";

import aroThermPlus10_12kWOutdoorIntroSmallWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-10_12kW-winter-small.jpg";
import aroThermPlus10_12kWOutdoorIntroMediumWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-10_12kW-winter-medium.jpg";
import aroThermPlus10_12kWOutdoorIntroLargeWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Intro/intro-aroTHERMplus-ODU-10_12kW-winter-large.jpg";

import aroThermPlus10_12kWOutdoorStage1mSmallWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-small-1m.jpg";
import aroThermPlus10_12kWOutdoorStage1mMediumWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-medium-1m.jpg";
import aroThermPlus10_12kWOutdoorStage1mLargeWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-large-1m.jpg";
import aroThermPlus10_12kWOutdoorStage3mSmallWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-small-3m.jpg";
import aroThermPlus10_12kWOutdoorStage3mMediumWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-medium-3m.jpg";
import aroThermPlus10_12kWOutdoorStage3mLargeWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-large-3m.jpg";
import aroThermPlus10_12kWOutdoorStage5mSmallWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-small-5m.jpg";
import aroThermPlus10_12kWOutdoorStage5mMediumWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-medium-5m.jpg";
import aroThermPlus10_12kWOutdoorStage5mLargeWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Stage/stage-aroTHERMplus-ODU-10_12kW-winter-large-5m.jpg";

import aroThermPlus10_12kWOutdoorTeaserSmallWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-10_12kW-winter-small-hover.jpg";
import aroThermPlus10_12kWOutdoorTeaserMediumWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-10_12kW-winter-medium-hover.jpg";
import aroThermPlus10_12kWOutdoorTeaserLargeWinter from "../static/vaillant/aroTHERM plus/10_12 kW/Outdoor Unit/Image/Teaser/teaser-aroTHERMplus-ODU-10_12kW-winter-large-hover.jpg";

import { Translations } from "../types/languages";

//sound
const ATP_ODU_L_A7W55 = require("../static/vaillant/sound/ATP_ODU_L_A7W55.mp3");
const ATP_ODU_L_max = require("../static/vaillant/sound/ATP_ODU_L_max.mp3");
const ATP_ODU_L_silent_mode = require("../static/vaillant/sound/ATP_ODU_L_silent mode.mp3");
const ATP_ODU_M_A7W55 = require("../static/vaillant/sound/ATP_ODU_M_A7W55.mp3");
const ATP_ODU_M_max = require("../static/vaillant/sound/ATP_ODU_M_max.mp3");
const ATP_ODU_M_silent_mode = require("../static/vaillant/sound/ATP_ODU_M_silent mode.mp3");
const ATP_ODU_S_A7W55 = require("../static/vaillant/sound/ATP_ODU_S_A7W55.mp3");
const ATP_ODU_S_max = require("../static/vaillant/sound/ATP_ODU_S_max.mp3");
const ATP_ODU_S_silent_mode = require("../static/vaillant/sound/ATP_ODU_S_silent mode.mp3");
const ATPE_ODU_A7W55 = require("../static/vaillant/sound/ATPE_ODU_A7W55.mp3");
const ATPE_ODU_max_sound_power_level = require("../static/vaillant/sound/ATPE_ODU_max_sound_power_level.mp3");
const ATPE_ODU_silent_mode_A7W35 = require("../static/vaillant/sound/ATPE_ODU_silent mode_A7W35.mp3");
const ATS_ODU_10_12_A7W55 = require("../static/vaillant/sound/ATS_ODU_10_12_A7W55.mp3");
const ATS_ODU_10_12_silent_mode = require("../static/vaillant/sound/ATS_ODU_10_12_silent mode.mp3");
const ATS_ODU_M_A7W55 = require("../static/vaillant/sound/ATS_ODU_M_A7W55.mp3");
const ATS_ODU_S_A7W55 = require("../static/vaillant/sound/ATS_ODU_S_A7W55.mp3");
const ATS_ODU_S_silent = require("../static/vaillant/sound/ATS_ODU_S_silent.mp3");
const FCE_ODU_A7W55 = require("../static/vaillant/sound/FCE_ODU_A7W55.mp3");
const FTE_ODU_A7W55 = require("../static/vaillant/sound/FTE_ODU_A7W55.mp3");
const RCE_ODU_3_5kW_A7W55 = require("../static/vaillant/sound/RCE_ODU_3_5kW_A7W55.mp3");
const RCE_ODU_7kW_A7W55 = require("../static/vaillant/sound/RCE_ODU_7kW_A7W55.mp3");
const VTP_ODU_3_5_A7W55 = require("../static/vaillant/sound/VTP_ODU_3_5_A7W55.mp3");
const VTP_ODU_7_A7W55 = require("../static/vaillant/sound/VTP_ODU_7_A7W55.mp3");

export const heatPumpDataVaillant: Array<HeatPumpData> = [
  {
    id: 1,
    category: "air",
    variants: [
      {
        power: "3/5",
        shortcut: "ATP3",
        teaser: [
          aroThermPlus3_5kWOutdoorTeaserSmallSummer,
          aroThermPlus3_5kWOutdoorTeaserMediumSummer,
          aroThermPlus3_5kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermPlus3_5kWOutdoorTeaserSmallWinter,
          aroThermPlus3_5kWOutdoorTeaserMediumWinter,
          aroThermPlus3_5kWOutdoorTeaserLargeWinter,
        ],
        intro: [
          aroThermPlus3_5kWOutdoorIntroSmallSummer,
          aroThermPlus3_5kWOutdoorIntroMediumSummer,
          aroThermPlus3_5kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermPlus3_5kWOutdoorIntroSmallWinter,
          aroThermPlus3_5kWOutdoorIntroMediumWinter,
          aroThermPlus3_5kWOutdoorIntroLargeWinter,
        ],
        scenesSummer: [
          {
            img: [
              aroThermPlus3_5kWOutdoorStage5mSmallSummer,
              aroThermPlus3_5kWOutdoorStage5mMediumSummer,
              aroThermPlus3_5kWOutdoorStage5mLargeSummer,
            ],
            gallery: [aroThermPlus3_5kWOutdoorGallery5mSmallSummer, aroThermPlus3_5kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 32,
              url: ATP_ODU_S_A7W55,
            },
            silentMode: {
              volume: 24.4,
              url: ATP_ODU_S_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus3_5kWOutdoorStage3mSmallSummer,
              aroThermPlus3_5kWOutdoorStage3mMediumSummer,
              aroThermPlus3_5kWOutdoorStage3mLargeSummer,
            ],
            gallery: [aroThermPlus3_5kWOutdoorGallery3mSmallSummer, aroThermPlus3_5kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 36.5,
              url: ATP_ODU_S_A7W55,
            },
            silentMode: {
              volume: 28.9,
              url: ATP_ODU_S_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus3_5kWOutdoorStage1mSmallSummer,
              aroThermPlus3_5kWOutdoorStage1mMediumSummer,
              aroThermPlus3_5kWOutdoorStage1mLargeSummer,
            ],
            gallery: [aroThermPlus3_5kWOutdoorGallery1mSmallSummer, aroThermPlus3_5kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 46,
              url: ATP_ODU_S_A7W55,
            },
            silentMode: {
              volume: 38.4,
              url: ATP_ODU_S_silent_mode,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermPlus3_5kWOutdoorStage5mSmallWinter,
              aroThermPlus3_5kWOutdoorStage5mMediumWinter,
              aroThermPlus3_5kWOutdoorStage5mLargeWinter,
            ],
            gallery: [aroThermPlus3_5kWOutdoorGallery5mSmallWinter, aroThermPlus3_5kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 33.4,
              url: ATP_ODU_S_max,
            },
            silentMode: {
              volume: 26.3,
              url: ATP_ODU_S_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus3_5kWOutdoorStage3mSmallWinter,
              aroThermPlus3_5kWOutdoorStage3mMediumWinter,
              aroThermPlus3_5kWOutdoorStage3mLargeWinter,
            ],
            gallery: [aroThermPlus3_5kWOutdoorGallery3mSmallWinter, aroThermPlus3_5kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 37.9,
              url: ATP_ODU_S_max,
            },
            silentMode: {
              volume: 30.8,
              url: ATP_ODU_S_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus3_5kWOutdoorStage1mSmallWinter,
              aroThermPlus3_5kWOutdoorStage1mMediumWinter,
              aroThermPlus3_5kWOutdoorStage1mLargeWinter,
            ],
            gallery: [aroThermPlus3_5kWOutdoorGallery1mSmallWinter, aroThermPlus3_5kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 47.4,
              url: ATP_ODU_S_max,
            },
            silentMode: {
              volume: 40.3,
              url: ATP_ODU_S_silent_mode,
            },
          },
        ],
      },
      {
        power: 7,
        shortcut: "ATP7",
        teaser: [
          aroThermPlus7kWOutdoorTeaserSmallSummer,
          aroThermPlus7kWOutdoorTeaserMediumSummer,
          aroThermPlus7kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermPlus7kWOutdoorTeaserSmallWinter,
          aroThermPlus7kWOutdoorTeaserMediumWinter,
          aroThermPlus7kWOutdoorTeaserLargeWinter,
        ],
        intro: [
          aroThermPlus7kWOutdoorIntroSmallSummer,
          aroThermPlus7kWOutdoorIntroMediumSummer,
          aroThermPlus7kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermPlus7kWOutdoorIntroSmallWinter,
          aroThermPlus7kWOutdoorIntroMediumWinter,
          aroThermPlus7kWOutdoorIntroLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              aroThermPlus7kWOutdoorStage5mSmallSummer,
              aroThermPlus7kWOutdoorStage5mMediumSummer,
              aroThermPlus7kWOutdoorStage5mLargeSummer,
            ],
            gallery: [aroThermPlus7kWOutdoorGallery5mSmallSummer, aroThermPlus7kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 33,
              url: ATP_ODU_M_A7W55,
            },
            silentMode: {
              volume: 24.2,
              url: ATP_ODU_M_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus7kWOutdoorStage3mSmallSummer,
              aroThermPlus7kWOutdoorStage3mMediumSummer,
              aroThermPlus7kWOutdoorStage3mLargeSummer,
            ],
            gallery: [aroThermPlus7kWOutdoorGallery3mSmallSummer, aroThermPlus7kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 37.5,
              url: ATP_ODU_M_A7W55,
            },
            silentMode: {
              volume: 28.7,
              url: ATP_ODU_M_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus7kWOutdoorStage1mSmallSummer,
              aroThermPlus7kWOutdoorStage1mMediumSummer,
              aroThermPlus7kWOutdoorStage1mLargeSummer,
            ],
            gallery: [aroThermPlus7kWOutdoorGallery1mSmallSummer, aroThermPlus7kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 47,
              url: ATP_ODU_M_A7W55,
            },
            silentMode: {
              volume: 38.2,
              url: ATP_ODU_M_silent_mode,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermPlus7kWOutdoorStage5mSmallWinter,
              aroThermPlus7kWOutdoorStage5mMediumWinter,
              aroThermPlus7kWOutdoorStage5mLargeWinter,
            ],
            gallery: [aroThermPlus7kWOutdoorGallery5mSmallWinter, aroThermPlus7kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 34.4,
              url: ATP_ODU_M_max,
            },
            silentMode: {
              volume: 27.2,
              url: ATP_ODU_M_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus7kWOutdoorStage3mSmallWinter,
              aroThermPlus7kWOutdoorStage3mMediumWinter,
              aroThermPlus7kWOutdoorStage3mLargeWinter,
            ],
            gallery: [aroThermPlus7kWOutdoorGallery3mSmallWinter, aroThermPlus7kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 38.9,
              url: ATP_ODU_M_max,
            },
            silentMode: {
              volume: 32.2,
              url: ATP_ODU_M_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus7kWOutdoorStage1mSmallWinter,
              aroThermPlus7kWOutdoorStage1mMediumWinter,
              aroThermPlus7kWOutdoorStage1mLargeWinter,
            ],
            gallery: [aroThermPlus7kWOutdoorGallery1mSmallWinter, aroThermPlus7kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 48.4,
              url: ATP_ODU_M_max,
            },
            silentMode: {
              volume: 41.7,
              url: ATP_ODU_M_silent_mode,
            },
          },
        ],
      },
      {
        power: "10/12",
        shortcut: "ATP10",

        teaser: [
          aroThermPlus10_12kWOutdoorTeaserSmallSummer,
          aroThermPlus10_12kWOutdoorTeaserMediumSummer,
          aroThermPlus10_12kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermPlus10_12kWOutdoorTeaserSmallWinter,
          aroThermPlus10_12kWOutdoorTeaserMediumWinter,
          aroThermPlus10_12kWOutdoorTeaserLargeWinter,
        ],
        intro: [
          aroThermPlus10_12kWOutdoorIntroSmallSummer,
          aroThermPlus10_12kWOutdoorIntroMediumSummer,
          aroThermPlus10_12kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermPlus10_12kWOutdoorIntroSmallWinter,
          aroThermPlus10_12kWOutdoorIntroMediumWinter,
          aroThermPlus10_12kWOutdoorIntroLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              aroThermPlus10_12kWOutdoorStage5mSmallSummer,
              aroThermPlus10_12kWOutdoorStage5mMediumSummer,
              aroThermPlus10_12kWOutdoorStage5mLargeSummer,
            ],
            gallery: [aroThermPlus10_12kWOutdoorGallery5mSmallSummer, aroThermPlus10_12kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 37,
              url: ATP_ODU_L_A7W55,
            },
            silentMode: {
              volume: 24.2,
              url: ATP_ODU_L_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus10_12kWOutdoorStage3mSmallSummer,
              aroThermPlus10_12kWOutdoorStage3mMediumSummer,
              aroThermPlus10_12kWOutdoorStage3mLargeSummer,
            ],
            gallery: [aroThermPlus10_12kWOutdoorGallery3mSmallSummer, aroThermPlus10_12kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 41.5,
              url: ATP_ODU_L_A7W55,
            },
            silentMode: {
              volume: 28.7,
              url: ATP_ODU_L_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus10_12kWOutdoorStage1mSmallSummer,
              aroThermPlus10_12kWOutdoorStage1mMediumSummer,
              aroThermPlus10_12kWOutdoorStage1mLargeSummer,
            ],
            gallery: [aroThermPlus10_12kWOutdoorGallery1mSmallSummer, aroThermPlus10_12kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 51,
              url: ATP_ODU_L_A7W55,
            },
            silentMode: {
              volume: 38.2,
              url: ATP_ODU_L_silent_mode,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermPlus10_12kWOutdoorStage5mSmallWinter,
              aroThermPlus10_12kWOutdoorStage5mMediumWinter,
              aroThermPlus10_12kWOutdoorStage5mLargeWinter,
            ],
            gallery: [aroThermPlus10_12kWOutdoorGallery5mSmallWinter, aroThermPlus10_12kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 35.4,
              url: ATP_ODU_L_max,
            },
            silentMode: {
              volume: 32.8,
              url: ATP_ODU_L_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus10_12kWOutdoorStage3mSmallWinter,
              aroThermPlus10_12kWOutdoorStage3mMediumWinter,
              aroThermPlus10_12kWOutdoorStage3mLargeWinter,
            ],
            gallery: [aroThermPlus10_12kWOutdoorGallery3mSmallWinter, aroThermPlus10_12kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 39.9,
              url: ATP_ODU_L_max,
            },
            silentMode: {
              volume: 37.3,
              url: ATP_ODU_L_silent_mode,
            },
          },
          {
            img: [
              aroThermPlus10_12kWOutdoorStage1mSmallWinter,
              aroThermPlus10_12kWOutdoorStage1mMediumWinter,
              aroThermPlus10_12kWOutdoorStage1mLargeWinter,
            ],
            gallery: [aroThermPlus10_12kWOutdoorGallery1mSmallWinter, aroThermPlus10_12kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 49.4,
              url: ATP_ODU_L_max,
            },
            silentMode: {
              volume: 46.8,
              url: ATP_ODU_L_silent_mode,
            },
          },
        ],
      },
    ],
  },
  {
    id: 2,
    category: "air",
    variants: [
      {
        power: "3/5",
        shortcut: "ATS3",

        teaser: [
          aroThermSplit3_5kWOutdoorTeaserSmallSummer,
          aroThermSplit3_5kWOutdoorTeaserMediumSummer,
          aroThermSplit3_5kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermSplit3_5kWOutdoorTeaserSmallWinter,
          aroThermSplit3_5kWOutdoorTeaserMediumWinter,
          aroThermSplit3_5kWOutdoorTeaserLargeWinter,
        ],
        intro: [
          aroThermSplit3_5kWOutdoorIntroSmallSummer,
          aroThermSplit3_5kWOutdoorIntroMediumSummer,
          aroThermSplit3_5kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermSplit3_5kWOutdoorIntroSmallWinter,
          aroThermSplit3_5kWOutdoorIntroMediumWinter,
          aroThermSplit3_5kWOutdoorIntroLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              aroThermSplit3_5kWOutdoorStage5mSmallSummer,
              aroThermSplit3_5kWOutdoorStage5mMediumSummer,
              aroThermSplit3_5kWOutdoorStage5mLargeSummer,
            ],
            gallery: [aroThermSplit3_5kWOutdoorGallery5mSmallSummer, aroThermSplit3_5kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 31,
              url: ATS_ODU_S_A7W55,
            },
            silentMode: {
              volume: 24,
              url: ATS_ODU_S_silent,
            },
          },
          {
            img: [
              aroThermSplit3_5kWOutdoorStage3mSmallSummer,
              aroThermSplit3_5kWOutdoorStage3mMediumSummer,
              aroThermSplit3_5kWOutdoorStage3mLargeSummer,
            ],
            gallery: [aroThermSplit3_5kWOutdoorGallery3mSmallSummer, aroThermSplit3_5kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 35.5,
              url: ATS_ODU_S_A7W55,
            },
            silentMode: {
              volume: 28.5,
              url: ATS_ODU_S_silent,
            },
          },
          {
            img: [
              aroThermSplit3_5kWOutdoorStage1mSmallSummer,
              aroThermSplit3_5kWOutdoorStage1mMediumSummer,
              aroThermSplit3_5kWOutdoorStage1mLargeSummer,
            ],
            gallery: [aroThermSplit3_5kWOutdoorGallery1mSmallSummer, aroThermSplit3_5kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 45,
              url: ATS_ODU_S_A7W55,
            },
            silentMode: {
              volume: 38,
              url: ATS_ODU_S_silent,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermSplit3_5kWOutdoorStage5mSmallWinter,
              aroThermSplit3_5kWOutdoorStage5mMediumWinter,
              aroThermSplit3_5kWOutdoorStage5mLargeWinter,
            ],
            gallery: [aroThermSplit3_5kWOutdoorGallery5mSmallWinter, aroThermSplit3_5kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 33,
              url: ATS_ODU_S_A7W55,
            },
            silentMode: {
              volume: 30,
              url: ATS_ODU_S_silent,
            },
          },
          {
            img: [
              aroThermSplit3_5kWOutdoorStage3mSmallWinter,
              aroThermSplit3_5kWOutdoorStage3mMediumWinter,
              aroThermSplit3_5kWOutdoorStage3mLargeWinter,
            ],
            gallery: [aroThermSplit3_5kWOutdoorGallery3mSmallWinter, aroThermSplit3_5kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 37.5,
              url: ATS_ODU_S_A7W55,
            },
            silentMode: {
              volume: 34.5,
              url: ATS_ODU_S_silent,
            },
          },
          {
            img: [
              aroThermSplit3_5kWOutdoorStage1mSmallWinter,
              aroThermSplit3_5kWOutdoorStage1mMediumWinter,
              aroThermSplit3_5kWOutdoorStage1mLargeWinter,
            ],
            gallery: [aroThermSplit3_5kWOutdoorGallery1mSmallWinter, aroThermSplit3_5kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 47,
              url: ATS_ODU_S_A7W55,
            },
            silentMode: {
              volume: 44,
              url: ATS_ODU_S_silent,
            },
          },
        ],
      },
      {
        power: 7,
        shortcut: "ATS7",

        teaser: [
          aroThermSplit7kWOutdoorTeaserSmallSummer,
          aroThermSplit7kWOutdoorTeaserMediumSummer,
          aroThermSplit7kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermSplit7kWOutdoorTeaserSmallWinter,
          aroThermSplit7kWOutdoorTeaserMediumWinter,
          aroThermSplit7kWOutdoorTeaserLargeWinter,
        ],
        intro: [
          aroThermSplit7kWOutdoorIntroSmallSummer,
          aroThermSplit7kWOutdoorIntroMediumSummer,
          aroThermSplit7kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermSplit7kWOutdoorIntroSmallWinter,
          aroThermSplit7kWOutdoorIntroMediumWinter,
          aroThermSplit7kWOutdoorIntroLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              aroThermSplit7kWOutdoorStage5mSmallSummer,
              aroThermSplit7kWOutdoorStage5mMediumSummer,
              aroThermSplit7kWOutdoorStage5mLargeSummer,
            ],
            gallery: [aroThermSplit7kWOutdoorGallery5mSmallSummer, aroThermSplit7kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 32,
              url: ATS_ODU_M_A7W55,
            },

            silentMode: {
              volume: 26,
              url: ATS_ODU_S_silent,
            },
          },
          {
            img: [
              aroThermSplit7kWOutdoorStage3mSmallSummer,
              aroThermSplit7kWOutdoorStage3mMediumSummer,
              aroThermSplit7kWOutdoorStage3mLargeSummer,
            ],
            gallery: [aroThermSplit7kWOutdoorGallery3mSmallSummer, aroThermSplit7kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 36.5,
              url: ATS_ODU_M_A7W55,
            },

            silentMode: {
              volume: 30.5,
              url: ATS_ODU_S_silent,
            },
          },
          {
            img: [
              aroThermSplit7kWOutdoorStage1mSmallSummer,
              aroThermSplit7kWOutdoorStage1mMediumSummer,
              aroThermSplit7kWOutdoorStage1mLargeSummer,
            ],
            gallery: [aroThermSplit7kWOutdoorGallery1mSmallSummer, aroThermSplit7kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 46,
              url: ATS_ODU_M_A7W55,
            },

            silentMode: {
              volume: 40,
              url: ATS_ODU_S_silent,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermSplit7kWOutdoorStage5mSmallWinter,
              aroThermSplit7kWOutdoorStage5mMediumWinter,
              aroThermSplit7kWOutdoorStage5mLargeWinter,
            ],
            gallery: [aroThermSplit7kWOutdoorGallery5mSmallWinter, aroThermSplit7kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 34,
              url: ATS_ODU_M_A7W55,
            },

            silentMode: {
              volume: 30,
              url: ATS_ODU_S_silent,
            },
          },
          {
            img: [
              aroThermSplit7kWOutdoorStage3mSmallWinter,
              aroThermSplit7kWOutdoorStage3mMediumWinter,
              aroThermSplit7kWOutdoorStage3mLargeWinter,
            ],
            gallery: [aroThermSplit7kWOutdoorGallery3mSmallWinter, aroThermSplit7kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 38.5,
              url: ATS_ODU_M_A7W55,
            },

            silentMode: {
              volume: 34.5,
              url: ATS_ODU_S_silent,
            },
          },
          {
            img: [
              aroThermSplit7kWOutdoorStage1mSmallWinter,
              aroThermSplit7kWOutdoorStage1mMediumWinter,
              aroThermSplit7kWOutdoorStage1mLargeWinter,
            ],
            gallery: [aroThermSplit7kWOutdoorGallery1mSmallWinter, aroThermSplit7kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 48,
              url: ATS_ODU_M_A7W55,
            },

            silentMode: {
              volume: 44,
              url: ATS_ODU_S_silent,
            },
          },
        ],
      },
      {
        power: "10/12",
        shortcut: "ATS10",

        teaser: [
          aroThermSplit10_12kWOutdoorTeaserSmallSummer,
          aroThermSplit10_12kWOutdoorTeaserMediumSummer,
          aroThermSplit10_12kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermSplit10_12kWOutdoorTeaserSmallWinter,
          aroThermSplit10_12kWOutdoorTeaserMediumWinter,
          aroThermSplit10_12kWOutdoorTeaserLargeWinter,
        ],
        intro: [
          aroThermSplit10_12kWOutdoorIntroSmallSummer,
          aroThermSplit10_12kWOutdoorIntroMediumSummer,
          aroThermSplit10_12kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermSplit10_12kWOutdoorIntroSmallWinter,
          aroThermSplit10_12kWOutdoorIntroMediumWinter,
          aroThermSplit10_12kWOutdoorIntroLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              aroThermSplit10_12kWOutdoorStage5mSmallSummer,
              aroThermSplit10_12kWOutdoorStage5mMediumSummer,
              aroThermSplit10_12kWOutdoorStage5mLargeSummer,
            ],
            gallery: [aroThermSplit10_12kWOutdoorGallery5mSmallSummer, aroThermSplit10_12kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 36.5,
              url: ATS_ODU_10_12_A7W55,
            },
            silentMode: {
              volume: 33,
              url: ATS_ODU_10_12_silent_mode,
            },
          },
          {
            img: [
              aroThermSplit10_12kWOutdoorStage3mSmallSummer,
              aroThermSplit10_12kWOutdoorStage3mMediumSummer,
              aroThermSplit10_12kWOutdoorStage3mLargeSummer,
            ],
            gallery: [aroThermSplit10_12kWOutdoorGallery3mSmallSummer, aroThermSplit10_12kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 41,
              url: ATS_ODU_10_12_A7W55,
            },
            silentMode: {
              volume: 37.5,
              url: ATS_ODU_10_12_silent_mode,
            },
          },
          {
            img: [
              aroThermSplit10_12kWOutdoorStage1mSmallSummer,
              aroThermSplit10_12kWOutdoorStage1mMediumSummer,
              aroThermSplit10_12kWOutdoorStage1mLargeSummer,
            ],
            gallery: [aroThermSplit10_12kWOutdoorGallery1mSmallSummer, aroThermSplit10_12kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 50.5,
              url: ATS_ODU_10_12_A7W55,
            },
            silentMode: {
              volume: 47,
              url: ATS_ODU_10_12_silent_mode,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermSplit10_12kWOutdoorStage5mSmallWinter,
              aroThermSplit10_12kWOutdoorStage5mMediumWinter,
              aroThermSplit10_12kWOutdoorStage5mLargeWinter,
            ],
            gallery: [aroThermSplit10_12kWOutdoorGallery5mSmallWinter, aroThermSplit10_12kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 39,
              url: ATS_ODU_10_12_A7W55,
            },
            silentMode: {
              volume: 37,
              url: ATS_ODU_10_12_silent_mode,
            },
          },
          {
            img: [
              aroThermSplit10_12kWOutdoorStage3mSmallWinter,
              aroThermSplit10_12kWOutdoorStage3mMediumWinter,
              aroThermSplit10_12kWOutdoorStage3mLargeWinter,
            ],
            gallery: [aroThermSplit10_12kWOutdoorGallery3mSmallWinter, aroThermSplit10_12kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 43.5,
              url: ATS_ODU_10_12_A7W55,
            },
            silentMode: {
              volume: 41.5,
              url: ATS_ODU_10_12_silent_mode,
            },
          },
          {
            img: [
              aroThermSplit10_12kWOutdoorStage1mSmallWinter,
              aroThermSplit10_12kWOutdoorStage1mMediumWinter,
              aroThermSplit10_12kWOutdoorStage1mLargeWinter,
            ],
            gallery: [aroThermSplit10_12kWOutdoorGallery1mSmallWinter, aroThermSplit10_12kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 53,
              url: ATS_ODU_10_12_A7W55,
            },
            silentMode: {
              volume: 51,
              url: ATS_ODU_10_12_silent_mode,
            },
          },
        ],
      },
    ],
  },
  {
    id: 3,
    category: "air",
    variants: [
      {
        power: 5,
        shortcut: "FE5",
        intro: [
          flexoThermExclusive5kWOutdoorIntroSmallSummer,
          flexoThermExclusive5kWOutdoorIntroMediumSummer,
          flexoThermExclusive5kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          flexoThermExclusive5kWOutdoorIntroSmallWinter,
          flexoThermExclusive5kWOutdoorIntroMediumWinter,
          flexoThermExclusive5kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          flexoThermExclusive5kWOutdoorTeaserSmallSummer,
          flexoThermExclusive5kWOutdoorTeaserMediumSummer,
          flexoThermExclusive5kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          flexoThermExclusive5kWOutdoorTeaserSmallWinter,
          flexoThermExclusive5kWOutdoorTeaserMediumWinter,
          flexoThermExclusive5kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              flexoThermExclusive5kWOutdoorStage5mSmallSummer,
              flexoThermExclusive5kWOutdoorStage5mMediumSummer,
              flexoThermExclusive5kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive5kWOutdoorGallery5mSmallSummer,
              flexoThermExclusive5kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 20.7,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 18,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive5kWOutdoorStage3mSmallSummer,
              flexoThermExclusive5kWOutdoorStage3mMediumSummer,
              flexoThermExclusive5kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive5kWOutdoorGallery3mSmallSummer,
              flexoThermExclusive5kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 25.2,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 22.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive5kWOutdoorStage1mSmallSummer,
              flexoThermExclusive5kWOutdoorStage1mMediumSummer,
              flexoThermExclusive5kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive5kWOutdoorGallery1mSmallSummer,
              flexoThermExclusive5kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 34.7,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 32,
              url: FTE_ODU_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              flexoThermExclusive5kWOutdoorStage5mSmallWinter,
              flexoThermExclusive5kWOutdoorStage5mMediumWinter,
              flexoThermExclusive5kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive5kWOutdoorGallery5mSmallWinter,
              flexoThermExclusive5kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 32,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 18,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive5kWOutdoorStage3mSmallWinter,
              flexoThermExclusive5kWOutdoorStage3mMediumWinter,
              flexoThermExclusive5kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive5kWOutdoorGallery3mSmallWinter,
              flexoThermExclusive5kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 36.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 22.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive5kWOutdoorStage1mSmallWinter,
              flexoThermExclusive5kWOutdoorStage1mMediumWinter,
              flexoThermExclusive5kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive5kWOutdoorGallery1mSmallWinter,
              flexoThermExclusive5kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 46,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 32,
              url: FTE_ODU_A7W55,
            },
          },
        ],
      },
      {
        power: 8,
        shortcut: "FE8",
        intro: [
          flexoThermExclusive8kWOutdoorIntroSmallSummer,
          flexoThermExclusive8kWOutdoorIntroMediumSummer,
          flexoThermExclusive8kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          flexoThermExclusive8kWOutdoorIntroSmallWinter,
          flexoThermExclusive8kWOutdoorIntroMediumWinter,
          flexoThermExclusive8kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          flexoThermExclusive8kWOutdoorTeaserSmallSummer,
          flexoThermExclusive8kWOutdoorTeaserMediumSummer,
          flexoThermExclusive8kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          flexoThermExclusive8kWOutdoorTeaserSmallWinter,
          flexoThermExclusive8kWOutdoorTeaserMediumWinter,
          flexoThermExclusive8kWOutdoorTeaserLargeWinter,
        ],
        scenesSummer: [
          {
            img: [
              flexoThermExclusive8kWOutdoorStage5mSmallSummer,
              flexoThermExclusive8kWOutdoorStage5mMediumSummer,
              flexoThermExclusive8kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive8kWOutdoorGallery5mSmallSummer,
              flexoThermExclusive8kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 28.6,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 24,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive8kWOutdoorStage3mSmallSummer,
              flexoThermExclusive8kWOutdoorStage3mMediumSummer,
              flexoThermExclusive8kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive8kWOutdoorGallery3mSmallSummer,
              flexoThermExclusive8kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 33.1,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 28.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive8kWOutdoorStage1mSmallSummer,
              flexoThermExclusive8kWOutdoorStage1mMediumSummer,
              flexoThermExclusive8kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive8kWOutdoorGallery1mSmallSummer,
              flexoThermExclusive8kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 42.6,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 38,
              url: FTE_ODU_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              flexoThermExclusive8kWOutdoorStage5mSmallWinter,
              flexoThermExclusive8kWOutdoorStage5mMediumWinter,
              flexoThermExclusive8kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive8kWOutdoorGallery5mSmallWinter,
              flexoThermExclusive8kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 39,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 24,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive8kWOutdoorStage3mSmallWinter,
              flexoThermExclusive8kWOutdoorStage3mMediumWinter,
              flexoThermExclusive8kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive8kWOutdoorGallery3mSmallWinter,
              flexoThermExclusive8kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 43.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 28.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive8kWOutdoorStage1mSmallWinter,
              flexoThermExclusive8kWOutdoorStage1mMediumWinter,
              flexoThermExclusive8kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive8kWOutdoorGallery1mSmallWinter,
              flexoThermExclusive8kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 53,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 38,
              url: FTE_ODU_A7W55,
            },
          },
        ],
      },
      {
        power: 11,
        shortcut: "FE11",

        intro: [
          flexoThermExclusive11kWOutdoorIntroSmallSummer,
          flexoThermExclusive11kWOutdoorIntroMediumSummer,
          flexoThermExclusive11kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          flexoThermExclusive11kWOutdoorIntroSmallWinter,
          flexoThermExclusive11kWOutdoorIntroMediumWinter,
          flexoThermExclusive11kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          flexoThermExclusive11kWOutdoorTeaserSmallSummer,
          flexoThermExclusive11kWOutdoorTeaserMediumSummer,
          flexoThermExclusive11kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          flexoThermExclusive11kWOutdoorTeaserSmallWinter,
          flexoThermExclusive11kWOutdoorTeaserMediumWinter,
          flexoThermExclusive11kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              flexoThermExclusive11kWOutdoorStage5mSmallSummer,
              flexoThermExclusive11kWOutdoorStage5mMediumSummer,
              flexoThermExclusive11kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive11kWOutdoorGallery5mSmallSummer,
              flexoThermExclusive11kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 34,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 31,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive11kWOutdoorStage3mSmallSummer,
              flexoThermExclusive11kWOutdoorStage3mMediumSummer,
              flexoThermExclusive11kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive11kWOutdoorGallery3mSmallSummer,
              flexoThermExclusive11kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 38.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 35.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive11kWOutdoorStage1mSmallSummer,
              flexoThermExclusive11kWOutdoorStage1mMediumSummer,
              flexoThermExclusive11kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive11kWOutdoorGallery1mSmallSummer,
              flexoThermExclusive11kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 48,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 45,
              url: FTE_ODU_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              flexoThermExclusive11kWOutdoorStage5mSmallWinter,
              flexoThermExclusive11kWOutdoorStage5mMediumWinter,
              flexoThermExclusive11kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive11kWOutdoorGallery5mSmallWinter,
              flexoThermExclusive11kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 45,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 31,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive11kWOutdoorStage3mSmallWinter,
              flexoThermExclusive11kWOutdoorStage3mMediumWinter,
              flexoThermExclusive11kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive11kWOutdoorGallery3mSmallWinter,
              flexoThermExclusive11kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 49.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 35.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive11kWOutdoorStage1mSmallWinter,
              flexoThermExclusive11kWOutdoorStage1mMediumWinter,
              flexoThermExclusive11kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive11kWOutdoorGallery1mSmallWinter,
              flexoThermExclusive11kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 59,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 45,
              url: FTE_ODU_A7W55,
            },
          },
        ],
      },
      {
        power: 15,
        shortcut: "FE15",
        intro: [
          flexoThermExclusive15kWOutdoorIntroSmallSummer,
          flexoThermExclusive15kWOutdoorIntroMediumSummer,
          flexoThermExclusive15kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          flexoThermExclusive15kWOutdoorIntroSmallWinter,
          flexoThermExclusive15kWOutdoorIntroMediumWinter,
          flexoThermExclusive15kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          flexoThermExclusive15kWOutdoorTeaserSmallSummer,
          flexoThermExclusive15kWOutdoorTeaserMediumSummer,
          flexoThermExclusive15kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          flexoThermExclusive15kWOutdoorTeaserSmallWinter,
          flexoThermExclusive15kWOutdoorTeaserMediumWinter,
          flexoThermExclusive15kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              flexoThermExclusive15kWOutdoorStage5mSmallSummer,
              flexoThermExclusive15kWOutdoorStage5mMediumSummer,
              flexoThermExclusive15kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive15kWOutdoorGallery5mSmallSummer,
              flexoThermExclusive15kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 27.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 26,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive15kWOutdoorStage3mSmallSummer,
              flexoThermExclusive15kWOutdoorStage3mMediumSummer,
              flexoThermExclusive15kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive15kWOutdoorGallery3mSmallSummer,
              flexoThermExclusive15kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 32,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 30.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive15kWOutdoorStage1mSmallSummer,
              flexoThermExclusive15kWOutdoorStage1mMediumSummer,
              flexoThermExclusive15kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive15kWOutdoorGallery1mSmallSummer,
              flexoThermExclusive15kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 41.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 40,
              url: FTE_ODU_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              flexoThermExclusive15kWOutdoorStage5mSmallWinter,
              flexoThermExclusive15kWOutdoorStage5mMediumWinter,
              flexoThermExclusive15kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive15kWOutdoorGallery5mSmallWinter,
              flexoThermExclusive15kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 40,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 26,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive15kWOutdoorStage3mSmallWinter,
              flexoThermExclusive15kWOutdoorStage3mMediumWinter,
              flexoThermExclusive15kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive15kWOutdoorGallery3mSmallWinter,
              flexoThermExclusive15kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 44.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 30.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive15kWOutdoorStage1mSmallWinter,
              flexoThermExclusive15kWOutdoorStage1mMediumWinter,
              flexoThermExclusive15kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive15kWOutdoorGallery1mSmallWinter,
              flexoThermExclusive15kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 54,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 40,
              url: FTE_ODU_A7W55,
            },
          },
        ],
      },
      {
        power: 19,
        shortcut: "FE19",
        intro: [
          flexoThermExclusive19kWOutdoorIntroSmallSummer,
          flexoThermExclusive19kWOutdoorIntroMediumSummer,
          flexoThermExclusive19kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          flexoThermExclusive19kWOutdoorIntroSmallWinter,
          flexoThermExclusive19kWOutdoorIntroMediumWinter,
          flexoThermExclusive19kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          flexoThermExclusive19kWOutdoorTeaserSmallSummer,
          flexoThermExclusive19kWOutdoorTeaserMediumSummer,
          flexoThermExclusive19kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          flexoThermExclusive19kWOutdoorTeaserSmallWinter,
          flexoThermExclusive19kWOutdoorTeaserMediumWinter,
          flexoThermExclusive19kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              flexoThermExclusive19kWOutdoorStage5mSmallSummer,
              flexoThermExclusive19kWOutdoorStage5mMediumSummer,
              flexoThermExclusive19kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive19kWOutdoorGallery5mSmallSummer,
              flexoThermExclusive19kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 31,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 31,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive19kWOutdoorStage3mSmallSummer,
              flexoThermExclusive19kWOutdoorStage3mMediumSummer,
              flexoThermExclusive19kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive19kWOutdoorGallery3mSmallSummer,
              flexoThermExclusive19kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 35.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 35.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive19kWOutdoorStage1mSmallSummer,
              flexoThermExclusive19kWOutdoorStage1mMediumSummer,
              flexoThermExclusive19kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              flexoThermExclusive19kWOutdoorGallery1mSmallSummer,
              flexoThermExclusive19kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 45,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 45,
              url: FTE_ODU_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              flexoThermExclusive19kWOutdoorStage5mSmallWinter,
              flexoThermExclusive19kWOutdoorStage5mMediumWinter,
              flexoThermExclusive19kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive19kWOutdoorGallery5mSmallWinter,
              flexoThermExclusive19kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 45,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 31,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive19kWOutdoorStage3mSmallWinter,
              flexoThermExclusive19kWOutdoorStage3mMediumWinter,
              flexoThermExclusive19kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive19kWOutdoorGallery3mSmallWinter,
              flexoThermExclusive19kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 49.5,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 35.5,
              url: FTE_ODU_A7W55,
            },
          },
          {
            img: [
              flexoThermExclusive19kWOutdoorStage1mSmallWinter,
              flexoThermExclusive19kWOutdoorStage1mMediumWinter,
              flexoThermExclusive19kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              flexoThermExclusive19kWOutdoorGallery1mSmallWinter,
              flexoThermExclusive19kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 59,
              url: FTE_ODU_A7W55,
            },
            silentMode: {
              volume: 45,
              url: FTE_ODU_A7W55,
            },
          },
        ],
      },
    ],
  },
  {
    id: 4,
    category: "air",
    variants: [
      {
        power: 3,
        shortcut: "RCE3",
        intro: [
          recoCompactExclusive3kWOutdoorIntroSmallSummer,
          recoCompactExclusive3kWOutdoorIntroMediumSummer,
          recoCompactExclusive3kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          recoCompactExclusive3kWOutdoorIntroSmallWinter,
          recoCompactExclusive3kWOutdoorIntroMediumWinter,
          recoCompactExclusive3kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          recoCompactExclusive3kWOutdoorTeaserSmallSummer,
          recoCompactExclusive3kWOutdoorTeaserMediumSummer,
          recoCompactExclusive3kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          recoCompactExclusive3kWOutdoorTeaserSmallWinter,
          recoCompactExclusive3kWOutdoorTeaserMediumWinter,
          recoCompactExclusive3kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              recoCompactExclusive3kWOutdoorStage5mSmallSummer,
              recoCompactExclusive3kWOutdoorStage5mMediumSummer,
              recoCompactExclusive3kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive3kWOutdoorGallery5mSmallSummer,
              recoCompactExclusive3kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 29.1,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 26,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive3kWOutdoorStage3mSmallSummer,
              recoCompactExclusive3kWOutdoorStage3mMediumSummer,
              recoCompactExclusive3kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive3kWOutdoorGallery3mSmallSummer,
              recoCompactExclusive3kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 33.6,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 30.5,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive3kWOutdoorStage1mSmallSummer,
              recoCompactExclusive3kWOutdoorStage1mMediumSummer,
              recoCompactExclusive3kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive3kWOutdoorGallery1mSmallSummer,
              recoCompactExclusive3kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 43.1,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 40,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              recoCompactExclusive3kWOutdoorStage5mSmallWinter,
              recoCompactExclusive3kWOutdoorStage5mMediumWinter,
              recoCompactExclusive3kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive3kWOutdoorGallery5mSmallWinter,
              recoCompactExclusive3kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 34,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 28,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive3kWOutdoorStage3mSmallWinter,
              recoCompactExclusive3kWOutdoorStage3mMediumWinter,
              recoCompactExclusive3kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive3kWOutdoorGallery3mSmallWinter,
              recoCompactExclusive3kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 38.5,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 32.5,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive3kWOutdoorStage1mSmallWinter,
              recoCompactExclusive3kWOutdoorStage1mMediumWinter,
              recoCompactExclusive3kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive3kWOutdoorGallery1mSmallWinter,
              recoCompactExclusive3kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 48,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 42,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
        ],
      },
      {
        power: 5,
        shortcut: "RCE5",
        intro: [
          recoCompactExclusive5kWOutdoorIntroSmallSummer,
          recoCompactExclusive5kWOutdoorIntroMediumSummer,
          recoCompactExclusive5kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          recoCompactExclusive5kWOutdoorIntroSmallWinter,
          recoCompactExclusive5kWOutdoorIntroMediumWinter,
          recoCompactExclusive5kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          recoCompactExclusive5kWOutdoorTeaserSmallSummer,
          recoCompactExclusive5kWOutdoorTeaserMediumSummer,
          recoCompactExclusive5kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          recoCompactExclusive5kWOutdoorTeaserSmallWinter,
          recoCompactExclusive5kWOutdoorTeaserMediumWinter,
          recoCompactExclusive5kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              recoCompactExclusive5kWOutdoorStage5mSmallSummer,
              recoCompactExclusive5kWOutdoorStage5mMediumSummer,
              recoCompactExclusive5kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive5kWOutdoorGallery5mSmallSummer,
              recoCompactExclusive5kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 29.1,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 28,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive5kWOutdoorStage3mSmallSummer,
              recoCompactExclusive5kWOutdoorStage3mMediumSummer,
              recoCompactExclusive5kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive5kWOutdoorGallery3mSmallSummer,
              recoCompactExclusive5kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 33.6,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 32.5,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive5kWOutdoorStage1mSmallSummer,
              recoCompactExclusive5kWOutdoorStage1mMediumSummer,
              recoCompactExclusive5kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive5kWOutdoorGallery1mSmallSummer,
              recoCompactExclusive5kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 43.1,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 42,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              recoCompactExclusive5kWOutdoorStage5mSmallWinter,
              recoCompactExclusive5kWOutdoorStage5mMediumWinter,
              recoCompactExclusive5kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive5kWOutdoorGallery5mSmallWinter,
              recoCompactExclusive5kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 34,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 28,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive5kWOutdoorStage3mSmallWinter,
              recoCompactExclusive5kWOutdoorStage3mMediumWinter,
              recoCompactExclusive5kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive5kWOutdoorGallery3mSmallWinter,
              recoCompactExclusive5kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 38.5,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 32.5,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive5kWOutdoorStage1mSmallWinter,
              recoCompactExclusive5kWOutdoorStage1mMediumWinter,
              recoCompactExclusive5kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive5kWOutdoorGallery1mSmallWinter,
              recoCompactExclusive5kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 48,
              url: RCE_ODU_3_5kW_A7W55,
            },
            silentMode: {
              volume: 42,
              url: RCE_ODU_3_5kW_A7W55,
            },
          },
        ],
      },
      {
        power: 7,
        shortcut: "RCE7",
        intro: [
          recoCompactExclusive7kWOutdoorIntroSmallSummer,
          recoCompactExclusive7kWOutdoorIntroMediumSummer,
          recoCompactExclusive7kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          recoCompactExclusive7kWOutdoorIntroSmallWinter,
          recoCompactExclusive7kWOutdoorIntroMediumWinter,
          recoCompactExclusive7kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          recoCompactExclusive7kWOutdoorTeaserSmallSummer,
          recoCompactExclusive7kWOutdoorTeaserMediumSummer,
          recoCompactExclusive7kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          recoCompactExclusive7kWOutdoorTeaserSmallWinter,
          recoCompactExclusive7kWOutdoorTeaserMediumWinter,
          recoCompactExclusive7kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              recoCompactExclusive7kWOutdoorStage5mSmallSummer,
              recoCompactExclusive7kWOutdoorStage5mMediumSummer,
              recoCompactExclusive7kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive7kWOutdoorGallery5mSmallSummer,
              recoCompactExclusive7kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 25.7,
              url: RCE_ODU_7kW_A7W55,
            },
            silentMode: {
              volume: 25,
              url: RCE_ODU_7kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive7kWOutdoorStage3mSmallSummer,
              recoCompactExclusive7kWOutdoorStage3mMediumSummer,
              recoCompactExclusive7kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive7kWOutdoorGallery3mSmallSummer,
              recoCompactExclusive7kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 30.2,
              url: RCE_ODU_7kW_A7W55,
            },
            silentMode: {
              volume: 29.5,
              url: RCE_ODU_7kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive7kWOutdoorStage1mSmallSummer,
              recoCompactExclusive7kWOutdoorStage1mMediumSummer,
              recoCompactExclusive7kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              recoCompactExclusive7kWOutdoorGallery1mSmallSummer,
              recoCompactExclusive7kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 39.7,
              url: RCE_ODU_7kW_A7W55,
            },
            silentMode: {
              volume: 39,
              url: RCE_ODU_7kW_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              recoCompactExclusive7kWOutdoorStage5mSmallWinter,
              recoCompactExclusive7kWOutdoorStage5mMediumWinter,
              recoCompactExclusive7kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive7kWOutdoorGallery5mSmallWinter,
              recoCompactExclusive7kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 35,
              url: RCE_ODU_7kW_A7W55,
            },
            silentMode: {
              volume: 28,
              url: RCE_ODU_7kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive7kWOutdoorStage3mSmallWinter,
              recoCompactExclusive7kWOutdoorStage3mMediumWinter,
              recoCompactExclusive7kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive7kWOutdoorGallery3mSmallWinter,
              recoCompactExclusive7kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 39.5,
              url: RCE_ODU_7kW_A7W55,
            },
            silentMode: {
              volume: 32.5,
              url: RCE_ODU_7kW_A7W55,
            },
          },
          {
            img: [
              recoCompactExclusive7kWOutdoorStage1mSmallWinter,
              recoCompactExclusive7kWOutdoorStage1mMediumWinter,
              recoCompactExclusive7kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              recoCompactExclusive7kWOutdoorGallery1mSmallWinter,
              recoCompactExclusive7kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 49,
              url: RCE_ODU_7kW_A7W55,
            },
            silentMode: {
              volume: 42,
              url: RCE_ODU_7kW_A7W55,
            },
          },
        ],
      },
    ],
  },
  {
    id: 5,
    category: "air",
    variants: [
      {
        power: 3,
        shortcut: "VTP3",
        intro: [
          versoThermPlus3kWOutdoorIntroSmallSummer,
          versoThermPlus3kWOutdoorIntroMediumSummer,
          versoThermPlus3kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          versoThermPlus3kWOutdoorIntroSmallWinter,
          versoThermPlus3kWOutdoorIntroMediumWinter,
          versoThermPlus3kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          versoThermPlus3kWOutdoorTeaserSmallSummer,
          versoThermPlus3kWOutdoorTeaserMediumSummer,
          versoThermPlus3kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          versoThermPlus3kWOutdoorTeaserSmallWinter,
          versoThermPlus3kWOutdoorTeaserMediumWinter,
          versoThermPlus3kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              versoThermPlus3kWOutdoorStage5mSmallSummer,
              versoThermPlus3kWOutdoorStage5mMediumSummer,
              versoThermPlus3kWOutdoorStage5mLargeSummer,
            ],
            gallery: [versoThermPlus3kWOutdoorGallery5mSmallSummer, versoThermPlus3kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 29.6,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 28,
              url: VTP_ODU_3_5_A7W55,
            },
          },
          {
            img: [
              versoThermPlus3kWOutdoorStage3mSmallSummer,
              versoThermPlus3kWOutdoorStage3mMediumSummer,
              versoThermPlus3kWOutdoorStage3mLargeSummer,
            ],
            gallery: [versoThermPlus3kWOutdoorGallery3mSmallSummer, versoThermPlus3kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 34.1,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 32.5,
              url: VTP_ODU_3_5_A7W55,
            },
          },
          {
            img: [
              versoThermPlus3kWOutdoorStage1mSmallSummer,
              versoThermPlus3kWOutdoorStage1mMediumSummer,
              versoThermPlus3kWOutdoorStage1mLargeSummer,
            ],
            gallery: [versoThermPlus3kWOutdoorGallery1mSmallSummer, versoThermPlus3kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 43.6,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 42,
              url: VTP_ODU_3_5_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              versoThermPlus3kWOutdoorStage5mSmallWinter,
              versoThermPlus3kWOutdoorStage5mMediumWinter,
              versoThermPlus3kWOutdoorStage5mLargeWinter,
            ],
            gallery: [versoThermPlus3kWOutdoorGallery5mSmallWinter, versoThermPlus3kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 33,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 29,
              url: VTP_ODU_3_5_A7W55,
            },
          },
          {
            img: [
              versoThermPlus3kWOutdoorStage3mSmallWinter,
              versoThermPlus3kWOutdoorStage3mMediumWinter,
              versoThermPlus3kWOutdoorStage3mLargeWinter,
            ],
            gallery: [versoThermPlus3kWOutdoorGallery3mSmallWinter, versoThermPlus3kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 37.5,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 33.5,
              url: VTP_ODU_3_5_A7W55,
            },
          },
          {
            img: [
              versoThermPlus3kWOutdoorStage1mSmallWinter,
              versoThermPlus3kWOutdoorStage1mMediumWinter,
              versoThermPlus3kWOutdoorStage1mLargeWinter,
            ],
            gallery: [versoThermPlus3kWOutdoorGallery1mSmallWinter, versoThermPlus3kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 47,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 43,
              url: VTP_ODU_3_5_A7W55,
            },
          },
        ],
      },
      {
        power: 5,
        shortcut: "VTP5",
        intro: [
          versoThermPlus5kWOutdoorIntroSmallSummer,
          versoThermPlus5kWOutdoorIntroMediumSummer,
          versoThermPlus5kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          versoThermPlus5kWOutdoorIntroSmallWinter,
          versoThermPlus5kWOutdoorIntroMediumWinter,
          versoThermPlus5kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          versoThermPlus5kWOutdoorTeaserSmallSummer,
          versoThermPlus5kWOutdoorTeaserMediumSummer,
          versoThermPlus5kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          versoThermPlus5kWOutdoorTeaserSmallWinter,
          versoThermPlus5kWOutdoorTeaserMediumWinter,
          versoThermPlus5kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              versoThermPlus5kWOutdoorStage5mSmallSummer,
              versoThermPlus5kWOutdoorStage5mMediumSummer,
              versoThermPlus5kWOutdoorStage5mLargeSummer,
            ],
            gallery: [versoThermPlus5kWOutdoorGallery5mSmallSummer, versoThermPlus5kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 29.6,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 28,
              url: VTP_ODU_3_5_A7W55,
            },
          },
          {
            img: [
              versoThermPlus5kWOutdoorStage3mSmallSummer,
              versoThermPlus5kWOutdoorStage3mMediumSummer,
              versoThermPlus5kWOutdoorStage3mLargeSummer,
            ],
            gallery: [versoThermPlus5kWOutdoorGallery3mSmallSummer, versoThermPlus5kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 34.1,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 32.5,
              url: VTP_ODU_3_5_A7W55,
            },
          },
          {
            img: [
              versoThermPlus5kWOutdoorStage1mSmallSummer,
              versoThermPlus5kWOutdoorStage1mMediumSummer,
              versoThermPlus5kWOutdoorStage1mLargeSummer,
            ],
            gallery: [versoThermPlus5kWOutdoorGallery1mSmallSummer, versoThermPlus5kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 43.6,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 42,
              url: VTP_ODU_3_5_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              versoThermPlus5kWOutdoorStage5mSmallWinter,
              versoThermPlus5kWOutdoorStage5mMediumWinter,
              versoThermPlus5kWOutdoorStage5mLargeWinter,
            ],
            gallery: [versoThermPlus5kWOutdoorGallery5mSmallWinter, versoThermPlus5kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 33,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 29,
              url: VTP_ODU_3_5_A7W55,
            },
          },
          {
            img: [
              versoThermPlus5kWOutdoorStage3mSmallWinter,
              versoThermPlus5kWOutdoorStage3mMediumWinter,
              versoThermPlus5kWOutdoorStage3mLargeWinter,
            ],
            gallery: [versoThermPlus5kWOutdoorGallery3mSmallWinter, versoThermPlus5kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 37.5,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 33.5,
              url: VTP_ODU_3_5_A7W55,
            },
          },
          {
            img: [
              versoThermPlus5kWOutdoorStage1mSmallWinter,
              versoThermPlus5kWOutdoorStage1mMediumWinter,
              versoThermPlus5kWOutdoorStage1mLargeWinter,
            ],
            gallery: [versoThermPlus5kWOutdoorGallery1mSmallWinter, versoThermPlus5kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 47,
              url: VTP_ODU_3_5_A7W55,
            },
            silentMode: {
              volume: 43,
              url: VTP_ODU_3_5_A7W55,
            },
          },
        ],
      },
      {
        power: 7,
        shortcut: "VTP7",
        intro: [
          versoThermPlus7kWOutdoorIntroSmallSummer,
          versoThermPlus7kWOutdoorIntroMediumSummer,
          versoThermPlus7kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          versoThermPlus7kWOutdoorIntroSmallWinter,
          versoThermPlus7kWOutdoorIntroMediumWinter,
          versoThermPlus7kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          versoThermPlus7kWOutdoorTeaserSmallSummer,
          versoThermPlus7kWOutdoorTeaserMediumSummer,
          versoThermPlus7kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          versoThermPlus7kWOutdoorTeaserSmallWinter,
          versoThermPlus7kWOutdoorTeaserMediumWinter,
          versoThermPlus7kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              versoThermPlus7kWOutdoorStage5mSmallSummer,
              versoThermPlus7kWOutdoorStage5mMediumSummer,
              versoThermPlus7kWOutdoorStage5mLargeSummer,
            ],
            gallery: [versoThermPlus7kWOutdoorGallery5mSmallSummer, versoThermPlus7kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 25.7,
              url: VTP_ODU_7_A7W55,
            },
            silentMode: {
              volume: 25,
              url: VTP_ODU_7_A7W55,
            },
          },
          {
            img: [
              versoThermPlus7kWOutdoorStage3mSmallSummer,
              versoThermPlus7kWOutdoorStage3mMediumSummer,
              versoThermPlus7kWOutdoorStage3mLargeSummer,
            ],
            gallery: [versoThermPlus7kWOutdoorGallery3mSmallSummer, versoThermPlus7kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 30.2,
              url: VTP_ODU_7_A7W55,
            },
            silentMode: {
              volume: 29.5,
              url: VTP_ODU_7_A7W55,
            },
          },
          {
            img: [
              versoThermPlus7kWOutdoorStage1mSmallSummer,
              versoThermPlus7kWOutdoorStage1mMediumSummer,
              versoThermPlus7kWOutdoorStage1mLargeSummer,
            ],
            gallery: [versoThermPlus7kWOutdoorGallery1mSmallSummer, versoThermPlus7kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 39.7,
              url: VTP_ODU_7_A7W55,
            },
            silentMode: {
              volume: 39,
              url: VTP_ODU_7_A7W55,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              versoThermPlus7kWOutdoorStage5mSmallWinter,
              versoThermPlus7kWOutdoorStage5mMediumWinter,
              versoThermPlus7kWOutdoorStage5mLargeWinter,
            ],
            gallery: [versoThermPlus7kWOutdoorGallery5mSmallWinter, versoThermPlus7kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 35,
              url: VTP_ODU_7_A7W55,
            },
            silentMode: {
              volume: 28,
              url: VTP_ODU_7_A7W55,
            },
          },
          {
            img: [
              versoThermPlus7kWOutdoorStage3mSmallWinter,
              versoThermPlus7kWOutdoorStage3mMediumWinter,
              versoThermPlus7kWOutdoorStage3mLargeWinter,
            ],
            gallery: [versoThermPlus7kWOutdoorGallery3mSmallWinter, versoThermPlus7kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 39.5,
              url: VTP_ODU_7_A7W55,
            },
            silentMode: {
              volume: 32.5,
              url: VTP_ODU_7_A7W55,
            },
          },
          {
            img: [
              versoThermPlus7kWOutdoorStage1mSmallWinter,
              versoThermPlus7kWOutdoorStage1mMediumWinter,
              versoThermPlus7kWOutdoorStage1mLargeWinter,
            ],
            gallery: [versoThermPlus7kWOutdoorGallery1mSmallWinter, versoThermPlus7kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 49,
              url: VTP_ODU_7_A7W55,
            },
            silentMode: {
              volume: 42,
              url: VTP_ODU_7_A7W55,
            },
          },
        ],
      },
    ],
  },
  {
    id: 6,
    category: "air",
    variants: [
      {
        power: 18,
        shortcut: "ATPE18",
        intro: [
          aroThermPeform18kWOutdoorIntroSmallSummer,
          aroThermPeform18kWOutdoorIntroMediumSummer,
          aroThermPeform18kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermPeform18kWOutdoorIntroSmallWinter,
          aroThermPeform18kWOutdoorIntroMediumWinter,
          aroThermPeform18kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          aroThermPeform18kWOutdoorTeaserSmallSummer,
          aroThermPeform18kWOutdoorTeaserMediumSummer,
          aroThermPeform18kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermPeform18kWOutdoorTeaserSmallWinter,
          aroThermPeform18kWOutdoorTeaserMediumWinter,
          aroThermPeform18kWOutdoorTeaserLargeWinter,
        ],
        scenesSummer: [
          {
            img: [
              aroThermPeform18kWOutdoorStage5mSmallSummer,
              aroThermPeform18kWOutdoorStage5mMediumSummer,
              aroThermPeform18kWOutdoorStage5mLargeSummer,
            ],
            gallery: [aroThermPeform18kWOutdoorGallery5mSmallSummer, aroThermPeform18kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 40,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 36,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeform18kWOutdoorStage3mSmallSummer,
              aroThermPeform18kWOutdoorStage3mMediumSummer,
              aroThermPeform18kWOutdoorStage3mLargeSummer,
            ],
            gallery: [aroThermPeform18kWOutdoorGallery3mSmallSummer, aroThermPeform18kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 44.5,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 40.5,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeform18kWOutdoorStage1mSmallSummer,
              aroThermPeform18kWOutdoorStage1mMediumSummer,
              aroThermPeform18kWOutdoorStage1mLargeSummer,
            ],
            gallery: [aroThermPeform18kWOutdoorGallery1mSmallSummer, aroThermPeform18kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 54,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 50,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermPeform18kWOutdoorStage5mSmallWinter,
              aroThermPeform18kWOutdoorStage5mMediumWinter,
              aroThermPeform18kWOutdoorStage5mLargeWinter,
            ],
            gallery: [aroThermPeform18kWOutdoorGallery5mSmallWinter, aroThermPeform18kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 40,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 36,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeform18kWOutdoorStage3mSmallWinter,
              aroThermPeform18kWOutdoorStage3mMediumWinter,
              aroThermPeform18kWOutdoorStage3mLargeWinter,
            ],
            gallery: [aroThermPeform18kWOutdoorGallery3mSmallWinter, aroThermPeform18kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 44.5,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 40.5,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeform18kWOutdoorStage1mSmallWinter,
              aroThermPeform18kWOutdoorStage1mMediumWinter,
              aroThermPeform18kWOutdoorStage1mLargeWinter,
            ],
            gallery: [aroThermPeform18kWOutdoorGallery1mSmallWinter, aroThermPeform18kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 54,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 50,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
        ],
      },
      {
        power: 25,
        shortcut: "ATPE25",
        intro: [
          aroThermPeform25kWOutdoorIntroSmallSummer,
          aroThermPeform25kWOutdoorIntroMediumSummer,
          aroThermPeform25kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermPeform25kWOutdoorIntroSmallWinter,
          aroThermPeform25kWOutdoorIntroMediumWinter,
          aroThermPeform25kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          aroThermPeform25kWOutdoorTeaserSmallSummer,
          aroThermPeform25kWOutdoorTeaserMediumSummer,
          aroThermPeform25kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermPeform25kWOutdoorTeaserSmallWinter,
          aroThermPeform25kWOutdoorTeaserMediumWinter,
          aroThermPeform25kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              aroThermPeform25kWOutdoorStage5mSmallSummer,
              aroThermPeform25kWOutdoorStage5mMediumSummer,
              aroThermPeform25kWOutdoorStage5mLargeSummer,
            ],
            gallery: [aroThermPeform25kWOutdoorGallery5mSmallSummer, aroThermPeform25kWOutdoorGallery5mLargeSummer],
            distance: 5,
            sound: {
              volume: 42,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 38,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeform25kWOutdoorStage3mSmallSummer,
              aroThermPeform25kWOutdoorStage3mMediumSummer,
              aroThermPeform25kWOutdoorStage3mLargeSummer,
            ],
            gallery: [aroThermPeform25kWOutdoorGallery3mSmallSummer, aroThermPeform25kWOutdoorGallery3mLargeSummer],
            distance: 3,
            sound: {
              volume: 46.5,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 42.5,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeform25kWOutdoorStage1mSmallSummer,
              aroThermPeform25kWOutdoorStage1mMediumSummer,
              aroThermPeform25kWOutdoorStage1mLargeSummer,
            ],
            gallery: [aroThermPeform25kWOutdoorGallery1mSmallSummer, aroThermPeform25kWOutdoorGallery1mLargeSummer],
            distance: 1,
            sound: {
              volume: 56,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 52,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermPeform25kWOutdoorStage5mSmallWinter,
              aroThermPeform25kWOutdoorStage5mMediumWinter,
              aroThermPeform25kWOutdoorStage5mLargeWinter,
            ],
            gallery: [aroThermPeform25kWOutdoorGallery5mSmallWinter, aroThermPeform25kWOutdoorGallery5mLargeWinter],
            distance: 5,
            sound: {
              volume: 42,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 38,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeform25kWOutdoorStage3mSmallWinter,
              aroThermPeform25kWOutdoorStage3mMediumWinter,
              aroThermPeform25kWOutdoorStage3mLargeWinter,
            ],
            gallery: [aroThermPeform25kWOutdoorGallery3mSmallWinter, aroThermPeform25kWOutdoorGallery3mLargeWinter],
            distance: 3,
            sound: {
              volume: 46.5,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 42.5,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeform25kWOutdoorStage1mSmallWinter,
              aroThermPeform25kWOutdoorStage1mMediumWinter,
              aroThermPeform25kWOutdoorStage1mLargeWinter,
            ],
            gallery: [aroThermPeform25kWOutdoorGallery1mSmallWinter, aroThermPeform25kWOutdoorGallery1mLargeWinter],
            distance: 1,
            sound: {
              volume: 56,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 52,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
        ],
      },
    ],
  },
  {
    id: 7,
    category: "air",
    variants: [
      {
        power: 18,
        shortcut: "ATPESS18",
        intro: [
          aroThermPeformSSP18kWOutdoorIntroSmallSummer,
          aroThermPeformSSP18kWOutdoorIntroMediumSummer,
          aroThermPeformSSP18kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermPeformSSP18kWOutdoorIntroSmallWinter,
          aroThermPeformSSP18kWOutdoorIntroMediumWinter,
          aroThermPeformSSP18kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          aroThermPeformSSP18kWOutdoorTeaserSmallSummer,
          aroThermPeformSSP18kWOutdoorTeaserMediumSummer,
          aroThermPeformSSP18kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermPeformSSP18kWOutdoorTeaserSmallWinter,
          aroThermPeformSSP18kWOutdoorTeaserMediumWinter,
          aroThermPeformSSP18kWOutdoorTeaserLargeWinter,
        ],

        scenesSummer: [
          {
            img: [
              aroThermPeformSSP18kWOutdoorStage5mSmallSummer,
              aroThermPeformSSP18kWOutdoorStage5mMediumSummer,
              aroThermPeformSSP18kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              aroThermPeformSSP18kWOutdoorGallery5mSmallSummer,
              aroThermPeformSSP18kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 37,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 33,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeformSSP18kWOutdoorStage3mSmallSummer,
              aroThermPeformSSP18kWOutdoorStage3mMediumSummer,
              aroThermPeformSSP18kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              aroThermPeformSSP18kWOutdoorGallery3mSmallSummer,
              aroThermPeformSSP18kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 41.5,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 37.5,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeformSSP18kWOutdoorStage1mSmallSummer,
              aroThermPeformSSP18kWOutdoorStage1mMediumSummer,
              aroThermPeformSSP18kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              aroThermPeformSSP18kWOutdoorGallery1mSmallSummer,
              aroThermPeformSSP18kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 51,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 47,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermPeformSSP18kWOutdoorStage5mSmallWinter,
              aroThermPeformSSP18kWOutdoorStage5mMediumWinter,
              aroThermPeformSSP18kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              aroThermPeformSSP18kWOutdoorGallery5mSmallWinter,
              aroThermPeformSSP18kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 37,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 33,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeformSSP18kWOutdoorStage3mSmallWinter,
              aroThermPeformSSP18kWOutdoorStage3mMediumWinter,
              aroThermPeformSSP18kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              aroThermPeformSSP18kWOutdoorGallery3mSmallWinter,
              aroThermPeformSSP18kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 41.5,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 37.5,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeformSSP18kWOutdoorStage1mSmallWinter,
              aroThermPeformSSP18kWOutdoorStage1mMediumWinter,
              aroThermPeformSSP18kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              aroThermPeformSSP18kWOutdoorGallery1mSmallWinter,
              aroThermPeformSSP18kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 51,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 47,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
        ],
      },
      {
        power: 25,
        shortcut: "ATPESS25",
        intro: [
          aroThermPeformSSP25kWOutdoorIntroSmallSummer,
          aroThermPeformSSP25kWOutdoorIntroMediumSummer,
          aroThermPeformSSP25kWOutdoorIntroLargeSummer,
        ],
        introWinter: [
          aroThermPeformSSP25kWOutdoorIntroSmallWinter,
          aroThermPeformSSP25kWOutdoorIntroMediumWinter,
          aroThermPeformSSP25kWOutdoorIntroLargeWinter,
        ],
        teaser: [
          aroThermPeformSSP25kWOutdoorTeaserSmallSummer,
          aroThermPeformSSP25kWOutdoorTeaserMediumSummer,
          aroThermPeformSSP25kWOutdoorTeaserLargeSummer,
        ],
        teaserWinter: [
          aroThermPeformSSP25kWOutdoorTeaserSmallWinter,
          aroThermPeformSSP25kWOutdoorTeaserMediumWinter,
          aroThermPeformSSP25kWOutdoorTeaserLargeWinter,
        ],
        scenesSummer: [
          {
            img: [
              aroThermPeformSSP25kWOutdoorStage5mSmallSummer,
              aroThermPeformSSP25kWOutdoorStage5mMediumSummer,
              aroThermPeformSSP25kWOutdoorStage5mLargeSummer,
            ],
            gallery: [
              aroThermPeformSSP25kWOutdoorGallery5mSmallSummer,
              aroThermPeformSSP25kWOutdoorGallery5mLargeSummer,
            ],
            distance: 5,
            sound: {
              volume: 39,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 35,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeformSSP25kWOutdoorStage3mSmallSummer,
              aroThermPeformSSP25kWOutdoorStage3mMediumSummer,
              aroThermPeformSSP25kWOutdoorStage3mLargeSummer,
            ],
            gallery: [
              aroThermPeformSSP25kWOutdoorGallery3mSmallSummer,
              aroThermPeformSSP25kWOutdoorGallery3mLargeSummer,
            ],
            distance: 3,
            sound: {
              volume: 43.5,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 39.5,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeformSSP25kWOutdoorStage1mSmallSummer,
              aroThermPeformSSP25kWOutdoorStage1mMediumSummer,
              aroThermPeformSSP25kWOutdoorStage1mLargeSummer,
            ],
            gallery: [
              aroThermPeformSSP25kWOutdoorGallery1mSmallSummer,
              aroThermPeformSSP25kWOutdoorGallery1mLargeSummer,
            ],
            distance: 1,
            sound: {
              volume: 53,
              url: ATPE_ODU_A7W55,
            },
            silentMode: {
              volume: 49,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
        ],
        scenesWinter: [
          {
            img: [
              aroThermPeformSSP25kWOutdoorStage5mSmallWinter,
              aroThermPeformSSP25kWOutdoorStage5mMediumWinter,
              aroThermPeformSSP25kWOutdoorStage5mLargeWinter,
            ],
            gallery: [
              aroThermPeformSSP25kWOutdoorGallery5mSmallWinter,
              aroThermPeformSSP25kWOutdoorGallery5mLargeWinter,
            ],
            distance: 5,
            sound: {
              volume: 39,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 35,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeformSSP25kWOutdoorStage3mSmallWinter,
              aroThermPeformSSP25kWOutdoorStage3mMediumWinter,
              aroThermPeformSSP25kWOutdoorStage3mLargeWinter,
            ],
            gallery: [
              aroThermPeformSSP25kWOutdoorGallery3mSmallWinter,
              aroThermPeformSSP25kWOutdoorGallery3mLargeWinter,
            ],
            distance: 3,
            sound: {
              volume: 43.5,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 39.5,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
          {
            img: [
              aroThermPeformSSP25kWOutdoorStage1mSmallWinter,
              aroThermPeformSSP25kWOutdoorStage1mMediumWinter,
              aroThermPeformSSP25kWOutdoorStage1mLargeWinter,
            ],
            gallery: [
              aroThermPeformSSP25kWOutdoorGallery1mSmallWinter,
              aroThermPeformSSP25kWOutdoorGallery1mLargeWinter,
            ],
            distance: 1,
            sound: {
              volume: 53,
              url: ATPE_ODU_max_sound_power_level,
            },
            silentMode: {
              volume: 49,
              url: ATPE_ODU_silent_mode_A7W35,
            },
          },
        ],
      },
    ],
  },
];
