import classNames from "classnames";
import "./Separator.scss";

type SeparatorProps = {
  className?: string;
};

export const Separator = (props: SeparatorProps) => {
  return <div className={classNames("separator", props.className)}></div>;
};
