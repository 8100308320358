import classNames from "classnames";
import "./Tag.scss";

type TagProps = {
  label: string;
  active?: boolean;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

export const Tag = (props: TagProps) => {
  return (
    <div
      className={classNames("tag", props.active && "tag--active", props.disabled && "tag--disabled", props.className)}
      role="button"
      tabIndex={props.disabled ? 1 : 0}
      onClick={props.disabled ? () => {} : props.onClick}
    >
      {props.label}
    </div>
  );
};
