import "./QuickChange.scss";
import villageImage from "../../static/images/village.png";
import cityImage from "../../static/images/city.png";
import suburbsImage from "../../static/images/suburbs.png";
import { IconButton } from "../IconButton/IconButton";
import { ReactComponent as PauseIcon } from "../../static/icons/pause.svg";
import { ReactComponent as PlayIcon } from "../../static/icons/play.svg";
import { ReactComponent as ConfigIcon } from "../../static/icons/config.svg";
import { SwitchButton } from "../SwitchButton/SwitchButton";
import { useContext, useRef, useState } from "react";
import { ReactComponent as SoundIcon } from "../../static/icons/sound.svg";
import { useClickOutside } from "../../hooks/useClickOutside";
import { SoundboxSettings } from "../../types/soundboxSettings";
import { PageDataContext } from "../../context/PageDataContext";
import classNames from "classnames";

export type QuickChangeProps = {
  setOpenSoundSelection: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      wasPausedBeforeChange: boolean;
    }>
  >;
  soundboxSettings: SoundboxSettings;
  muteSurroundings: boolean;
  setMuteSurroundings: React.Dispatch<React.SetStateAction<boolean>>;
};

export const QuickChange = (props: QuickChangeProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const quickChangeRef = useRef<HTMLDivElement>(null);
  useClickOutside(quickChangeRef, () => setOpen(false));
  const context = useContext(PageDataContext);

  const selectSurroundibngsImage = () => {
    if (props.soundboxSettings.surroundings === "city") {
      return cityImage;
    } else if (props.soundboxSettings.surroundings === "village") {
      return villageImage;
    }

    return suburbsImage;
  };

  const mainButton = (
    <button className="quick-change__button" onClick={() => setOpen(open => !open)}>
      <img className="quick-change__image" src={selectSurroundibngsImage()} alt="Image" />
    </button>
  );

  return (
    <div className="quick-change">
      <div className="quick-change__button-wrapper">
        {mainButton}

        {open && (
          <div className="quick-change__menu" ref={quickChangeRef}>
            <div className="quick-change__item">
              <IconButton
                onClick={() => {
                  props.setOpenSoundSelection({
                    open: true,
                    wasPausedBeforeChange: props.muteSurroundings,
                  });
                  setOpen(false);
                }}
              >
                <ConfigIcon />
              </IconButton>
            </div>
            <div className="quick-change__item">
              <SwitchButton active={!props.muteSurroundings} onClick={() => props.setMuteSurroundings(e => !e)}>
                {props.muteSurroundings ? <PlayIcon /> : <PauseIcon />}
              </SwitchButton>
            </div>
            <div className="quick-change__item">{mainButton}</div>
          </div>
        )}
      </div>
      <div className="quick-change__text">
        <span
          className={classNames("quick-change__icon", !props.muteSurroundings && "quick-change__icon--audio-active")}
        >
          <SoundIcon />
        </span>
        {context.translations.soundSelection[props.soundboxSettings.surroundings || "village"]}
      </div>
    </div>
  );
};
