import { createRef, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { PageDataContext } from "../context/PageDataContext";
import { Surroundings } from "./../types/surroundings";
import { useIsSummer } from "./useIsSummer";

const citySummer = require("../static/backgroundNoise/citySummer.mp3");
const cityWinter = require("../static/backgroundNoise/cityWinter.mp3");
const suburbanWinter = require("../static/backgroundNoise/suburbanWinter.mp3");
const suburbanSummer = require("../static/backgroundNoise/suburbanSummer.mp3");
const ruralWinter = require("../static/backgroundNoise/ruralWinter.mp3");
const ruralSummer = require("../static/backgroundNoise/ruralSummer.mp3");
const clock = require("../static/backgroundNoise/clock.mp3");

export function mapSound(surroundings: Surroundings, isSummer: boolean, playIndoorScene: boolean): string | null {
  if (surroundings === null) {
    return null;
  }

  if (playIndoorScene) {
    return clock;
  }

  switch (surroundings) {
    case "city": {
      return isSummer ? citySummer : cityWinter;
    }

    case "village": {
      return isSummer ? ruralSummer : ruralWinter;
    }

    case "suburbs": {
      return isSummer ? suburbanSummer : suburbanWinter;
    }

    default: {
      throw Error("INVALID_SURROUNDING");
    }
  }
}

export const useBackgroundAudio = (
  surroundings: Surroundings,
  currentVolume: number,
  isPaused: boolean,
  muteSurroundings: React.Dispatch<React.SetStateAction<boolean>>,
  playIndoorScene: boolean,
  isMuted: boolean,
) => {
  const context = useContext(PageDataContext);
  const { isSummer } = useIsSummer(context.translations);
  const [isLoading, setIsLoading] = useState(false);
  const audioCtxRef = useRef<AudioContext>();
  const trackRef = useRef<MediaElementAudioSourceNode>();
  const audioElementRef = useRef<HTMLAudioElement>();
  const gainNodeRef = useRef<GainNode>();
  const loadingData = useRef<boolean>(false);
  const loadingDataRetry = useRef<boolean>(false);
  const [retryTrigger, setRetryTrigger] = useState<{ retry: "RETRY" }>({ retry: "RETRY" });

  const init = () => {
    if (!audioElementRef.current) {
      return;
    }

    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    const audioCtx = new AudioContext();
    const track = audioCtx.createMediaElementSource(audioElementRef.current);
    const gainNode = audioCtx.createGain();

    gainNode.gain.value = currentVolume / 100;
    gainNodeRef.current = gainNode;

    trackRef.current = track;
    audioCtxRef.current = audioCtx;

    track.connect(gainNode).connect(audioCtx.destination);
  };

  useEffect(() => {
    const audioElement = new Audio();
    audioElement.loop = true;

    const callback = () => {
      setIsLoading(false);
    };

    const pause = () => {
      muteSurroundings(true);
    };

    const play = () => {
      muteSurroundings(false);
    };

    audioElement.addEventListener("canplay", callback);
    audioElement.addEventListener("pause", pause);
    audioElement.addEventListener("play", play);
    audioElementRef.current = audioElement;
    setRetryTrigger({ retry: "RETRY" });
    return () => {
      audioElement.pause();
      audioElement.src = "";
      audioElement.removeEventListener("canplay", callback);
      audioElement.removeEventListener("pause", pause);
      audioElement.removeEventListener("play", play);

      trackRef.current?.disconnect(gainNodeRef.current as GainNode);
    };
  }, []);

  useLayoutEffect(() => {
    if (!surroundings) {
      return;
    }

    if (!audioCtxRef.current) {
      init();
    }

    if (!loadingData.current) {
      loadData();
    } else {
      loadingDataRetry.current = true;
    }
  }, [surroundings, retryTrigger, playIndoorScene]);

  useLayoutEffect(() => {
    const volume = currentVolume / 100;

    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = volume;
    }
  }, [currentVolume]);

  useLayoutEffect(() => {
    if (!surroundings) {
      return;
    }

    if (isPaused) {
      audioElementRef.current?.pause();
    } else {
      audioElementRef.current?.play().catch(e => {});
    }
  }, [isPaused]);

  useLayoutEffect(() => {
    if (!gainNodeRef.current || !audioElementRef.current) {
      return;
    }

    if (isMuted) {
      gainNodeRef.current.gain.value = 0;
    } else {
      audioElementRef.current.play().catch(e => {});
      gainNodeRef.current.gain.value = currentVolume / 100;
    }
  }, [isMuted]);

  const loadData = async () => {
    if (!audioElementRef.current) {
      return;
    }
    const url = mapSound(surroundings, isSummer, playIndoorScene);

    if (!url) {
      return;
    }

    if (audioCtxRef.current && audioCtxRef.current.state === "suspended") {
      audioCtxRef.current.resume();
    }

    loadingData.current = true;

    setIsLoading(true);
    audioElementRef.current.src = url;
    audioElementRef.current.pause();
    audioElementRef.current.load();

    !isPaused && (await audioElementRef.current.play().catch(e => {}));

    loadingData.current = false;
    if (loadingDataRetry.current) {
      loadingDataRetry.current = false;
      setRetryTrigger({ retry: "RETRY" });
    }
  };

  return { isLoading, audioRef: audioElementRef };
};
