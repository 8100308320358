import { HeatPumpData } from "../types/heatPump";

export const checkIfIsMoreThanOneCategory = (heatPumps: Array<HeatPumpData>) => {
  const heatPumpElement = heatPumps[0];

  for (let i = 0; i < heatPumps.length; i++) {
    if (heatPumpElement.category !== heatPumps[i].category) {
      return true;
    }
  }

  return false;
};
