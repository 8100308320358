import "./OverlayTimeUp.scss";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import { useContext, useEffect, useRef, useState } from "react";
import { PageDataContext } from "../../context/PageDataContext";
import { useLocation, useParams } from "react-router-dom";

type OverlayTimeUpProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OverlayTimeUp = (props: OverlayTimeUpProps) => {
  const context = useContext(PageDataContext);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const location = useLocation();
  const { lang } = useParams();

  useEffect(() => {
    const handleClick = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        props.setOpen(true);
      }, 60000);
    };

    document.addEventListener("click", handleClick);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return props.open ? (
    <Modal>
      <div className="overlay-time-up">
        <h1 className="overlay-time-up__title">{context.translations.overlayTimeUp.title}</h1>
        <p className="overlay-time-up__subtitle">{context.translations.overlayTimeUp.subtitle}</p>
        <div className="overlay-time-up__buttons-wrapper">
          <div className="overlay-time-up__first-button-wrapper">
            <Button type="primary" className="overlay-time-up__button" href={`/${lang}${location.search}`}>
              {context.translations.overlayTimeUp.startNewButtonText}
            </Button>
          </div>
          <Button type="tertiary" className="overlay-time-up__button" onClick={() => props.setOpen(false)}>
            {context.translations.overlayTimeUp.cancelButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};
