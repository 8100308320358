import { useMemo } from "react";
import { Translations } from "../types/languages";

export const useIsSummer = (translations: Translations) => {
  const isSummer = useMemo(() => {
    if (!translations.isSummer) {
      return true;
    }
    const date = new Date();

    const startDate = new Date(
      date.getFullYear(),
      translations.isSummer.from.month - 1,
      translations.isSummer.from.day,
    );
    const endDate = new Date(date.getFullYear(), translations.isSummer.to.month - 1, translations.isSummer.to.day);

    if (startDate.getTime() < endDate.getTime()) {
      if (startDate.getTime() <= date.getTime() && endDate.getTime() >= date.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      if (startDate.getTime() >= date.getTime() && endDate.getTime() <= date.getTime()) {
        return false;
      } else {
        return true;
      }
    }
  }, [translations]);

  return {
    isSummer,
  };
};
