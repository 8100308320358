import { ControlButton } from "../ControlButton/ControlButton";
import "./SoundSelection.scss";
import village from "../../static/images/village.png";
import suburbs from "../../static/images/suburbs.png";
import city from "../../static/images/city.png";
import { Separator } from "../Separator/Separator";
import { RangeInput } from "../RangeInput/RangeInput";
import { ReactComponent as SoundIcon } from "../../static/icons/sound.svg";
import { ReactComponent as SoundMinIcon } from "../../static/icons/soundMin.svg";
import { ReactComponent as SoundMaxIcon } from "../../static/icons/soundMax.svg";
import classNames from "classnames";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import { useContext, useEffect, useRef, useState } from "react";
import { PageDataContext } from "../../context/PageDataContext";
import { OutdoorSurroundings, Surroundings } from "../../types/surroundings";
import { SoundboxSettings } from "../../types/soundboxSettings";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";
import { Loading } from "../Loading/Loading";
import { useLoadingDataDelay } from "../../hooks/useLoadingDataDelay";

type SoundSelectionProps = {
  soundboxSettings: SoundboxSettings;
  setSoundboxSettings: React.Dispatch<React.SetStateAction<SoundboxSettings>>;
  setOpenSoundSelection: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      wasPausedBeforeChange: boolean;
    }>
  >;
  soundSelectionVolume: number;
  soundSelectionSurroundings: Surroundings | undefined;
  setSoundSelectionVolume: React.Dispatch<React.SetStateAction<number>>;
  setSoundSelectionSurroundings: React.Dispatch<React.SetStateAction<Surroundings>>;
  soundLoading: boolean;
};

export const SoundSelection = React.memo((props: SoundSelectionProps) => {
  const context = useContext(PageDataContext);
  const navigate = useNavigate();
  const { lang } = useParams();
  const location = useLocation();
  const { showLoading } = useLoadingDataDelay(props.soundLoading);

  const createAreaSelector = (image: string, title: string, selectorSurroundings: Surroundings) => {
    const active = selectorSurroundings === props.soundSelectionSurroundings;

    return (
      <div className="area-selector__item">
        <div className="area-selector__icon-wrapper">
          <ControlButton
            image={image}
            alt={title}
            active={active}
            onClick={() => {
              props.setSoundSelectionSurroundings(selectorSurroundings);
            }}
          />
        </div>
        <div className={classNames("area-selector-description", active && "area-selector-description--active")}>
          {active && (
            <span className="area-selector-description__icon">{showLoading ? <Loading /> : <SoundIcon />}</span>
          )}
          <span>{title}</span>
        </div>
      </div>
    );
  };

  return (
    <Modal>
      <div className="sound-selection">
        <h1 className="sound-selection__title">{context.translations.soundSelection.title}</h1>
        <p className="sound-selection__subtitle">{context.translations.soundSelection.subtitle}</p>
        <div className="area-selector">
          {createAreaSelector(village, context.translations.soundSelection.village, "village")}
          {createAreaSelector(suburbs, context.translations.soundSelection.suburbs, "suburbs")}
          {createAreaSelector(city, context.translations.soundSelection.city, "city")}
        </div>
        <Separator />
        <div className="sound-range">
          <div className="sound-range__icon">
            <SoundMinIcon />
          </div>
          <RangeInput value={props.soundSelectionVolume} setValue={props.setSoundSelectionVolume} />
          <div className="sound-range__icon">
            <SoundMaxIcon />
          </div>
        </div>
        <Separator />
        <div className="sound-selection-buttons">
          <div className="sound-selection-buttons__first-button-wrapper">
            <Button
              className="sound-selection-buttons__button"
              disabled={!props.soundSelectionSurroundings}
              type="primary"
              onClick={() => {
                props.setSoundboxSettings({
                  volume: props.soundSelectionVolume,
                  surroundings: props.soundSelectionSurroundings || "village",
                });
                props.setOpenSoundSelection(e => ({ ...e, open: false }));

                if (props.soundSelectionSurroundings) {
                  (window as any).dataLayer.push({
                    event: "noise_selection",
                    background_noise: props.soundSelectionSurroundings,
                  });
                }
              }}
            >
              {context.translations.soundSelection.confirmButtonText}
            </Button>
          </div>
          <Button
            className="sound-selection-buttons__button"
            type="tertiary"
            onClick={() => {
              if (props.soundboxSettings.surroundings === null) {
                navigate(`/${lang}${location.search}`);
              }
              props.setOpenSoundSelection(e => ({ ...e, open: false }));
            }}
          >
            {context.translations.soundSelection.cancelButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
});
