import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SelectedHeatPumpContext } from "../../context/SelectedHeatPumpContext";
import { useGetAvailableLanguages } from "../../hooks/useGetAvailableLanguages";
import { useIsSummer } from "../../hooks/useIsSummer";
import { Translations } from "../../types/languages";
import { HeatPumpData } from "../../types/heatPump";
import { ProductIntroV1 } from "../../components/ProductIntroV1/ProductIntroV1";
import { ProductIntroV2 } from "../../components/ProductIntroV2/ProductIntroV2";

type SelectedProductIntroProps = {
  context: {
    translations: Translations;
    heatPumps: Array<HeatPumpData>;
  };
};

export const SelectedProductIntro = (props: SelectedProductIntroProps) => {
  const { context } = props;
  const selectedHeatPumpContext = useContext(SelectedHeatPumpContext);
  const { isSummer } = useIsSummer(context.translations);
  const heatPump = context.heatPumps.find(e => e.id === selectedHeatPumpContext.data?.heatPumpId);
  const location = useLocation();
  const { lang } = useParams();
  const { availableLanguages } = useGetAvailableLanguages();

  const selectImages = () => {
    const variantData = heatPump?.variants[selectedHeatPumpContext.data!!.variantIndex];

    if (!isSummer) {
      return variantData?.introWinter || variantData?.intro;
    }

    return variantData?.intro || variantData?.introWinter;
  };
  if (!heatPump) {
    return null;
  }

  const images = selectImages();

  return (
    <ProductIntroV1
      images={images}
      pageTitle={context.translations.intro.selectedProduct.title}
      buttonText={context.translations.intro.selectedProduct.buttonText}
      locationSearch={location.search}
      lang={lang}
      availableLanguages={availableLanguages}
    />
  );
};
