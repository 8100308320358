import { useMemo } from "react";
import { SelectedHeatPumpData } from "../context/SelectedHeatPumpContext";
import { HeatPumpData } from "../types/heatPump";
import { Translations } from "../types/languages";

export const useFindHeatPumpTranslation = (
  context: { translations: Translations; heatPumps: Array<HeatPumpData> },
  heatPumpData: SelectedHeatPumpData,
) => {
  const heatPumpTranslation = useMemo(() => {
    const heatPump = context.heatPumps.find(e => e.id === heatPumpData.heatPumpId)?.variants[heatPumpData.variantIndex];

    if (!heatPump) {
      return null;
    }

    const shortcut = heatPump.shortcut;
    const hasProperty = context.translations?.pumps?.hasOwnProperty(shortcut);

    return hasProperty ? context.translations.pumps[shortcut] : null;
  }, [context, heatPumpData]);

  return {
    heatPumpTranslation,
  };
};
