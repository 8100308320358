import "./SceneDescriptionImage.scss";
import classNames from "classnames";

type SceneDescriptionImageProps = {
  active?: boolean;
  distance: string | number;
  img: Array<string>;
  onClick: () => void;
};

export const SceneDescriptionImage = (props: SceneDescriptionImageProps) => {
  return (
    <div
      className={classNames("scene-description-image", props.active && "scene-description-image--active")}
      onClick={props.onClick}
      tabIndex={0}
      onKeyPress={event => {
        if (event.key === "Enter") {
          props.onClick();
        }
      }}
    >
      <picture className="soundbox-carousel__image">
        <source srcSet={props.img[0]} media="(max-width: 768px)" />
        <img src={props.img[1]} alt={props.distance as string} className="soundbox-carousel__image" />
      </picture>
      <span className="scene-description-image__text">{props.distance}</span>
    </div>
  );
};
