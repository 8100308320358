import "./Soundbox.scss";
import { SoundboxHeader } from "../../components/SoundboxHeader/SoundboxHeader";
import { SoundboxCarouselControl } from "../../components/SoundboxCarouselConrol/SoundboxCarouselControl";
import { OverlayRestart } from "../../components/OverlayRestart/OverlayRestart";
import { OverlayTimeUp } from "../../components/OverlayTimeUp/OverlayTimeUp";
import { SoundboxCarousel } from "../../components/SoundboxCarousel/SoundboxCarousel";
import { useCarousel } from "../../hooks/useCarousel";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { SoundSelection } from "../../components/SoundSelection/SoundSelection";
import { SelectedHeatPumpContext } from "../../context/SelectedHeatPumpContext";
import { useBackgroundAudio } from "../../hooks/useBackgroundAudio";
import { Surroundings } from "../../types/surroundings";
import { useHeatPumpAudio } from "../../hooks/useHeatPumpAudio";
import { useGetCurrentScenes } from "../../hooks/useGetCurrentScenes";
import { BackgroundSelectionContext } from "../../context/BackgroundSelectionContext";
import { isSafari } from "../../utils/isSafari";

export const Soundbox = () => {
  const selectedHeatPumpContext = useContext(SelectedHeatPumpContext);
  const carouselRef = useRef<HTMLDivElement>(null);
  const backgroundSelection = useContext(BackgroundSelectionContext);
  const [openSoundSelection, setOpenSoundSelection] = useState<{ open: boolean; wasPausedBeforeChange: boolean }>({
    open: backgroundSelection.data.surroundings === null || isSafari(),
    wasPausedBeforeChange: false,
  });
  const [muteSurroundings, setMuteSurroundings] = useState<boolean>(false);
  const [turnOnSound, setTurnOnSound] = useState<boolean>(true);
  const [nightMode, setNightMode] = useState<boolean>(false);
  const [soundSelectionVolume, setSoundSelectionVolume] = useState<number>(backgroundSelection.data.volume);
  const currentScenes = useGetCurrentScenes();
  const [soundSelectionSurroundings, setSoundSelectionSurroundings] = useState<Surroundings>(
    isSafari() ? null : backgroundSelection.data.surroundings,
  );
  const { translateCarousel, disableArrow, activeIndex, changeTranslate } = useCarousel(
    100,
    currentScenes.length,
    [carouselRef],
    true,
  );

  const [openTimeUp, setOpenTimeUp] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (isSafari()) {
        backgroundSelection.setData(e => ({
          ...e,
          surroundings: null,
        }));
      }
    };
  }, []);

  const { isLoading, audioRef } = useBackgroundAudio(
    openSoundSelection.open ? soundSelectionSurroundings : backgroundSelection.data.surroundings,
    openSoundSelection.open ? soundSelectionVolume : backgroundSelection.data.volume,
    checkMuteSurroundings(),
    setMuteSurroundings,
    openSoundSelection.open ? false : !currentScenes[activeIndex]?.outside,
    false,
  );

  function checkMuteSurroundings() {
    if (openSoundSelection.open && !openTimeUp) {
      return false;
    } else if (openTimeUp) {
      return true;
    }

    return muteSurroundings;
  }

  useLayoutEffect(() => {
    if (!openSoundSelection.open && audioRef.current && openSoundSelection.wasPausedBeforeChange) {
      audioRef.current.pause();
    }
  }, [openSoundSelection]);

  const [openRestartOverlay, setOverlayRestart] = useState<boolean>(false);
  const { loading: loadingHeatPumpSound } = useHeatPumpAudio(
    activeIndex,
    backgroundSelection.data.surroundings !== null ? turnOnSound : false,
    nightMode,
    currentScenes,
    setTurnOnSound,
    openSoundSelection.open || openTimeUp ? true : false,
  );

  useEffect(() => {
    changeTranslate(0);
  }, [selectedHeatPumpContext.data]);

  return (
    <>
      <div className="soundbox">
        {backgroundSelection.data.surroundings !== null && (
          <>
            <SoundboxHeader
              turnOnSound={openSoundSelection.open ? false : turnOnSound}
              nightMode={nightMode}
              setTurnOnSound={setTurnOnSound}
              setNightMode={setNightMode}
              setOverlayRestart={setOverlayRestart}
              activeIndex={activeIndex}
              loadingHeatPumpSound={loadingHeatPumpSound}
              surrounding={backgroundSelection.data.surroundings}
            />
            <SoundboxCarouselControl
              activeIndex={activeIndex}
              disableArrow={disableArrow}
              translateCarousel={translateCarousel}
              setOpenSoundSelection={setOpenSoundSelection}
              soundboxSettings={backgroundSelection.data}
              muteSurroundings={muteSurroundings}
              setMuteSurroundings={setMuteSurroundings}
              changeTranslate={changeTranslate}
              selectedHeatPumpContext={selectedHeatPumpContext.data}
            />
          </>
        )}
        <SoundboxCarousel carouselRef={carouselRef} />
      </div>
      {openSoundSelection.open && (
        <SoundSelection
          setSoundSelectionVolume={setSoundSelectionVolume}
          setSoundSelectionSurroundings={setSoundSelectionSurroundings}
          soundSelectionVolume={soundSelectionVolume}
          soundSelectionSurroundings={soundSelectionSurroundings}
          soundboxSettings={backgroundSelection.data}
          setSoundboxSettings={backgroundSelection.setData}
          setOpenSoundSelection={setOpenSoundSelection}
          soundLoading={isLoading}
        />
      )}
      {openRestartOverlay && <OverlayRestart setOverlayRestart={setOverlayRestart} />}
      <OverlayTimeUp open={openTimeUp} setOpen={setOpenTimeUp} />
    </>
  );
};
