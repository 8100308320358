import React from "react";
import { useContext } from "react";
import { PageDataContext } from "../../context/PageDataContext";
import { SelectedHeatPumpData } from "../../context/SelectedHeatPumpContext";
import { useFindHeatPumpTranslation } from "../../hooks/useFindHeatPumpTranslation";
import { useGetCurrentScenes } from "../../hooks/useGetCurrentScenes";
import { SoundboxSettings } from "../../types/soundboxSettings";
import { CTAButton } from "../CTAButton/CTAButton";
import { SceneDescription } from "../SceneDescription/SceneDescription";
import { SoundboxCarouselButton } from "../SoundboxCarouselButton/SoundboxCarouselButton";
import "./SoundboxCarouselControl.scss";

type SoundboxCarouselControlProps = {
  translateCarousel: (direction: "left" | "right") => void;
  disableArrow: { right: boolean; left: boolean };
  setOpenSoundSelection: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      wasPausedBeforeChange: boolean;
    }>
  >;
  soundboxSettings: SoundboxSettings;
  muteSurroundings: boolean;
  setMuteSurroundings: React.Dispatch<React.SetStateAction<boolean>>;
  activeIndex: number;
  changeTranslate: (value: number) => void;
  selectedHeatPumpContext: SelectedHeatPumpData;
};

export const SoundboxCarouselControl = React.memo((props: SoundboxCarouselControlProps) => {
  const context = useContext(PageDataContext);
  const currentScenes = useGetCurrentScenes();
  const { heatPumpTranslation } = useFindHeatPumpTranslation(context, props.selectedHeatPumpContext);

  const selectSceneButtonDescription = (buttonSceneIndex: number) => {
    if (buttonSceneIndex < 0 || currentScenes.length < buttonSceneIndex + 1 || !currentScenes[props.activeIndex]) {
      return;
    }
    if (currentScenes[props.activeIndex].outside !== currentScenes[buttonSceneIndex].outside) {
      return {
        distance: currentScenes[buttonSceneIndex].outside
          ? context.translations.soundbox.outside
          : context.translations.soundbox.inside,
      };
    }

    const currentButtonScene = currentScenes[buttonSceneIndex];
    return { distance: currentButtonScene.distance + "m", distanceDescription: context.translations.soundbox.distant };
  };

  return (
    <div className="soundbox-carousel-control">
      {currentScenes.length === props.activeIndex + 1 && heatPumpTranslation?.ctaButton?.link && (
        <div className="soundbox-carousel-control__cta-button-wrapper">
          <CTAButton
            text={heatPumpTranslation.ctaButton.text}
            href={heatPumpTranslation.ctaButton.link}
            onClick={() => {
              const heatPump = context.heatPumps.find(e => e.id === props.selectedHeatPumpContext.heatPumpId)?.variants[
                props.selectedHeatPumpContext.variantIndex
              ];

              if (heatPump) {
                (window as any).dataLayer.push({
                  event: "hp_contact",
                  hp_model: heatPump.shortcut,
                  power_setting: heatPump.power + " kW",
                });
              }
            }}
          />
        </div>
      )}
      <div className="soundbox-carousel-control__panel">
        <SoundboxCarouselButton
          type="back"
          text={context.translations.soundbox.previousScene}
          subText={selectSceneButtonDescription(props.activeIndex - 1)}
          onClick={() => props.translateCarousel("left")}
          disabled={props.disableArrow.left}
        />
        <SceneDescription
          setOpenSoundSelection={props.setOpenSoundSelection}
          soundboxSettings={props.soundboxSettings}
          muteSurroundings={props.muteSurroundings}
          setMuteSurroundings={props.setMuteSurroundings}
          activeIndex={props.activeIndex}
          changeTranslate={props.changeTranslate}
        />
        <SoundboxCarouselButton
          type="next"
          text={context.translations.soundbox.nextScene}
          subText={selectSceneButtonDescription(props.activeIndex + 1)}
          onClick={() => props.translateCarousel("right")}
          disabled={props.disableArrow.right}
        />
      </div>
    </div>
  );
});
