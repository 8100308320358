import { useEffect, useState } from "react";

const getWindowInnerHeight = (w: Window) => {
  return w.innerHeight;
};

const getWindowInnerWidth = (w: Window) => {
  return w.innerWidth;
};

export function useDeviceScreenHeight() {
  return useWindowRelatedInfo(getWindowInnerHeight);
}

export function useDeviceScreenWidth() {
  return useWindowRelatedInfo(getWindowInnerWidth);
}

export function useWindowRelatedInfo<T>(f: (w: Window) => T): T | undefined {
  const [state, setState] = useState<T | undefined>(undefined);
  useEffect(() => {
    function onResize() {
      setState(f(window));
    }

    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [f]);

  return state;
}
